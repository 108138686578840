'use client'

import { EmptyState, Image } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'

const NotFoundEmptyState = () => {
  const translate = useTranslate()
  const asset = useAsset('/assets/error-page')

  return (
    <EmptyState
      title={translate('error_page.not_found.title')}
      body={translate('error_page.not_found.body')}
      image={<Image src={asset('failed-to-load.svg')} />}
    />
  )
}

export default NotFoundEmptyState
