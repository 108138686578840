'use client'

import { ReactNode, useMemo, useState } from 'react'

import { useQueryParams } from '@marketplace-web/shared/browser'
import { useSession } from '@marketplace-web/shared/session'
import { SavedSearchDto } from 'types/dtos'
import useLatestCallback from 'hooks/useLatestCallback'
import * as api from 'data/api'
import { isResponseError } from '_libs/utils/api'
import { toParams, toUrlQuery } from '_libs/utils/url'
import { SavedSearchApiParams } from 'types/api'

import SavedSearchesContext, { SavedSearchesContextType } from './SavedSearchesContext'
import { SEARCH_ID } from './constants'
import { searchDtoToApiParams } from './transformers'

type Props = {
  children: ReactNode
}

const SavedSearchesProvider = ({ children }: Props) => {
  const { user } = useSession()
  // todo: replace this with useLocation once context is scaled
  const query = useQueryParams()

  const [searchesById, setSearchesById] = useState<Record<number, SavedSearchDto>>({})
  const [searchIds, setSearchIds] = useState<Array<number>>([])

  const currentSearchId = query[SEARCH_ID] ? Number(query[SEARCH_ID]) : undefined

  // todo: replace this with useLocation once context is scaled
  const setCurrentSearchId = (id: number) => {
    const stringValue = String(id)

    const url = new URL(window.location.href)
    const params = toParams(url.search)
    params[SEARCH_ID] = stringValue

    window.history.replaceState(null, '', `${url.pathname}?${toUrlQuery(params)}`)
  }

  const currentSearch = currentSearchId ? searchesById[currentSearchId] : undefined

  const searches = useMemo<Array<SavedSearchDto>>(() => {
    return searchIds.map(id => searchesById[id]).filter(Boolean)
  }, [searchesById, searchIds])

  const updateSearchesById = (items: Array<SavedSearchDto>) => {
    setSearchesById(prev => {
      const clone = { ...prev }

      items.forEach(search => {
        clone[search.id] = search
      })

      return clone
    })
  }

  const setSearches = (items: Array<SavedSearchDto>) => {
    setSearchIds(items.map(item => item.id))
    updateSearchesById(items)
  }

  const fetchSearches = useLatestCallback(async () => {
    if (!user) return
    const response = await api.getSortedSavedSearches(user.id)

    if (isResponseError(response)) return
    setSearches(response.searches)
  })

  const fetchSearch = useLatestCallback(
    async (...params: Parameters<typeof api.getSavedSearch>) => {
      const response = await api.getSavedSearch(...params)

      if (isResponseError(response)) return undefined
      updateSearchesById([response.search])

      return response.search
    },
  )

  const createSearch = useLatestCallback(
    async (...params: Parameters<typeof api.createSavedSearch>) => {
      if (!user) return
      const response = await api.createSavedSearch(...params)

      if (isResponseError(response)) return

      await fetchSearch({ id: response.search.id, userId: user.id })
      setCurrentSearchId(response.search.id)
    },
  )

  const updateSearch = useLatestCallback(
    async (...params: Parameters<typeof api.updateSavedSearch>) => {
      const response = await api.updateSavedSearch(...params)
      if (isResponseError(response)) return

      const current = searchesById[response.search.id]
      if (!current) return

      updateSearchesById([{ ...current, ...response.search }])
    },
  )

  const toggleSearchSubscription = useLatestCallback(async (searchId: number) => {
    const search = searchesById[searchId]
    if (!search || !user) return

    await updateSearch({
      search: { ...searchDtoToApiParams(search), subscribed: !search.subscribed },
      userId: user.id,
      id: search.id,
      keepLastVisitTime: true,
    })
  })

  const toggleCurrentSearchSubscription = useLatestCallback(async (arg: SavedSearchApiParams) => {
    if (!user) return

    if (currentSearchId) {
      await toggleSearchSubscription(currentSearchId)

      return
    }

    await createSearch({ userId: user.id, search: { ...arg, subscribed: true } })
  })

  const actions = useMemo<SavedSearchesContextType['actions']>(
    () => ({
      createSearch,
      fetchSearch,
      fetchSearches,
      toggleCurrentSearchSubscription,
      toggleSearchSubscription,
      updateSearch,
    }),
    [
      createSearch,
      fetchSearch,
      fetchSearches,
      toggleCurrentSearchSubscription,
      toggleSearchSubscription,
      updateSearch,
    ],
  )

  const value = useMemo<SavedSearchesContextType>(
    () => ({
      currentSearchId,
      currentSearch,
      searches,
      actions,
    }),
    [actions, currentSearch, currentSearchId, searches],
  )

  return <SavedSearchesContext.Provider value={value}>{children}</SavedSearchesContext.Provider>
}

export default SavedSearchesProvider
