import { compact } from 'lodash'

import { HomepageItemBlockCtaDto, HomepageItemBlockDto } from 'types/dtos/homepage-blocks'
import {
  HomepageItemBlockModel,
  HomepageItemBlockCtaModel,
  HomepageBlockEntityModel,
  HomepageBlockItemModel,
  HomepageTabModel,
  HomepageItemModel,
} from 'types/models/homepage-blocks'
import { FeedItemDto, HomepageBlockEntityDto, HomepageBlockItemDto } from 'types/dtos'
import { BlockEntityType } from 'constants/home'
import { getItemThumbnail } from 'data/utils/item'
import { ItemThumbnailSize } from 'constants/images'
import { logHomeError } from 'pages/Home/utils/observability'
import { HomepageConfigResp } from 'types/api'
import { getUserThumbnail } from 'data/utils/user'

import {
  getDominantColor,
  transformHomepageItemDtosToProductItems,
  transformPartialUserDtoToProductItemUser,
} from './product-item'
import { transformThumbnailsBlock, transformBannersBlock } from './homepage-layouts'
import { transformCurrencyAmountDtoOrString } from './currency-amount'
import { transformItemPhotoDtos } from './item'
import { transformIconBadges } from './icon-badges'
import { transformItemBoxDto } from './item-box'
import { transformPromoBoxDto } from './generic-promo-box'

export const transformHomepageItemBlockCta = (
  cta: HomepageItemBlockCtaDto | null,
): HomepageItemBlockCtaModel | null => {
  if (!cta) return null

  return {
    url: cta.url,
    title: cta.title,
    accessibilityLabel: cta.accessibility_label,
  }
}

export const transformHomepageItemBlock = (
  itemBlock: HomepageItemBlockDto,
): HomepageItemBlockModel => ({
  id: itemBlock.id,
  name: itemBlock.name,
  title: itemBlock.title,
  subtitle: itemBlock.subtitle,
  items: transformHomepageItemDtosToProductItems(itemBlock.items),
  headerCta: transformHomepageItemBlockCta(itemBlock.header_cta),
  itemCta: transformHomepageItemBlockCta(itemBlock.item_cta),
})

export const transformFeedItemDtoToHomepageItem = (item: FeedItemDto): HomepageItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title,
  sizeTitle: item.size_title,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.currency),
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
    : undefined,
  badge: item.badge,
  isPromoted: item.promoted,
  isFavourite: item.is_favourite,
  thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
  dominantColor: item.photo && getDominantColor([item.photo]),
  user: transformPartialUserDtoToProductItemUser(item.user),
  photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
  contentSource: item.content_source,
})

export const transformHomepageBlockEntity = (
  itemBlock: HomepageBlockEntityDto,
): HomepageBlockEntityModel | undefined => {
  const { type } = itemBlock

  try {
    switch (type) {
      case BlockEntityType.ItemBoxBlock:
        return { type, entity: transformHomepageItemBlock(itemBlock.entity) }
      case BlockEntityType.ThumbnailsBlock:
        return { type, entity: transformThumbnailsBlock(itemBlock.entity) }
      case BlockEntityType.ExposureBlock:
        return { type, entity: itemBlock.entity }
      case BlockEntityType.BannersBlock:
        return { type, entity: transformBannersBlock(itemBlock.entity) }
      case BlockEntityType.Item:
        return { type, entity: transformFeedItemDtoToHomepageItem(itemBlock.entity) }
      case BlockEntityType.PromoBox:
        return { type, entity: transformPromoBoxDto(itemBlock.entity) }
      case BlockEntityType.Header:
        return { type, entity: itemBlock.entity }
      default:
        return undefined
    }
  } catch (err) {
    logHomeError(err, type)

    return undefined
  }
}

export const transformHomepageBlockEntities = (
  blocks: Array<HomepageBlockEntityDto>,
): Array<HomepageBlockEntityModel> => compact(blocks.map(transformHomepageBlockEntity))

export const transformHomepageBlockItemDtosToHomepageBlockItems = (
  items: Array<HomepageBlockItemDto>,
): Array<HomepageBlockItemModel> =>
  items.map(item => ({
    id: item.id,
    title: item.title,
    user: {
      id: item.user.id,
      isBusiness: item.user.business,
      login: item.user.login,
      profileUrl: item.user.profile_url,
      thumbnailUrl: item.user.photo && getUserThumbnail(item.user.photo),
    },
    url: item.url,
    favouriteCount: item.favourite_count,
    brandTitle: item.brand_title || '',
    sizeTitle: item.size_title || '',
    priceWithDiscount: item.discount
      ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
      : null,
    price: transformCurrencyAmountDtoOrString(item.price, item.currency),
    serviceFee: item.service_fee
      ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
      : null,
    totalItemPrice: item.total_item_price
      ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
      : undefined,
    badge: item.badge,
    itemClosingAction: item.item_closing_action,
    isPromoted: item.promoted,
    isFavourite: item.is_favourite,
    isHidden: item.is_hidden,
    isReserved: item.is_reserved,
    isClosed: item.is_closed,
    thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
    dominantColor: item.photo && getDominantColor([item.photo]),
    photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
    contentSource: item.content_source,
    iconBadges: transformIconBadges(item.icon_badges),
    homepageSignals: item.homepage_signals,
    itemBox: item.item_box && transformItemBoxDto(item.item_box),
  }))

export const transformHomepageTabDtos = (
  tabs: HomepageConfigResp['verticals'],
): Array<HomepageTabModel> =>
  tabs.map(tab => ({
    title: tab.title,
    name: tab.name,
    isPromoBoxEnabled: tab.is_promobox_enabled,
    batchCount: tab.batch_count,
    isSellerPromotionEnabled: tab.is_seller_promotion_enabled,
    isFeedLoadedInHomepageApi: tab.load_feed_in_homepage,
    feed: {
      title: tab.feed.title,
      arePromotedClosetsEnabled: tab.feed.are_promoted_closets_enabled,
      areAdsEnabled: tab.feed.are_ads_enabled,
      isPromoBoxEnabled: tab.feed.is_promobox_enabled,
    },
  }))
