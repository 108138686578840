import { Translation } from 'constants/translation'
import { UserDto, UserHandleDto } from 'types/dtos'
import { UserModel, UserSuggestionModel } from 'types/models'
import { getUserThumbnail } from 'data/utils/user'

import { transformAddress } from './address'
import { transformUserBundleDiscountDto } from './bundle-discount'
import { transformBusinessAccountDto } from './business-account'
import { transformPhotoDto } from './photo'
import { transformManageableAccountsDto } from './account-staff'

export const transformUserDto = (dto: UserDto): UserModel => {
  return {
    id: dto.id,
    anonId: dto.anon_id,
    photo: dto.photo && transformPhotoDto(dto.photo),
    login: dto.login,
    externalId: dto.external_id,
    allowDirectMessaging: dto.allow_direct_messaging,
    profileUrl: dto.profile_url,
    path: dto.path,
    feedbackReputation: dto.feedback_reputation,
    feedbackCount: dto.feedback_count,
    isFavourite: dto.is_favourite,
    accountBanDate: dto.account_ban_date,
    isAccountBanned: dto.is_account_banned,
    listingRestrictedUntil: dto.listing_restricted_until,
    verification: dto.verification,
    accountStatus: dto.account_status,
    isModerator: dto.moderator,
    isVolunteerModerator: dto.volunteer_moderator,
    isGod: dto.is_god,
    isSystem: !!dto.is_system,
    isIdentity: dto.is_identity,
    isHated: dto.is_hated,
    hatesYou: dto.hates_you,
    isRestrictedByTerms: !!dto.restricted_by_terms,
    isSoftRestrictedByTerms: !!dto.soft_restricted_by_terms,
    isTermsUpdateAvailable: !!dto.terms_update_available,
    isRestrictedByUnconfirmedRealName: !!dto.restricted_by_unconfirmed_real_name,
    isRestrictedByBalanceActivation: !!dto.restricted_by_balance_activation,
    locationDescription: dto.location_description,
    email: dto.email,
    lastLoggedOn: dto.last_loged_on,
    lastLoggedOnTs: dto.last_loged_on_ts,
    createdAt: dto.created_at,
    followersCount: dto.followers_count,
    followingCount: dto.following_count,
    conversationTemplateCount: dto.msg_template_count,
    takenItemCount: dto.taken_item_count,
    givenItemCount: dto.given_item_count,
    itemCount: dto.item_count,
    facebookUserId: dto.facebook_user_id,
    countryId: dto.country_id,
    countryCode: dto.country_code,
    countryIsoCode: dto.country_iso_code,
    countryTitle: dto.country_title,
    countryTitleLocal: dto.country_title_local,
    about: dto.about,
    hasItemAlerts: !!dto.has_item_alerts,
    hasReplicaProofItems: !!dto.has_replica_proof_items,
    localization: dto.localization ?? Translation.None,
    cityId: dto.city_id,
    city: dto.city,
    isLocationPublic: !!dto.is_location_public,
    defaultAddress: dto.default_address && transformAddress(dto.default_address),
    exposeLocation: dto.expose_location,
    isPublishPhotosAgreed: dto.is_publish_photos_agreed,
    allowMyFavouriteNotifications: !!dto.allow_my_favourite_notifications,
    allowThirdPartyTracking: dto.third_party_tracking,
    allowPersonalization: !!dto.allow_personalization,
    isOnHoliday: dto.is_on_holiday,
    hasConfirmedPaymentsAccount: !!dto.has_confirmed_payments_account,
    isLoginViaExternalSystemOnly: !!dto.is_login_via_external_system_only,
    fundraiser: dto.fundraiser,
    realName: dto.real_name,
    gender: dto.gender,
    birthday: dto.birthday,
    isGeneratedLogin: !!dto.generated_login,
    bundleDiscount: dto.bundle_discount && transformUserBundleDiscountDto(dto.bundle_discount),
    isBusiness: dto.business,
    businessAccount: dto.business_account && transformBusinessAccountDto(dto.business_account),
    isInternationalTradingEnabled: !!dto.international_trading_enabled,
    currency: dto.currency,
    isCountrySelectionNeeded: !!dto.country_selection_needed,
    closetCountdownEndDate: dto.closet_countdown_end_date,
    locale: dto.locale,
    isCatalogModerator: dto.is_catalog_moderator,
    isCatalogRoleMarketingPhotos: dto.is_catalog_role_marketing_photos,
    manageableAccounts: dto.manageable_accounts
      ? transformManageableAccountsDto(dto.manageable_accounts)
      : undefined,
    isImpersonatingBusiness: !!dto.impersonating_business,
  }
}

export const transformUserDtos = (users: Array<UserDto>): Array<UserModel> =>
  users.map(transformUserDto)

export const transformUserSuggestion = (user: UserHandleDto): UserSuggestionModel => {
  const { id, login, photo } = user

  return {
    id,
    login,
    thumbnail: getUserThumbnail(photo),
  }
}

export const transformUserSuggestions = (users: Array<UserHandleDto>): Array<UserSuggestionModel> =>
  users.map(transformUserSuggestion)
