export enum FaqEntryType {
  NoAccessToEmail = 86,
  NoAccessToOldEmail = 597,
  SmsVerfication = 583,
  PhoneVerification = 585,
  PhoneChange = 688,
  PendingBalance = 460,
  PaymentFailed = 764,
  Donations = 725,
  KycVerificationProblems = 671,
  PortalMerge = 798,
  MakingGoodPhoto = 48,
  BusinessRow = 886,
  InsufficientBalance = 936,
  RequestDataExport = 947,
  Bump = 340,
  ClosetPromotion = 452,
  CrossCurrency = 1074,
  SetCorrectPrice = 376,
  WriteGoodItemDescription = 49,
  OfflineVerification = 1153,
  ElectronicsVerificationSeller = 1361,
  SpamPhishing = 628,
  BlockingMember = 91,
  VintedGuide = 360,
  HowToVerifyEmailCode = 1144,
  UploadingAnItem = 8,
  ShippingSeller = 419,
  VintedWallet = 431,
  TrackDelivery = 100,
  IsItFreeToSell = 373,
  FindingAnItem = 424,
  BuyingStepByStep = 25,
  ShippingBuyer = 420,
  BuyerProtection = 550,
  IhaiRefundPolicy = 465,
  HelpCenterRoot = 3,
  MembersFeedback = 356,
  AddPhoneNumber = 622,
  LeavingFeedbackForBuyer = 14,
  CantLogin = 104,
  AccountStaff = 1270,
  TaxpayerReportingMistakeSeller = 1259,
  DsaLegalRequirements = 1269,
  Dac7Reporting = 1149,
  HowToCompleteDac7Report = 1287,
  Dac7HarmReduction = 1308,
  Dac7SpecialVerification = 1298,
  AccontProtectPhoneVerificationNotWorking = 1405,
  VerifyPhoneNumber = 537,
  RequestDataExportV1 = 1374,
}
