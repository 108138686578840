import { Screen } from 'constants/tracking/screens'

import { urlWithParams } from '_libs/utils/url'

export const USER_PERSONALIZATION_SIZES_URL = '/member/personalization/sizes'
export const USER_PERSONALIZATION_SIZES_CATEGORY_URL = (type: string): string =>
  `/member/personalization/sizes/${type}`
export const USER_PERSONALIZATION_BRANDS_URL = '/member/personalization/brands'
export const USER_PERSONALIZATION_BRANDS_FOLLOWED_URL = '/member/personalization/brands/followed'
export const MEMBER_PROFILE_URL = (id: string | number, closetPromotionPrecheckout?: boolean) =>
  urlWithParams(`/member/${id}`, { cp_precheckout: closetPromotionPrecheckout })
export const MEMBER_FEEDBACK_URL = (id: string | number) => `/member/${id}?tab=feedback`
export const MEMBER_ITEMS_URL = (id: string) => `/member/${id}/items`
export const MEMBER_RESTORE_URL = (id: number): string => `/admin/users/${id}/restore`
export const MEMBER_SEARCH_URL = (page: number, searchText: string): string =>
  `/member/general/search?page=${page}&search_text=${searchText}`
export const MEMBER_FOLLOW_URL = (id: number, tab: string, page?: number) =>
  urlWithParams(`/member/general/follow/${id}`, { tab, page })
export const MEMBER_FOLLOWING_URL = (id: number, page?: number): string =>
  page ? `/member/general/following/${id}?page=${page}` : `/member/general/following/${id}`
export const MEMBER_FOLLOWERS_URL = (id: number, page?: number): string =>
  page ? `/member/general/followers/${id}?page=${page}` : `/member/general/followers/${id}`
export const MEMBER_TEMPLATES_URL = (id: number): string => `/admin/member/${id}/user_msg_templates`

export const ITEM_UPLOAD_URL = '/items/new'
export const ITEM_URL = (id: number): string => `/items/${id}`
export const EDIT_ITEM_URL = (id: number, origin?: string) =>
  `/items/${id}/edit${origin ? `?origin=${origin}` : ''}`
export const EDIT_ITEM_URL_WITH_ANCHOR = (id: number, anchor: string) =>
  `/items/${id}/edit#${anchor}`
export const PROFILE_SETTINGS_URL = '/settings/profile'
export const ACCOUNT_SETTINGS_URL = '/settings/account'
export const DONATIONS_SETTINGS_URL = '/settings/donations'
export const SHIPPING_SETTINGS_URL = '/settings/shipping'
export const BUNDLE_DISCOUNT_SETTINGS_URL = '/settings/bundle_discount'
export const PRIVACY_SETTINGS_URL = '/settings/privacy'
export const POLICIES_SETTINGS_URL = '/settings/policies'
export const EPR_SETTINGS_URL = '/settings/epr'
export const CREATE_BUNDLE_URL = (id: string | number): string => `/member/${id}/bundles/new`
export const CATALOG_URL = '/catalog'
export const CATALOG_ID_URL = (id: number): string => `/catalog/${id}`
export const CATALOG_RULES = '/catalog-rules'
export const MARK_AS_SOLD_URL = (transactionId: number): string =>
  ` /transactions/${transactionId}/mark_as_sold/new?act_as=sold`
export const MARK_ITEM_AS_RESERVED_URL = (itemId: number): string =>
  `/member/items/reservation?id=${itemId}`
export const MARK_ITEM_AS_SOLD_URL = (itemId: number): string =>
  `/member/items/close?act_as=sold&id=${itemId}`
export const BUY_URL = (transactionId: number, sourceScreen: Screen): string =>
  `/transaction/buy/new?transaction_id=${transactionId}&source_screen=${sourceScreen}`
export const TRANSACTION_COMPLETED_URL = (transactionId: number) =>
  `/transaction/completed/payment_close?transaction_id=${transactionId}`
export const CHECKOUT_URL = (transactionId: number) => `/checkout?transaction_id=${transactionId}`
export const SINGLE_CHECKOUT_URL = (checkoutId: string, orderId: number, purchaseType: string) =>
  `/checkout?purchase_id=${checkoutId}&order_id=${orderId}&order_type=${purchaseType}`
export const SUBSCRIPTION_SETTINGS_URL = '/settings/subscriptions'
export const EMAIL_CHANGE_OLD_URL = '/users/verification/email/new'
export const EMAIL_CHANGE_CONFIRM_URL = '/member/settings/email/confirm'
export const FORGOT_PASSWORD_URL = '/member/general/reset_password'
export const ROOT_URL = '/'
export const EXPIRE_COOKIES_URL = '/web/api/auth/expire-cookies'
export const BRANDS_URL = '/brands'
export const BRANDS_BY_LETTER_URL = (letter: string): string => `/brands/by_letter/${letter}`
export const TERMS_URL = '/terms_and_conditions'
export const IMPRESSUM_URL = '/impressum'
export const BUSINESS_TERMS_URL = '/pro-terms-of-use'
export const PRIVATE_BETA_TERMS_URL = '/private-beta-terms-of-use'
export const PHONE_VERIFICATION_URL = '/users/verification/phone/new'
export const CHANGE_PHONE_URL = '/users/verification/phone/edit'
export const OUR_PLATFORM_URL = '/our-platform'
export const ADMIN_CATALOG_ITEM_URL = '/admin/catalog_items'
export const GO_TO_WALLET_URL = '/wallet/balance'
export const MY_ORDERS_URL = ({
  type,
  isDynamicTabMenuLink,
}: {
  type: string
  isDynamicTabMenuLink: boolean
}) => `/my_orders/${type}${isDynamicTabMenuLink ? '?dt=true' : ''}`
export const MY_ORDERS_SOLD_URL = '/my_orders/sold'
export const MY_ORDERS_PURCHASED_URL = '/my_orders/purchased'
export const WALLET_ACCOUNT_EDIT_URL = '/wallet/bank_account'
export const SHIPPING_INSTRUCTIONS_URL = (transactionId: number) =>
  `/member/instructions/shipment?transaction_id=${transactionId}`
export const TRANSACTION_FAQ_ENTRY_URL = (transactionId: number) =>
  `/help/for_transaction/${transactionId}`
export const LIVE_CHAT_FORM_URL = (transactionId: number) =>
  `/help/for_transaction/${transactionId}/live_chat`
export const COMPLAINT_URL = (complaintId: string) => `/complaints/${complaintId}`
export const VIEW_ISSUE_DETAILS_URL = (transactionId: number) => `/issue_details/${transactionId}`
export const LEAVE_FEEDBACK_URL = (userId: string) => `/member/${userId}/feedback/new`
export const EDIT_FEEDBACK_URL = (userId: number, feedbackId: number) =>
  `/member/${userId}/feedback/${feedbackId}/edit`
export const FEEDBACK_URL = (feedbackId: number) => `/feedback/${feedbackId}`
export const APP_LANDING_URL = '/app'
export const OFFER_REQUEST_ACCEPT_URL = (transactionId: number, offerRequestId: string) =>
  `/transactions/${transactionId}/offer_requests/${offerRequestId}/accept`
export const OFFER_REQUEST_REJECT_URL = (transactionId: number, offerRequestId: string) =>
  `/transactions/${transactionId}/offer_requests/${offerRequestId}/reject`
export const SECURITY_SETTINGS_URL = '/settings/security'
export const WALLET_SETUP_URL = '/wallet/setup'
export const WALLET_PAYOUT_URL = '/wallet/payouts/new'
export const BUMP_MULTIPLE_ITEM_SELECTION_URL = '/items/push_up/new'
export const PAYMENTS_IDENTITY = '/settings/payments_identity'
export const PAYMENTS_IDENTITY_SIMPLIFIED = '/settings/payments_identity_simplified'
export const DELETE_ACCOUNT_URL = '/delete_account'
export const MEMBER_DELETE_ACCOUNT_URL = '/member/general/delete_account'
export const MEMBER_DELETE_ACCOUNT_SUCCESS_URL = '/member/general/delete_account_success'
export const SIGNUP_URL = '/member/signup/select_type'
export const REGISTER_URL = '/member/signup/email_start'
export const CHANGE_PASSWORD = '/member/general/change_password'
export const USER_SEARCH_URL = '/member/general/search'
export const USER_FAVOURITE_ITEMS_URL = '/member/items/favourite_list'
export const PAYOUT_DETAILS = (payoutId: number, showSuccess = false) =>
  `/wallet/payouts/${payoutId}${showSuccess ? '?success=true' : ''}`
export const NEW_ADMIN_ALERT_URL = '/admin_alert/new'
export const HELP_FAQ_ENTRIES_URL = '/help'
export const SIGNUP_HELP_ENTRIES_URL = '/help/103'
export const GUIDE_ENTRIES_URL = '/help/360'
export const EPR_GUIDE_URL = '/help/1296'
export const NOTIFICATIONS_URL = '/member/notifications'
export const NEW_HELP_TICKET_URL = '/help/support_tickets/new'
export const TRENDSALES_PAYOUT_FORM_URL = '/help/trendsales_payout'
export const NEW_COMPLAINTS_URL = '/complaints/new'
export const COMPLAINT_CREATION_URL = '/complaints/report'
export const PRICELIST_URL = '/pricelist'
export const PAYMENTS_SETTINGS_URL = '/settings/payments'
export const TWO_FACTOR_AUTH_URL = '/users/verification/user_2fa'
export const PRIVACY_POLICY_URL = '/privacy-policy'
export const COOKIE_POLICY_URL = '/cookie-policy'
export const BUYER_PROTECTION_URL = '/safety#buyer-protection'
export const BUYER_PROTECTION_PRO_URL = '/safety_pro#buyer-protection'
export const BUSINESS_TERMS_AND_CONDITIONS_URL = '/pro-terms-and-conditions'
export const BUSINESS_TERMS_OF_SALE_URL = '/pro-terms-of-sale'
export const BUSINESS_TERMS_OF_USE_URL = '/pro-terms-of-use'
export const PRIVATE_BETA_TERMS_OF_USE_URL = '/private-beta-terms-of-use'
export const INCOME_REPORT_URL = (year: number) => `/wallet/income_reports/${year}.pdf`
export const YEARLY_INCOME_REPORT_URL = (year: number) => `/wallet/income_reports/${year}.pdf`
export const INVOICE_URL = (invoiceId: number) => `/wallet/invoices/${invoiceId}`
export const REFERRALS_URL = '/referrals'
export const REFERRALS_REGISTRATIONS_URL = '/referrals/registrations'
export const REQUEST_DATA_EXPORT_URL = '/request_data_export'
export const KYC_HELP_URL = '/help/111'
export const SELLING_HELP_URL = '/help/52'
export const REPORT_HELP_URL = '/help/288'
export const SERVICE_FEE_HELP_URL = '/help/342'
export const SERVICE_FEE_PRO_HELP_URL = '/help/908'
export const BUSINESS_ACCOUNTS_HOMEPAGE = '/pro'
export const BUSINESS_ACCOUNTS_GUIDE_URL = '/pro-guide'
export const NOTICE_AND_ACTION_FORM = '/help/notice_and_action_form'
export const NOTICE_AND_ACTION_FORM_REPORT = `${NOTICE_AND_ACTION_FORM}/report`
export const MAIN_NAVIGATION_ABOUT_TAB_URL = '/about'
export const DONATION_CLOSE_URL = '/donations/close'
export const SOCIAL_FACEBOOK_URL = 'https://www.facebook.com/vinted'
export const SOCIAL_LINKEDIN_URL = 'https://www.linkedin.com/company/vinted'
export const SOCIAL_INSTAGRAM_URL = 'https://www.instagram.com/vinted'
export const HELP_CENTER_URL = '/help'
export const HELP_SELLING_URL = '/help/4-selling?access_channel=hc_topics'
export const HELP_BUYING_URL = '/help/5-buying?access_channel=hc_topics'
export const HELP_TRUST_SAFETY_URL = '/help/15-trust-safety'
export const HELP_REFUND_POLICY = '/help/465-the-vinted-refund-policy'
export const HELP_REFUND_POLICY_PRO = '/help/885'
export const HELP_SAFETY_URL = '/safety'
export const HELP_SEARCH_URL = '/help/s'
export const BUNDLES_POLICY_URL = '/help/260'
export const BUNLDE_DISCOUNTS_POLICY_URL = '/help/257'
export const EMAIL_VERIFICATION_URL = '/users/verification/email/new'
export const HOW_IT_WORKS_URL = '/how_it_works'
export const INFOBOARD_URL = '/infoboard'
export const ITEM_VERIFICATION_URL = '/item_verification'
export const ADVERTISING_URL = '/advertising'
export const JOBS_URL_NEW = 'https://careers.vinted.com/'
export const JOBS_URL = '/jobs'
export const SECURITY_SETTINGS_2FA_URL = '/settings/security/phone_verification'
export const WALLET_INVOICES_URL = '/wallet/invoices'
export const PRESS_CENTER_URL = 'https://company.vinted.com'
export const USER_SESSIONS_URL = '/settings/security/sessions'
export const USERS_VERIFICATION_URL = '/users/verification'
export const EMAIL_CODE_VERIFICATION_URL = '/users/verification/email_code'
export const GOOGLE_OAUTH_URL = '/auth/google/oauth'
export const GO_TO_TAXPAYER_FORM_URL = '/settings/taxpayer'
export const WALLET_HISTORY_PATH = '/wallet/history'
export const HAOV_VERIFICATION_GENERAL_URL = '/help/1147'
export const APP_STORE_BY_DEVICE_URL = 'https://4uwb.adj.st/?adjust_t=l2fpdc8_rnxf4nj'
export const APP_FEEDBACKS_URL = '/app_feedbacks'
export const WALLET_HISTORY_OVERVIEW_URL = '/wallet/history/overview'
export const WALLET_INCOME_REPORTS_URL = '/wallet/income_reports'
export const BUSINESS_ACCOUNT_REGISTER_URL = '/pro/register'
export const BLOCKED_URL = '/main/blocked'
export const BANNED_URL = '/main/banned'
export const LISTING_RESTRICTION_URL = '/listing-restriction'
export const BALANCE_ACTIVATION_URL = '/member/balance_activation'
export const FULL_NAME_CONFIRMATION_URL = '/member/full_name_confirmation'
export const COMPLAINT_MORE_INFORMATION_URL = (complaintId: string) =>
  `/complaints/${complaintId}/faq_entry`
export const ADMIN_SECTION_ITEM_PAGE_URL = (itemId: number) =>
  `/admin/item_moderation_actions/${itemId}`
export const ADMIN_SECTION_PROFILE_PAGE_URL = (userId: number) => `/admin/member/${userId}`
export const DSA_APPEAL_EDUCATION_URL = (threadId: number) =>
  `/dsa/appeal_education?thread_id=${threadId}`
export const DSA_NOTICE_APPEAL_URL = (threadId: number) =>
  `/dsa/notice_appeal?thread_id=${threadId}`
export const TAX_RULES_URL = '/settings/tax_rules'
export const TAX_PAYER_URL = '/settings/taxpayer'
export const RECOMMENDED_BROWSERS_URL = '/recommended_browsers'
export const DAC7_URL = '/dac'
export const ACCESSIBILITY_URL = '/accessibility'
export const UK_REPORTING = '/uk-reporting'
export const NO_CHANGES_TO_TAXES_URL = '/no-changes-to-taxes'
export const TAXPAYER_EDUCATION_URL = '/settings/taxpayer_education'
export const USER_MIGRATION_URL = '/user_migration'
export const TAXPAYER_FORM_URL_WITH_REF = (refUrl: string) =>
  urlWithParams('/settings/taxpayer', { ref_url: refUrl })
export const RATE_LIMITED_URL = '/rate-limited'
export const NO_VPN_URL = '/no-vpn'
export const TAXPAYER_SUMMARY_URL = '/settings/taxpayer_summary'
export const TAXPAYER_EDUCATION_URL_WITH_REF = (refUrl: string) =>
  urlWithParams('/settings/taxpayer_education', { ref_url: refUrl })
export const TAXPAYER_REPORT_URL = (id: number) =>
  urlWithParams('/settings/taxpayer_report', { id })
export const ACCOUNT_STAFF_SETTINGS_URL = '/settings/account_staff'

export const SPECIAL_VERIFICATION_FORM_URL_WITH_REF = (
  refUrl: string,
  specialVerificationSessionId: string,
) =>
  urlWithParams('/taxpayers/special_verification', {
    ref_url: refUrl,
    id: specialVerificationSessionId,
  })
export const MIGRATION_HELP_URL = '/help'
export const CLOSET_PROMOTION_STATS_URL = '/closet_promotions/stats'
export const TAXPAYER_CENTER_URL = '/settings/taxpayer_center'
export const EMAIL_VERIFICATION_CODE_SUCCESS_URL = '/users/verification/email_code/success'
export const NOT_FOUND_URL = '/404'
export const TAXPAYER_CENTER_URL_WITH_REF = (refUrl: string) =>
  urlWithParams('/settings/taxpayer_center', { ref_url: refUrl })
export const SELLER_INSIGHTS_URL = (userId: number) => `/member/${userId}?tab=insights`
