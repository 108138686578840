'use client'

import { useCallback, useContext, useMemo } from 'react'

import { toParams } from '_libs/utils/url'
import RequestContext from 'containers/RequestProvider/RequestContext'

import { navigateToPage } from '../utils/location'

const useLocation = () => {
  const { url, serverUrl, urlParams, routerPush, back, push, replace, refreshUrl } =
    useContext(RequestContext).location

  const safeBack = useCallback(
    (fallbackUrl: string) => {
      if (document.referrer.indexOf(new URL(url).origin) === -1) {
        navigateToPage(fallbackUrl)
      } else {
        back()
      }
    },
    [back, url],
  )

  const initialSearchParams = useMemo(() => toParams(new URL(serverUrl).search), [serverUrl])

  return useMemo(() => {
    const currentUrl = new URL(url)

    return {
      href: currentUrl.href,
      baseUrl: currentUrl.origin,
      host: currentUrl.host,
      urlQuery: currentUrl.search,
      relativeUrl: currentUrl.pathname,
      urlHash: currentUrl.hash,
      searchParams: toParams(currentUrl.search),
      initialSearchParams,
      urlParams,
      safeBack,
      goToPreviousPage: back,
      pushHistoryState: push,
      routerPush,
      replaceHistoryState: replace,
      refreshUrl,
    }
  }, [back, push, refreshUrl, routerPush, replace, safeBack, url, urlParams, initialSearchParams])
}

export default useLocation
