import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

type Props = {
  isViewingOwnItem: boolean
}

export const useShowStickyButtons = ({ isViewingOwnItem }: Props): boolean => {
  const breakpoints = useBreakpoint()

  const showStickyButtons = breakpoints.phones && !isViewingOwnItem

  return !!showStickyButtons
}
