import { HorizontalScrollBlockItemDto, ItemSearchParamsDto } from 'types/dtos'
import { getItemThumbnail } from 'data/utils/item'
import { ItemThumbnailSize } from 'constants/images'
import { transformItemPhotoDtos } from 'data/transformers/item'
import {
  getDominantColor,
  transformPartialUserDtoToProductItemUser,
} from 'data/transformers/product-item'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import { transformIconBadges } from 'data/transformers/icon-badges'
import { transformItemBoxDto } from 'data/transformers/item-box'
import { ProductItemModel } from 'types/models/product-item'

export const transformHorizontalScrollBlockItemDtoToProductItem = (
  item: HorizontalScrollBlockItemDto,
): ProductItemModel & {
  searchTrackingParams?: ItemSearchParamsDto | null
} => ({
  id: item.id,
  title: item.title,
  url: item.url,
  photos: item.photo ? transformItemPhotoDtos([item.photo]) : [],
  viewCount: item.view_count,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title || '',
  sizeTitle: item.size_title || '',
  user: transformPartialUserDtoToProductItemUser(item.user),
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: item.photo ? getItemThumbnail([item.photo], ItemThumbnailSize.Large) : null,
  dominantColor: item.photo ? getDominantColor([item.photo]) : null,
  badge: item.badge,
  isPromoted: item.promoted,
  isDraft: item.is_draft,
  isFavourite: item.is_favourite,
  iconBadges: transformIconBadges(item.icon_badges),
  searchTrackingParams: item.search_tracking_params,
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})
