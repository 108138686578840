import { FaqEntryType } from 'constants/faq-entry'
import { CURRENT_USER_API_ENDPOINT, RECOMMENDED_LOCALE_API_ENDPOINT } from 'constants/paths'
import type ApiClient from '_libs/common/api-client'
import type { ApiClientRequestConfig } from '_libs/common/api-client'
import type * as apiTypes from 'types/api'
import { RecommendedLocaleResp } from 'types/api'

import { ContentType } from '_libs/landing-page-builder/constants'
import { ContentTypeDtoMap } from '_libs/landing-page-builder/types/dtos/content-types'

import type { SeoContentType } from '../../../next/libs/seo/constants'

import { updateCurrentUserSettingsArgsToParams } from './transformers/args'

const createGetRequest =
  <R, E = unknown>(url: string) =>
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(config?: C) =>
    apiClient.get<R, E, C>(url, config)

export const getUserByIdRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(userId: number, config?: C) =>
    apiClient.get<apiTypes.UserResp, unknown, C>(`/users/${userId}`, config)

export const getCurrentUserRequest = createGetRequest<apiTypes.UserResp>(CURRENT_USER_API_ENDPOINT)

export const getCatalogsRequest = createGetRequest<apiTypes.GetCatalogsResp>('/catalogs')

export const getItemUploadCatalogsRequest =
  createGetRequest<apiTypes.GetItemUploadCatalogsResp>('/item_upload/catalogs')

export const getProfileUserInfoRequest = (userId: number) =>
  createGetRequest<apiTypes.ProfileUserInfoResp>(`users/${userId}/profile`)

export const getSystemConfigurationRequest =
  createGetRequest<apiTypes.SystemConfigurationResp>('/system_configuration')

export const getTranslationsRequest = createGetRequest<apiTypes.GetTranslationsResp>('/texts')

export const getFeatureSwitchesRequest =
  createGetRequest<apiTypes.GetFeatureSwitchesResp>('/features')

export const getWalletConversionStatusRequest =
  createGetRequest<apiTypes.WalletConversionStatusResp>('/business_accounts/conversion')

export const getLanguagesRequest = createGetRequest<apiTypes.GetLanguagesResp>('/languages')

export const getDiscoveryCatalogsRequest = createGetRequest<apiTypes.GetDiscoveryCatalogsResp>(
  '/discovery_catalogs?designer_categories=true',
)

export const getDomainSelectionRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(referrer = '', config?: C) =>
    apiClient.get<apiTypes.GetDomainSelectionResp, unknown, C>(
      `/domain_selection?referrer=${referrer}`,
      config,
    )

export const getFaqEntryByIdRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(id: string, config?: C) =>
    apiClient.get<apiTypes.FaqEntryResp, unknown, C>(`/faq_entries/${id}`, config)

export const getFaqEntriesByTransactionIdRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(transactionId: string, config?: C) =>
    apiClient.get<apiTypes.FaqEntriesByTransactionResp, unknown, C>(
      `transactions/${transactionId}/faq_entries`,
      config,
    )

export const getFaqEntriesSearchRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(searchText: string, config?: C) =>
    apiClient.get<apiTypes.FaqEntriesSearchResp, unknown, C>(
      `/faq_entries/search?query=${searchText}`,
      config,
    )

export const getFaqEntryByCodeRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(code: FaqEntryType, config?: C) =>
    apiClient.get<apiTypes.FaqEntryResp, unknown, C>(`/faq_entries/lookup?code=${code}`, config)

export const getAdsPlacementsRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>({ pageName }: apiTypes.GetAdsPlacementsArgs, config?: C) =>
    apiClient.get<apiTypes.GetAdsPlacementsResp, unknown, C>(
      `/ads/placements?page=${pageName}`,
      config,
    )

export const getRoktTransactionsAttributesRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(
    { transactionId }: apiTypes.GetRoktTransactionAttributesArgs,
    config?: C,
  ) =>
    apiClient.get<apiTypes.GetRoktTransactionAttributesResp, unknown, C>(
      `/ads/rokt?transaction_id=${transactionId}`,
      config,
    )

export const getVanPlacementRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(
    { placementId, pageName }: apiTypes.GetVanPlacementArgs,
    config?: C,
  ) =>
    apiClient.get<apiTypes.GetVanPlacementResp, unknown, C>(
      `/ad/${placementId}?screen=${pageName}`,
      config,
    )

export const getAdsSegmentsRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>({ catId }: apiTypes.GetAdsSegmentsArgs, config?: C) =>
    apiClient.get<apiTypes.GetAdsSegmentsResp, unknown, C>(
      `/ads/segments${catId ? `?category=${catId}` : ''}`,
      config,
    )

export const getAdsTxtRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(
    { platform, countryCode }: apiTypes.GetAdsTxtArgs,
    config?: C,
  ) =>
    apiClient.get<apiTypes.GetAdsTxtResp, unknown, C>(
      `/ads/txt?platform=${platform}&country_code=${countryCode.toUpperCase()}`,
      config,
    )

export const getConversationRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(conversationId: number | string, config?: C) =>
    apiClient.get<apiTypes.ConversationResp, unknown, C>(`/conversations/${conversationId}`, config)

export const getUserPermissionsRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(userId: number, config?: C) =>
    apiClient.get<apiTypes.UserPermissionsResp, unknown, C>(`/users/${userId}/permissions`, config)

export const getVerificationPromptRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(userId: number, config?: C) =>
    apiClient.get<apiTypes.VerificationPromptResp, unknown, C>(
      `/users/${userId}/verifications/prompt`,
      config,
    )

export const getCommonTextRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(code: string, config?: C) =>
    apiClient.get<apiTypes.GetCommonTextResp, unknown, C>(`/common_texts?code=${code}`, config)

export const getDsaNoticeAppealRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(threadId: number, config?: C) =>
    apiClient.get<apiTypes.GetDsaNoticeAppealResp, unknown, C>(
      `/dsa/appeals/lookup?thread_id=${threadId}`,
      config,
    )

export const getDsaReporterAppealRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(threadId: number, config?: C) =>
    apiClient.get<apiTypes.GetDsaNoticeAppealResp, unknown, C>(
      `/dsa/reporter_appeals/lookup?thread_id=${threadId}`,
      config,
    )

export const getDsaNtdAppealRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(referenceId: string, config?: C) =>
    apiClient.get<apiTypes.GetDsaNoticeAppealResp, unknown, C>(
      `/dsa/ntd_appeals/lookup?reference_id=${referenceId}`,
      config,
    )

export const getItemDetailsRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(itemId: number | string, config?: C) =>
    apiClient.get<apiTypes.ItemDetailsResp, unknown, C>(`/items/${itemId}/details`, config)

export const getShippingDetailsRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(itemId: number | string, config?: C) =>
    apiClient.get<apiTypes.GetShippingDetailsResp, unknown, C>(
      `/items/${itemId}/shipping_details`,
      config,
    )

export const createReferrerRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(login: string, config?: C) =>
    apiClient.post<apiTypes.CreateReferrerResp, unknown, C>(
      '/referrals/referrer',
      { referrer: login },
      config,
    )

export const getRecommendedLocaleRequest = createGetRequest<RecommendedLocaleResp>(
  RECOMMENDED_LOCALE_API_ENDPOINT,
)

export const getInfoPost =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(infoPostId: number, config?: C) =>
    apiClient.get<apiTypes.GetInfoBoardPostResp, unknown, C>(`/info_posts/${infoPostId}`, config)

export const getTaxpayerReportRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(id: string, config?: C) =>
    apiClient.get<apiTypes.GetTaxpayerReportResp, unknown, C>(`/taxpayers/reports/${id}`, config)

export const getSeoContent = (
  seoContentType: SeoContentType,
  key: string,
  value: string | number,
) =>
  createGetRequest<apiTypes.SeoContent>(`/cms/${String(seoContentType)}?key=${key}&value=${value}`)

export const getInfluencer = createGetRequest<apiTypes.InfluencerResp>('/cms/dynamic/influencers')

export const getSellElectronicsLandingPage = createGetRequest<any>('/cms/dynamic/sell-electronics') // TODO: populate the dto

export const getBuyElectronicsLandingPage = createGetRequest<any>('/cms/dynamic/buy-electronics') // TODO: populate the dto

export const getLandingPage =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig, T extends ContentType>(contentType: ContentType, config?: C) =>
    apiClient.get<ContentTypeDtoMap[T], unknown, C>(`/cms/dynamic/${contentType}`, config)

export const getBrandRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(brandId: string, config?: C) =>
    apiClient.get<apiTypes.BrandResp, unknown, C>(`/brands/${brandId}`, config)

export const getLiveChatAvailability =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(transactionId: string, config?: C) =>
    apiClient.get<apiTypes.LiveChatAvailabilityResp, unknown, C>(
      `/help_center/live_chat_status?transaction_id=${transactionId || ''}`,
      config,
    )

export const validateBusinessInvitation =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(token: string, config?: C) =>
    apiClient.post<apiTypes.ValidateBusinessInvitationResp>(
      '/secondary_users/invitation/validate',
      { token },
      config,
    )

export const getDataExportLink =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(hash: string, config?: C) =>
    apiClient.get<apiTypes.GetDataExportLinkResp>(
      `/users/personal_data_export/link?hash=${hash}`,
      config,
    )

export const getTaxpayerCenterConfigRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(config?: C) =>
    apiClient.get<apiTypes.GetTaxpayerCenterConfigResp, unknown, C>('/taxpayers/center/', config)

export const getEscrowFeesRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(itemId: number | string, config?: C) =>
    apiClient.get<apiTypes.GetItemEscrowFeesResp, unknown, C>(
      `/items/${itemId}/escrow_fees`,
      config,
    )

export const getHomepageConfig = createGetRequest<apiTypes.HomepageConfigResp>('/homepage')

export const updateCurrentUserSettingsRequest =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(args: apiTypes.UpdateCurrentUserSettingsArgs, config?: C) =>
    apiClient.put<apiTypes.UserResp, unknown, C>(
      CURRENT_USER_API_ENDPOINT,
      {
        user: {
          user_settings: updateCurrentUserSettingsArgsToParams(args),
        },
      },
      config,
    )
export const confirmDataExport =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(
    { userId, code }: apiTypes.ConfirmDataExportArgs,
    config?: C,
  ) =>
    apiClient.post(`/users/${userId}/personal_data_export/confirmation`, { code }, config)

export const confirmMembership =
  (apiClient: ApiClient) =>
  <C extends ApiClientRequestConfig>(code: string, config?: C) =>
    apiClient.post('/users/confirmation', { code }, config)
