'use client'

import { useState } from 'react'
import { Badge, Icon } from '@vinted/web-ui'
import { useIntl } from 'react-intl'
import {
  CheckmarkShieldOutline16,
  AuthenticityDiamond16,
  Van16,
  ElectronicsBadge16,
} from '@vinted/monochrome-icons'

import { EscrowFeesModel } from 'types/models/escrow-fees'
import { CurrencyAmountModel } from 'types/models'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import DetailedItemPriceBreakdownModal from '../../DetailedItemPriceBreakdownModal'

type Props = {
  escrowFees: EscrowFeesModel
  isSellerBusiness: boolean
  itemUserId: number
  itemTitle: string
  itemPrice: CurrencyAmountModel
  itemPhotoSrc?: string
  itemId: number
}

const DiscountBadges = ({
  escrowFees,
  isSellerBusiness,
  itemUserId,
  itemTitle,
  itemPrice,
  itemPhotoSrc,
  itemId,
}: Props) => {
  const [isPriceBreakdownModalOpen, setIsPriceBreakdownModalOpen] = useState(false)
  const { track } = useTracking()
  const translate = useTranslate('item.discount_badges')

  const { user } = useSession()
  const userId = user?.id
  const { locale } = useIntl()

  const userTypeSuffix = isSellerBusiness ? '_pro' : ''
  const { buyerProtection, itemVerification, shipping, electronicsVerification } = escrowFees

  const itemHasDiscounts = !!(
    buyerProtection?.discountPercentage ||
    itemVerification?.discountPercentage ||
    electronicsVerification?.discountPercentage ||
    shipping?.maxDiscountPercentage
  )

  const isItemOwner = userId === itemUserId

  const handleModalOpen = () => {
    setIsPriceBreakdownModalOpen(true)

    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.DiscountInfo,
        targetDetails: JSON.stringify({
          item_id: itemId,
        }),
      }),
    )
  }

  const formattedItemPrice = formatCurrencyAmount(itemPrice, locale)

  const renderBuyerProtectionFee = () =>
    !!buyerProtection?.discountPercentage && (
      <Badge
        testId="buyer-protection-fee-discount-badge"
        theme="warning"
        styling={Badge.Styling.Light}
        icon={
          <Icon
            name={CheckmarkShieldOutline16}
            color={Icon.Color.WarningDark}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        content={
          buyerProtection?.isFree
            ? translate(`free_buyer_protection_fee${userTypeSuffix}`)
            : translate(`buyer_protection_fee${userTypeSuffix}`, {
                discount: buyerProtection.discountPercentage,
              })
        }
      />
    )

  const renderItemVerificationFee = () =>
    !!itemVerification?.discountPercentage && (
      <Badge
        testId="item-verification-fee-discount-badge"
        theme="warning"
        styling={Badge.Styling.Light}
        icon={
          <Icon
            name={AuthenticityDiamond16}
            color={Icon.Color.WarningDark}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        content={
          itemVerification?.isFree
            ? translate('free_item_verification_fee')
            : translate('item_verification_fee', {
                discount: itemVerification?.discountPercentage,
              })
        }
      />
    )

  const renderShippingFee = () =>
    !!shipping?.maxDiscountPercentage && (
      <Badge
        testId="shipping-discount-badge"
        theme="warning"
        styling={Badge.Styling.Light}
        icon={
          <Icon
            name={Van16}
            color={Icon.Color.WarningDark}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        content={translate('postage', {
          discount: shipping.maxDiscountPercentage,
        })}
      />
    )

  const renderElectronicsVerificationFee = () =>
    !!electronicsVerification?.discountPercentage && (
      <Badge
        testId="electronics-verification-fee-discount-badge"
        theme="warning"
        styling={Badge.Styling.Light}
        icon={
          <Icon
            name={ElectronicsBadge16}
            color={Icon.Color.WarningDark}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        content={
          electronicsVerification?.isFree
            ? translate('free_electronics_verification_fee')
            : translate('electronics_verification_fee', {
                discount: electronicsVerification?.discountPercentage,
              })
        }
      />
    )

  if (!itemHasDiscounts || isItemOwner) return null

  return (
    <div data-testid="discount-badges-container">
      <button
        type="button"
        data-testid="discount-badge-button"
        className="u-flexbox u-flex-wrap u-gap-small u-padding-vertical-medium"
        onClick={handleModalOpen}
      >
        {renderBuyerProtectionFee()}
        {renderItemVerificationFee()}
        {renderElectronicsVerificationFee()}
        {renderShippingFee()}
      </button>
      <DetailedItemPriceBreakdownModal
        isVisible={isPriceBreakdownModalOpen}
        onClose={() => setIsPriceBreakdownModalOpen(false)}
        itemTitle={itemTitle}
        itemPrice={formattedItemPrice}
        itemPhotoSrc={itemPhotoSrc}
        escrowFees={escrowFees}
        isSellerBusiness={isSellerBusiness}
        isItemOwner={isItemOwner}
        itemId={itemId}
      />
    </div>
  )
}

export default DiscountBadges
