'use client'

import { Button } from '@vinted/web-ui'

import { MARK_ITEM_AS_RESERVED_URL } from 'constants/routes'
import { markAsUnreserved } from 'data/api'
import useRefUrl from 'hooks/useRefUrl'
import { useSession } from '@marketplace-web/shared/session'
import useTranslate from 'hooks/useTranslate'
import { urlWithParams } from '_libs/utils/url'
import { reloadPage } from '_libs/utils/window'

import { ItemPageMarkAsReservedButtonPluginModel } from './types'

type Props = {
  data: ItemPageMarkAsReservedButtonPluginModel | undefined
}

const ItemPageMarkAsReservedButtonPlugin = ({ data }: Props) => {
  const translate = useTranslate('item')
  const refUrl = useRefUrl()
  const { user: currentUser } = useSession()

  if (!data) return null

  const { itemId, isItemReserved, canReserve, transactionPermitted, userId } = data
  const isViewingOwnItem = userId === currentUser?.id

  if (!isViewingOwnItem) return null
  if (!(canReserve && transactionPermitted)) return null

  const handleClick = async () => {
    await markAsUnreserved({ itemId })

    reloadPage()
  }

  if (isItemReserved) {
    return (
      <Button
        text={translate('actions.mark_as_unreserved')}
        styling={Button.Styling.Outlined}
        testId="mark-as-unreserved-button"
        size={Button.Size.Medium}
        onClick={handleClick}
      />
    )
  }

  const markAsReservedUrl = urlWithParams(MARK_ITEM_AS_RESERVED_URL(itemId), {
    ref_url: refUrl,
  })

  return (
    <Button
      text={translate('actions.mark_as_reserved')}
      styling={Button.Styling.Outlined}
      url={markAsReservedUrl}
      testId="mark-as-reserved-button"
      size={Button.Size.Medium}
    />
  )
}

export default ItemPageMarkAsReservedButtonPlugin
