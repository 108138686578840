'use client'

import classNames from 'classnames'
import { Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  isMobile?: boolean
}

const AdvertisingHeroText = ({ isMobile = false }: Props) => {
  const translate = useTranslate('advertising.hero')

  return (
    <div className={`advertising__${isMobile ? 'mobile-' : ''}hero--text`}>
      <Spacer size={isMobile ? Spacer.Size.X2Large : Spacer.Size.X5Large} />
      <Text as="h1" type={Text.Type.Heading} width={Text.Width.Parent}>
        <span
          className={classNames('c-text--responsive-large-heading', !isMobile && 'c-text--inverse')}
        >
          {translate('title')}
        </span>
      </Text>
      <Spacer size={Spacer.Size.X2Large} />
      <Text as="span" html width={Text.Width.Parent}>
        <span className={classNames(!isMobile && 'c-text--inverse')}>
          <span className="advertising__hero--description">{translate('description')}</span>
        </span>
      </Text>
      <Spacer size={isMobile ? Spacer.Size.X2Large : Spacer.Size.X5Large} />
    </div>
  )
}

export default AdvertisingHeroText
