'use client'

import { MouseEvent } from 'react'
import { Button } from '@vinted/web-ui'

import useRefUrl from 'hooks/useRefUrl'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'

import { SIGNUP_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'
import { toUrlQuery } from '_libs/utils/url'
import usePageId from 'hooks/usePageId'

import useAuthModal from '../../hooks/useAuthModal/useAuthModal'

const HeaderLoginButton = () => {
  const refUrl = useRefUrl()
  const translate = useTranslate()
  const { track } = useTracking()
  const { openAuthModal } = useAuthModal()
  const pageId = usePageId()
  const isAuthPageOpen = pageId === 'member-signup-select-type'

  function handleClick(event: MouseEvent) {
    track(clickEvent({ target: ClickableElement.Login }))

    if (isAuthPageOpen) return

    event.preventDefault()
    openAuthModal()
  }

  return (
    <Button
      text={translate('header.actions.login')}
      size={Button.Size.Small}
      url={`${SIGNUP_URL}?${toUrlQuery({ ref_url: refUrl })}`}
      onClick={handleClick}
      testId="header--login-button"
    />
  )
}

export default HeaderLoginButton
