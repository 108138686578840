'use client'

import { createContext } from 'react'

import { UiState } from 'constants/ui'
import { HttpStatus } from 'data/api/response-codes'
import { Conversation } from 'state/conversation/types'
import { TransactionModel } from 'types/models'

export type GetConversationRequestArgs = {
  conversationId: number | string
  fromPostReply?: boolean
}

export type ConversationContextType = {
  conversation: Conversation | undefined
  conversationUiState: UiState
  conversationRequestStatus: HttpStatus | null
  getConversationRequest: ({ conversationId, fromPostReply }: GetConversationRequestArgs) => void
  setConversation: (conversation: Conversation) => void
  conversationError: string | null
  clearConversation: () => void
  transactionUiState: UiState
  transaction: TransactionModel | null
  recipientId: number | null
}

export const ConversationContext = createContext<ConversationContextType | null>(null)
