import { createAction } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

import { Screen } from 'constants/tracking/screens'
import { FieldErrorType } from 'pages/ItemUpload/types'
import { AssignedPhotoDto, GtmTrackableItemDto } from 'types/dtos'
import { Attributes } from 'pages/ItemUpload/types/context'
import { UserModel } from 'types/models'

import { ItemStatus, stateName } from './constants'
import { DynamicAttribute } from './types'

type SubmitItemActionArgs = {
  tempUuid: string
  user: Pick<UserModel, 'externalId' | 'email' | 'id'>
  itemStatus: ItemStatus
  screenName: Screen
  saveAsDraft: boolean
  isItemBumped: boolean
  trackListingToGoogleTagManager: (item: GtmTrackableItemDto) => void
  trackListingToBraze: (args: {
    itemId: number | undefined
    userExternalId: string | undefined | null
  }) => void
  setFieldErrors: Dispatch<SetStateAction<FieldErrorType>>
  showIVSModal: () => void
  showEVSModal: () => void
  assignedPhotos: Array<AssignedPhotoDto>
  enableForm: () => void
  disableForm: () => void
  dynamicAttributes: Array<DynamicAttribute>
  attributes: Attributes
  origin?: string
  openLuxuryItemModal: () => void
  openMissingPostalCodeModal: () => void
  currency: string
  feedbackId: number | null
}

export const submitItem = createAction(
  `${stateName}/submitItem`,
  ({
    user,
    tempUuid,
    itemStatus,
    screenName,
    saveAsDraft,
    isItemBumped,
    trackListingToGoogleTagManager,
    trackListingToBraze,
    setFieldErrors,
    showIVSModal,
    showEVSModal,
    assignedPhotos,
    enableForm,
    disableForm,
    dynamicAttributes,
    attributes,
    origin,
    openLuxuryItemModal,
    openMissingPostalCodeModal,
    currency,
    feedbackId,
  }: SubmitItemActionArgs) => ({
    payload: {
      openLuxuryItemModal,
      openMissingPostalCodeModal,
      user,
      tempUuid,
      itemStatus,
      screenName,
      trackListingToGoogleTagManager,
      trackListingToBraze,
      setFieldErrors,
      showIVSModal,
      showEVSModal,
      assignedPhotos,
      enableForm,
      disableForm,
      dynamicAttributes,
      attributes,
      origin,
      currency,
      feedbackId,
    },
    meta: {
      saveAsDraft,
      isItemBumped,
    },
  }),
)

export const fetchPackageSizesRequest = createAction(`${stateName}/fetchPackageSizesRequest`)
