import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { ClientCookieManager, cookiesDataByName } from '@marketplace-web/shared/cookies'
import { serverSide } from '@marketplace-web/shared/environment'
import { logWarning } from '@marketplace-web/shared/logging'
import { navigateToPage } from '_libs/utils/window'

import { HEADERS } from 'constants/header'
import { ResponseCode } from 'data/api/response-codes'
import { USERS_VERIFICATION_URL } from 'constants/routes'
import { TWO_FA_CANCELLED_EVENT, TWO_FA_COMPLETED_EVENT } from 'constants/index'
import { extractErrorResponseCode } from 'data/utils/axios'

const cookies = new ClientCookieManager()

export const railsCsrfTokenInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.request.use(config => {
    if (serverSide) return config

    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

    if (csrfToken) config.headers.set(HEADERS.X_CSRF_TOKEN, csrfToken)

    return config
  })

export const nextJsCsrfTokenInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.request.use(config => {
    const csrfToken = process.env.NEXT_PUBLIC_CSRF_TOKEN

    if (!csrfToken) throw new Error('Missing Next.js CSRF token')

    config.headers.set(HEADERS.X_CSRF_TOKEN, csrfToken)

    return config
  })

export const csrfTokenInterceptor =
  process.env.NEXT_PUBLIC_NEXT_JS === 'true'
    ? nextJsCsrfTokenInterceptor
    : railsCsrfTokenInterceptor

export const errorInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.response.use((response: AxiosResponse) => {
    if (typeof response.data.code === 'undefined') {
      logWarning(
        `API code is missing in response: ${response.request.responseURL} with status: ${response.status}`,
      )
    }

    if (response.data.code && response.data.code !== ResponseCode.Ok) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ response })
    }

    return Promise.resolve(response)
  })

export const localeInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.request.use(config => {
    const locale = document.querySelector('meta[name="accept-language"]')?.getAttribute('content')

    if (!locale) {
      logWarning('Unable to retrieve locale for API call')
    }

    if (locale) config.headers.set(HEADERS.ACCEPT_LANGUAGE, locale)

    return config
  })

export const phoneVerificationInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (extractErrorResponseCode(error) === ResponseCode.UserVerificationRequired) {
      navigateToPage(USERS_VERIFICATION_URL)
    }

    return Promise.reject(error)
  })

function isTwoFaRequiredErrorResponse(
  error: AxiosError,
): error is AxiosError<{ payload: { entity_id: string } }> {
  return extractErrorResponseCode(error) === ResponseCode.TwoFARequired
}

export const twoFAInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (!isTwoFaRequiredErrorResponse(error) || !error.response) return Promise.reject(error)

    const { response } = error

    const event = new CustomEvent<string>('twoFARequired', {
      detail: response.data.payload.entity_id,
    })

    window.dispatchEvent(event)

    return new Promise((resolve, reject) => {
      const abortController = new AbortController()

      const repeatRequest = () => {
        instance.request(response.config).then(resolve).catch(reject)
        abortController.abort()
      }

      const rejectRequest = () => {
        reject(error)
        abortController.abort()
      }

      window.addEventListener(TWO_FA_COMPLETED_EVENT, repeatRequest, {
        signal: abortController.signal,
      })

      window.addEventListener(TWO_FA_CANCELLED_EVENT, rejectRequest, {
        signal: abortController.signal,
      })
    })
  })

export const anonIdInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(config => {
    const anonId = cookies.get(cookiesDataByName.anon_id)

    config.headers.set(HEADERS.X_ANON_ID, anonId)

    return config
  })
}

export const appendAcceptWebPHeaderInterceptor = (instance: AxiosInstance) =>
  instance.interceptors.request.use(config => {
    if (!config.headers.hasAccept('image/webp')) {
      const accept = config.headers.getAccept()?.toString().split(',') ?? []

      accept.push('image/webp')

      config.headers.setAccept(accept)
    }

    return config
  })
