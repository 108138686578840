'use client'

import { Container } from '@vinted/web-ui'

import { Error, StackTrace } from '@marketplace-web/shared/ui-helpers'
import { useSession } from '@marketplace-web/shared/session'
import useTranslate from 'hooks/useTranslate'

type Props = {
  error: Error & { digest?: string }
  reset: () => void
}

const ErrorPage = ({ error, reset }: Props) => {
  const translate = useTranslate('error_page')
  const isGod = useSession().user?.is_god

  return (
    <Error
      action={translate('actions.retry')}
      body={translate('client_error.body')}
      title={translate('client_error.title')}
      onActionClick={reset}
    >
      {isGod && (
        <Container>
          <StackTrace error={error} />
        </Container>
      )}
    </Error>
  )
}

export default ErrorPage
