'use client'

import { useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { Cell, Spacer, Text } from '@vinted/web-ui'

import { ContentSource } from 'constants/tracking/content-sources'
import useFetch from 'hooks/useFetch'
import useTranslate from 'hooks/useTranslate'
import { getItemsByPluginName } from 'data/api'
import ContentLoader from 'components/ContentLoader'
import ItemViewItems from 'components/ItemViewItems'
import { transformHorizontalScrollBlockItemDtoToProductItem } from 'data/transformers/horizontal-scroll-block'

import { ItemPageSimilarItemsPluginModel } from './types'
import { HorizontalScrollBlockItemModel } from '../HorizontalScrollBlock'

type Props = {
  data?: ItemPageSimilarItemsPluginModel
}

const ItemPageSimilarItemsPlugin = ({ data }: Props) => {
  const { fetch: fetchSimilarItems, data: similarItemsData } = useFetch(getItemsByPluginName)
  const hasFetched = useRef(false)

  const translate = useTranslate('item')

  const items: Array<HorizontalScrollBlockItemModel> = useMemo(() => {
    return similarItemsData?.items.map(transformHorizontalScrollBlockItemDtoToProductItem) || []
  }, [similarItemsData])

  if (!data) return null

  const handleInViewChange = (inView: boolean) => {
    if (!inView || hasFetched.current) return

    fetchSimilarItems({ itemId: data.itemId.toString(), name: 'similar_items' })
    hasFetched.current = true
  }

  const renderLoader = () =>
    !similarItemsData && (
      <div className="u-ui-margin-top-medium">
        <ContentLoader testId="item-feed-loader" />
      </div>
    )

  return (
    <InView as="section" data-testid="item-page-similar-items-plugin" onChange={handleInViewChange}>
      {renderLoader()}
      {items.length > 0 && (
        <>
          <Spacer size={Spacer.Size.XLarge} />
          <Cell
            title={
              <Text type={Text.Type.Title} as="h2">
                {translate('similar_items_title')}
              </Text>
            }
            styling={Cell.Styling.Tight}
            testId="item-page-similar_items_header-plugin"
          />
          <ItemViewItems
            items={items}
            contentSource={ContentSource.SimilarItems}
            itemTestId="similar-item"
          />
        </>
      )}
    </InView>
  )
}

export default ItemPageSimilarItemsPlugin
