'use client'

import { useContext } from 'react'
import { Cell } from '@vinted/web-ui'

import ItemPageGalleryPlugin from '../plugins/Gallery'
import { ItemPageGalleryPluginModel } from '../plugins/Gallery/types'
import ItemPageReportButtonPlugin from '../plugins/ReportButton'
import { ItemPageReportButtonPluginModel } from '../plugins/ReportButton/types'
import { PluginsContext } from '../containers/PluginsProvider'
import Plugin from '../plugins/Plugin'
import { PluginName } from '../types'

type Props = {
  galleryPluginModel: ItemPageGalleryPluginModel | undefined
  reportButtonPluginModel: ItemPageReportButtonPluginModel | undefined
}

const ContentSection = ({ galleryPluginModel, reportButtonPluginModel }: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)

  return (
    <>
      <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Alert)} />
      {galleryPluginModel && <ItemPageGalleryPlugin data={galleryPluginModel} />}
      <Cell
        theme="transparent"
        styling={Cell.Styling.Tight}
        body={
          <Plugin
            data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Breadcrumbs)}
          />
        }
        suffix={<ItemPageReportButtonPlugin data={reportButtonPluginModel} />}
      />
    </>
  )
}

export default ContentSection
