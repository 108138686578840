'use client'

import { ReactNode, useContext, useState } from 'react'
import { Card, Cell, Image, Spacer, Text } from '@vinted/web-ui'
import { useIntl } from 'react-intl'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'

import { formatCurrencyAmount } from '_libs/utils/formatString'
import { clickEvent } from '_libs/common/event-tracker/events'

import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ShippingDetailsContext } from 'pages/Item/containers/ShippingDetailsProvider'
import { EscrowFeesContext } from 'pages/Item/containers/EscrowFeesProvider'

import { ItemPageOfflineVerificationBlockPluginModel } from './types'
import OfflineVerificationSellerModal from '../OfflineVerification'
import OfflineVerificationBuyerModal from '../OfflineVerificationBuyerModal'

type Props = {
  data: ItemPageOfflineVerificationBlockPluginModel
}

const ItemPageOfflineVerificationBlockPlugin = ({ data }: Props) => {
  const { user } = useSession()
  const { locale } = useIntl()
  const { shippingDetails } = useContext(ShippingDetailsContext)
  const { escrowFees } = useContext(EscrowFeesContext)

  const isViewingOwnItem = user?.id === data?.itemUserId
  const userSide = isViewingOwnItem ? 'seller' : 'buyer'

  const { track } = useTracking()
  const asset = useAsset('/assets/offline-authenticity')
  const translate = useTranslate(`item.authenticity_fee.${userSide}`)

  const [isBuyerModalOpen, setIsBuyerModalOpen] = useState(false)
  const [isSellerModalOpen, setIsSellerModalOpen] = useState(false)

  if (!shippingDetails) return null

  const getFormattedVerificationFee = () => {
    const finalPrice = escrowFees?.itemVerification?.finalPrice

    return (
      data.isOfflineVerificationAvailable && finalPrice && formatCurrencyAmount(finalPrice, locale)
    )
  }

  const formattedVerificationFee = getFormattedVerificationFee()

  if (!formattedVerificationFee) return null

  const verificationIconPath = asset('authenticity-diamond-48.svg', {
    theme: { dark: 'authenticity-diamond-48-dark.svg' },
  })

  const handleIsModalOpen = (isOpen: boolean) =>
    isViewingOwnItem ? setIsSellerModalOpen(isOpen) : setIsBuyerModalOpen(isOpen)

  const handleClick = () => {
    handleIsModalOpen(true)

    const target = isViewingOwnItem
      ? ClickableElement.PhysicalAuthenticitySeller
      : ClickableElement.PhysicalAuthenticityBuyer

    track(clickEvent({ target, targetDetails: JSON.stringify({ position: 'bottom' }) }))
  }

  const handleClose = () => {
    handleIsModalOpen(false)
  }

  const howItWorksLink = {
    'how-it-works': (chunks: Array<ReactNode>) => (
      <span>
        <button type="button" onClick={handleClick}>
          <Text
            as="span"
            clickable
            text={chunks}
            type={Text.Type.Subtitle}
            testId="item-offline-verification-block-how-it-works-link"
          />
        </button>
      </span>
    ),
  }

  const renderContent = () => (
    <>
      <Spacer />
      <Cell
        styling={Cell.Styling.Tight}
        prefix={
          <Image
            src={verificationIconPath}
            size={Image.Size.Medium}
            testId="item-offline-verification-block-icon"
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        title={
          <Text
            as="h2"
            text={translate('title')}
            theme="amplified"
            type={Text.Type.Title}
            testId="item-offline-verification-block-title"
          />
        }
        body={
          <>
            {!isViewingOwnItem && (
              <>
                <Text
                  as="h3"
                  text={translate('fee', { amount: formattedVerificationFee })}
                  theme="inherit"
                  type={Text.Type.Subtitle}
                  testId="item-offline-verification-block-amount"
                />
                <Spacer />
              </>
            )}
            <Text
              as="h3"
              text={translate('body', howItWorksLink)}
              theme="inherit"
              type={Text.Type.Subtitle}
              testId="item-offline-verification-block-description"
              html
            />
          </>
        }
      />
      <OfflineVerificationBuyerModal
        show={isBuyerModalOpen}
        onClose={handleClose}
        verificationFee={formattedVerificationFee}
      />
      <OfflineVerificationSellerModal show={isSellerModalOpen} onClose={handleClose} />
    </>
  )

  return (
    <Card>
      <div className="u-overflow-hidden">
        <Cell>{renderContent()}</Cell>
      </div>
    </Card>
  )
}

export default ItemPageOfflineVerificationBlockPlugin
