'use client'

import { createContext } from 'react'

import { ItemShippingDetailsModel } from 'types/models/shipping-option'

export type ShippingDetailsContextType = {
  shippingDetails?: ItemShippingDetailsModel | null
}

const ShippingDetailsProvider = createContext<ShippingDetailsContextType>({
  shippingDetails: null,
})

export default ShippingDetailsProvider
