import { plug as conversation } from './conversation/slice'
import { plug as session } from './session/slice'
import { plug as screen } from './screen/slice'
import { plug as banners } from './banners/slice'
import { plug as checkout } from './checkout'
import { plug as abTests } from './ab-tests/slice'
import { plug as systemConfiguration } from './system-configuration/slice'

export const staticReducers = {
  ...session,
  ...screen,
  ...abTests,
}

export const sharedReducers = {
  ...systemConfiguration,
}

// Reducers for core app that are not split by route
export const coreReducers = {
  ...sharedReducers,
  ...conversation,
  ...banners,
  ...checkout,
}
