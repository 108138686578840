'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import usePageId from 'hooks/usePageId'
import { useSession } from '@marketplace-web/shared/session'

const pageConfigMessage = '`%s` is `unknown`, you can set it in `get-page-configuration.ts`'

type Props = {
  requireAuthorization: boolean
}

const ValidatePageConfigs = ({ requireAuthorization }: Props) => {
  const pageId = usePageId()
  const { screen } = useSession()
  const pathname = usePathname()
  const skipScreenValidation = pathname?.startsWith('/dev/') || pathname === '/revision'

  useEffect(() => {
    if (skipScreenValidation) return
    if (screen !== 'unknown') return

    throw new Error(pageConfigMessage.replace('%s', 'screen'))
  }, [skipScreenValidation, screen])

  useEffect(() => {
    if (pageId !== 'unknown') return

    throw new Error(pageConfigMessage.replace('%s', 'pageId'))
  }, [pageId])

  useEffect(() => {
    if (!requireAuthorization) return
    if (document.querySelectorAll('link[rel="alternate"]').length) return

    throw new Error('use generatePageMetadata to set meta tags')
  }, [requireAuthorization])

  return null
}

export default ValidatePageConfigs
