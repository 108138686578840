'use client'

import { createContext } from 'react'

import { OfferPriceSuggestionOption } from 'state/conversation/types'
import { OfferRequestOptionsModel } from 'types/models'

export type OfferContextType = {
  offerRequestSessionId?: string
  setOfferRequestSessionId: (id: string) => void
  offerRequestOptions?: OfferRequestOptionsModel
  selectedOfferRequestPriceSuggestion?: OfferPriceSuggestionOption
  setSelectedOfferRequestPriceSuggestion: (offerPriceSuggestion: OfferPriceSuggestionOption) => void
}

export const OfferContext = createContext<OfferContextType | null>(null)
