'use client'

import { useEffect } from 'react'
import { Loader } from '@vinted/web-ui'

import useFetch from 'hooks/useFetch'
import { createUser2FA } from 'data/api'
import { transformTwoFactorVerification } from 'state/checkout/transformers'
import { VerificationType, TwoFactorVerification } from '@marketplace-web/domain/verification'
import { getFingerprint } from '@marketplace-web/domain/audit'
import { useSession } from '@marketplace-web/shared/session'

type Props = {
  entityId: string
  onCodeSent: () => void
  onError: () => void
}

const GlobalTwoFA = ({ entityId, onCodeSent, onError }: Props) => {
  const userId = useSession().user?.id

  const handleCreateUser2FA = async (args: { userId: number; entityId: string }) => {
    return createUser2FA({
      userId: args.userId,
      entityId: args.entityId,
      entityType: VerificationType.EntityHash,
      fingerprint: await getFingerprint(),
    })
  }

  const { fetch, transformedData, isLoading, error } = useFetch(
    handleCreateUser2FA,
    transformTwoFactorVerification,
  )

  useEffect(() => {
    if (!userId || !entityId) return

    fetch({ userId, entityId })
  }, [fetch, userId, entityId])

  useEffect(() => {
    if (!error) return

    onError()
  }, [error, onError])

  if (isLoading || !transformedData) {
    return (
      <div className="u-flexbox u-justify-content-center">
        <Loader size={Loader.Size.XLarge} testId="global-two-FA-loader" />
      </div>
    )
  }

  return (
    <div className="narrow-container">
      <TwoFactorVerification
        verificationType={VerificationType.EntityHash}
        maskedInfo={transformedData.userMaskedInfo}
        nextResendAvailableIn={transformedData.nextResendAvailableIn}
        showResend={transformedData.showResendOption}
        twoFAId={transformedData.id}
        onCodeSent={onCodeSent}
      />
    </div>
  )
}

export default GlobalTwoFA
