'use client'

import { ReactNode } from 'react'
import { Button, Cell, Spacer, Text } from '@vinted/web-ui'

import { PRIVACY_POLICY_URL } from 'constants/routes'
import useTranslate from 'hooks/useTranslate'

type Props = {
  isLoading: boolean
}

const FormSubmit = ({ isLoading }: Props) => {
  const translate = useTranslate('advertising.form.submit')

  const disclaimerValues = {
    'policy-link': (chunks: Array<ReactNode>) => (
      <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
        {chunks}
      </a>
    ),
  }

  return (
    <>
      <Spacer size={Spacer.Size.XLarge} />
      <div className="u-phones-only">
        <Text text={translate('disclaimer', disclaimerValues)} type={Text.Type.Caption} as="p" />
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            if (isLoading) {
              event.preventDefault()
            }
          }}
        >
          {translate('action')}
        </Button>
      </div>
      <div className="u-tablets-up-only">
        <Cell
          styling={Cell.Styling.Tight}
          body={
            <Text
              text={translate('disclaimer', disclaimerValues)}
              type={Text.Type.Caption}
              as="p"
            />
          }
          suffix={
            <Button
              type={Button.Type.Submit}
              styling={Button.Styling.Filled}
              disabled={isLoading}
              isLoading={isLoading}
              onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
                if (isLoading) {
                  event.preventDefault()
                }
              }}
            >
              {translate('action')}
            </Button>
          }
        />
      </div>
    </>
  )
}

export default FormSubmit
