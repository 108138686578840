'use client'

import { useCallback, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as conversationSelectors from 'state/conversation/selectors'
import { actions } from 'state/conversation/slice'

import { OfferPriceSuggestionOption } from 'state/conversation/types'

import { OfferContext } from './OfferContext'

type Props = {
  children: React.ReactNode
}

const OfferProvider = ({ children }: Props) => {
  const dispatch = useDispatch()

  const offerRequestSessionId = useSelector(conversationSelectors.getOfferRequestSessionId)
  const offerRequestOptions = useSelector(conversationSelectors.getOfferRequestOptions)
  const selectedOfferRequestPriceSuggestion = useSelector(
    conversationSelectors.getSelectedOfferPriceSuggestionOption,
  )

  const setSelectedOfferRequestPriceSuggestion = useCallback(
    (offerPriceSuggestionOption: OfferPriceSuggestionOption) => {
      dispatch(actions.setSelectedOfferPriceSuggestionOption({ offerPriceSuggestionOption }))
    },
    [dispatch],
  )

  const setOfferRequestSessionId = useCallback(
    (id: string) => {
      dispatch(actions.setOfferRequestSessionId({ offerRequestSessionId: id }))
    },
    [dispatch],
  )

  const contextValue = useMemo(
    () => ({
      offerRequestSessionId,
      setOfferRequestSessionId,
      offerRequestOptions,
      selectedOfferRequestPriceSuggestion,
      setSelectedOfferRequestPriceSuggestion,
    }),
    [
      offerRequestSessionId,
      setOfferRequestSessionId,
      offerRequestOptions,
      selectedOfferRequestPriceSuggestion,
      setSelectedOfferRequestPriceSuggestion,
    ],
  )

  return <OfferContext.Provider value={contextValue}>{children}</OfferContext.Provider>
}

export default OfferProvider
