'use client'

import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import { logMessage } from '@marketplace-web/shared/logging'
import { useSession } from '@marketplace-web/shared/session'
import { getUserStats } from 'data/api'
import { transformUserStatsDto } from 'data/transformers/user-stats'
import { UserStatsModel } from 'types/models'

export type UserStatsContextValue = {
  userStats: UserStatsModel | undefined
  fetchUserStats: () => void
  setUnreadMsgCount: (unreadConversationCount: number) => void
}

type Props = {
  children: ReactNode
}

export const UserStatsContext = createContext<UserStatsContextValue>({
  userStats: undefined,
  fetchUserStats: () => {
    throw new Error('fetchUserStats must be provided in UserStatsProvider')
  },
  setUnreadMsgCount: () => {
    throw new Error('setUnreadMsgCount must be provided in UserStatsProvider')
  },
})

const UserStatsProvider = ({ children }: Props) => {
  const [userStats, setUserStats] = useState<UserStatsModel>()
  const { user } = useSession()

  const handleApiResponse = useCallback((response: Awaited<ReturnType<typeof getUserStats>>) => {
    if ('errors' in response) return

    setUserStats(transformUserStatsDto(response.stats))
  }, [])

  const fetchUserStats = useCallback(() => {
    if (!user?.id) return

    getUserStats(user.id).then(handleApiResponse)
  }, [user, handleApiResponse])

  const setUnreadMsgCount = useCallback(
    (unreadMsgCount: number) => {
      if (!userStats) {
        logMessage(
          'UserStatsProvider: unread msg count should not be set before user stats are fetched',
        )

        return
      }

      setUserStats({
        ...userStats,
        unreadMsgCount,
      })
    },
    [userStats],
  )

  const contextValues = useMemo(
    () => ({
      userStats,
      fetchUserStats,
      setUnreadMsgCount,
    }),
    [userStats, fetchUserStats, setUnreadMsgCount],
  )

  return <UserStatsContext.Provider value={contextValues}>{children}</UserStatsContext.Provider>
}

export default UserStatsProvider
