'use client'

import { Button, Cell, Image, Spacer, Text } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import FollowButton from 'components/FollowButton'

import useAsset from 'hooks/useAsset'
import useClosetCountdown from 'hooks/useClosetCountdown'
import useTranslate from 'hooks/useTranslate'
import { useSession } from '@marketplace-web/shared/session'

type Props = {
  userId: number
  ownerLogin: string
  isFollowed: boolean
  endDate: string
  isHatePresent: boolean
}

const ItemCountdownBanner = ({ userId, ownerLogin, isFollowed, endDate, isHatePresent }: Props) => {
  const { formattedRemainingTime } = useClosetCountdown(endDate)
  const translate = useTranslate('closet_countdown')
  const currentUserId = useSession().user?.id
  const asset = useAsset('/assets/closet-countdown')

  const showFollowAction = userId !== currentUserId && !isHatePresent
  const iconPath = asset('clothes-star-64.png', { theme: { dark: '/dark/clothes-star-64.png' } })

  const renderCountdown = () => {
    if (!formattedRemainingTime) return null

    const { days, hours, minutes, seconds } = formattedRemainingTime

    return (
      <ul className="closet-countdown__timer">
        <SeparatedList separator={<Spacer orientation={Spacer.Orientation.Vertical} />}>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{days}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.days', undefined, { count: days })}
            </div>
          </li>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{hours}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.hours', undefined, { count: hours })}
            </div>
          </li>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{minutes}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.minutes', undefined, { count: minutes })}
            </div>
          </li>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{seconds}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.seconds', undefined, { count: seconds })}
            </div>
          </li>
        </SeparatedList>
      </ul>
    )
  }

  return (
    <section>
      <Spacer size={Spacer.Size.Large} />
      <Cell
        styling={Cell.Styling.Tight}
        suffix={
          <div className="closet-countdown__image">
            <Image src={iconPath} size={Image.Size.XLarge} alt="" styling={Image.Styling.Rounded} />
          </div>
        }
      >
        <Text as="span" theme="amplified">
          {translate('subtitle')}
        </Text>
        <Spacer />
        {renderCountdown()}
        {showFollowAction && (
          <>
            <Spacer size={Spacer.Size.XLarge} />
            <Text as="h2" type={Text.Type.Title}>
              {translate('title', { username: ownerLogin })}
            </Text>
          </>
        )}
        <Spacer size={Spacer.Size.Small} />
        <Text type={Text.Type.Caption} as="p" html>
          {translate('disclaimer')}
        </Text>
        <Spacer size={Spacer.Size.XLarge} />
      </Cell>
      {showFollowAction && (
        <>
          <FollowButton
            userId={userId}
            username={ownerLogin}
            isActive={isFollowed}
            size={Button.Size.Medium}
          />
          <Spacer />
        </>
      )}
    </section>
  )
}

export default ItemCountdownBanner
