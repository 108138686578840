'use client'

import { MouseEvent, useEffect, useState } from 'react'
import { Badge, Button, Icon } from '@vinted/web-ui'
import { Bell24 } from '@vinted/monochrome-icons'

import OutsideClick from 'components/OutsideClick'
import { NOTIFICATIONS_URL } from 'constants/routes'
import { UiState } from 'constants/ui'
import useFetchInboxNotifications from 'hooks/useFetchInboxNotifications'
import useTranslate from 'hooks/useTranslate'
import useBrazeControlCardImpressionLogging from '_libs/common/braze/hooks/useBrazeControlCardImpressionLogging'

import HeaderNotificationDropdown from './HeaderNotificationDropdown'

const MAX_NOTIFICATIONS_AMOUNT = 5
const MAX_BADGE_COUNT = 99

type Props = {
  unreadNotificationCount?: number
  onNotificationsFetched?: () => void
}

const HeaderNotifications = ({ unreadNotificationCount = 0, onNotificationsFetched }: Props) => {
  const translate = useTranslate('header.a11y.notifications')
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const {
    notifications,
    fetchUserNotifications,
    controlNotificationCards,
    setNotifications,
    brazeNotificationCount,
    uiState,
  } = useFetchInboxNotifications()

  useBrazeControlCardImpressionLogging(
    isDropdownVisible && controlNotificationCards
      ? controlNotificationCards.map(({ id }) => id)
      : [],
  )

  useEffect(() => {
    if (uiState === UiState.Success) onNotificationsFetched?.()
  }, [uiState, onNotificationsFetched])

  function handleDropdownVisibilityChange() {
    const wasVisible = isDropdownVisible

    if (wasVisible) {
      setNotifications(items =>
        items.map(item => (item.isViewed ? item : { ...item, isViewed: true })),
      )
    }

    if (!wasVisible && (uiState === UiState.Idle || uiState === UiState.Failure)) {
      fetchUserNotifications()
    }

    setIsDropdownVisible(!wasVisible)
  }

  function handleClick(event: MouseEvent) {
    event.preventDefault()

    handleDropdownVisibilityChange()
  }

  function handleOutsideClick() {
    if (!isDropdownVisible) return

    handleDropdownVisibilityChange()
  }

  const mergedNotificationCount = unreadNotificationCount + brazeNotificationCount
  const badgeNotificationCount = Math.min(mergedNotificationCount, MAX_BADGE_COUNT)
  const notificationSuffix = mergedNotificationCount > MAX_BADGE_COUNT ? '+' : ''

  return (
    <OutsideClick onOutsideClick={handleOutsideClick}>
      <Button
        testId="header-notification-button"
        onClick={handleClick}
        styling={Button.Styling.Flat}
        aria={{
          'aria-label': mergedNotificationCount
            ? translate('new_notifications', { count: mergedNotificationCount })
            : translate('notifications'),
        }}
        icon={
          <Icon
            name={Bell24}
            color={Icon.Color.GreyscaleLevel2}
            testId="header-notifications-icon"
          />
        }
        url={NOTIFICATIONS_URL}
      />
      {!!badgeNotificationCount && (
        <div
          className="user-actions__notification u-no-pointer-events"
          data-testid="header-notifications-badge"
        >
          <Badge
            content={
              <span aria-hidden="true">{`${badgeNotificationCount}${notificationSuffix}`}</span>
            }
            theme="warning"
          />
        </div>
      )}
      {isDropdownVisible && (
        <div className="header-notification-dropdown" data-testid="header-notification-dropdown">
          <HeaderNotificationDropdown
            isLoading={uiState === UiState.Pending}
            notifications={notifications.slice(0, MAX_NOTIFICATIONS_AMOUNT)}
          />
        </div>
      )}
    </OutsideClick>
  )
}

export default HeaderNotifications
