import { delay, takeLatest, takeEvery, put } from 'redux-saga/effects'
import { select, call } from 'typed-redux-saga'

import { encryptCvv } from 'components/CreditCardForm/tokenizers/payrails'

import { TwoFactorVerificationDto } from 'types/dtos'
import { ResponseError } from 'types/api'

import { getBrowserAttributes } from '_libs/utils/threeDS2'

import { transformCheckoutDto } from 'data/transformers/checkout'
import { transformPaymentAuthAction } from 'data/transformers/payment'
import { transformCheckoutConfigurationDto } from 'data/transformers/checkout-configuration'

import { DebitStatus } from 'constants/payment'

import * as api from 'data/api'
import { ResponseCode } from 'data/api/response-codes'

import { incrementCheckoutLoad } from 'pages/SingleCheckout/utils/observability'

import { PAYMENT_POLL_DELAY, MAX_PAYMENT_POLL_ATTEMPT_COUNT } from './constants'
import * as checkoutSelectors from './selectors'
import * as paymentSelectors from './payment/selectors'
import * as statelessActions from './actions'
import * as transformers from './transformers'
import { actions } from './slice'
import { actions as paymentActions } from './payment'

export function* getCheckoutData({
  payload: { transactionId },
}: ReturnType<typeof actions.getCheckoutDataRequest>) {
  if (!transactionId) return

  const checkoutResponse = yield* call(api.getCheckoutData, transactionId)

  if ('errors' in checkoutResponse) {
    yield put(actions.getCheckoutDataFailure())

    return
  }

  const checkout = transformCheckoutDto(checkoutResponse.checkout)

  yield put(actions.getCheckoutDataSuccess({ checkout }))
}

export function* updateCheckoutData({
  payload: { transactionId, updatedCheckoutData },
}: ReturnType<typeof actions.updateCheckoutDataRequest>) {
  if (!transactionId) return

  const checkoutResponse = yield* call(
    api.updateCheckoutData,
    transactionId,
    updatedCheckoutData || null,
  )

  if ('errors' in checkoutResponse) {
    if (!updatedCheckoutData) {
      incrementCheckoutLoad({ type: 'error', checkoutType: 'old' })
    }

    yield put(
      checkoutResponse.code === ResponseCode.PaymentAlreadyProcessed
        ? statelessActions.getEscrowPaymentDataRequest({ transactionId })
        : actions.updateCheckoutDataFailure(),
    )

    return
  }

  // Check if it's initial load from backend. updatedCheckoutData is undefined on initial load.
  if (!updatedCheckoutData) {
    incrementCheckoutLoad({ type: 'success', checkoutType: 'old' })
  }

  const checkout = transformCheckoutDto(checkoutResponse.checkout)

  yield put(actions.updateCheckoutDataSuccess({ checkout }))
}

export function* handleTwoFactorVerificationError(
  twoFactorVerificationDto?: TwoFactorVerificationDto | null,
) {
  if (!twoFactorVerificationDto) return

  const twoFactorVerificationPayload =
    transformers.transformTwoFactorVerification(twoFactorVerificationDto)

  yield put(actions.setTwoFactorVerification(twoFactorVerificationPayload))
}

export function* handlePaymentResponseError(response: ResponseError) {
  if (!response) return

  switch (response.code) {
    case ResponseCode.User2FAConfirmation:
      yield* call(handleTwoFactorVerificationError, response.payload)
      break
    case ResponseCode.CreditCardExpired:
      yield put(paymentActions.setIsCardExpired({ isExpired: true }))
      break
    case ResponseCode.CheckoutChecksumMismatchError:
      yield put(actions.setHasChecksumError({ hasChecksumError: true }))
      break
    case ResponseCode.ShippingDiscountAlreadyUsed:
      yield put(actions.setHasShippingDiscountError({ hasShippingDiscountError: true }))
      break
    default:
      yield put(actions.setPaymentError({ error: { message: response.errors[0]?.value } }))
      break
  }
}

export function* cleanupAfterPaymentFailure() {
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: false }))
  yield put(actions.clearPaymentAuthAction())
  yield put(actions.clearBlikAuth())
  yield put(actions.setEncryptedCvv({ encryptedCvv: null }))

  const transactionId = yield* select(checkoutSelectors.getTransactionId)

  if (!transactionId) return

  yield put(actions.updateCheckoutDataRequest({ transactionId }))
}

export function* fetchConfiguration() {
  const response = yield* call(api.getCheckoutConfiguration)

  if ('errors' in response) {
    yield put(actions.fetchConfigurationFailure())
  } else {
    yield put(
      actions.fetchConfigurationSuccess({
        configuration: transformCheckoutConfigurationDto(response.checkout_configuration),
      }),
    )
  }
}

export function* handlePaymentInitiationResponse({
  payload: { response },
}: ReturnType<typeof statelessActions.handlePaymentInitiationResponse>) {
  if ('errors' in response) {
    yield call(handlePaymentResponseError, response)
    yield call(cleanupAfterPaymentFailure)

    return
  }

  const {
    debit_status,
    pay_in_redirect_url,
    authentication_action,
    user_msg_thread_id,
    payrails_init_data,
  } = response

  if (debit_status === DebitStatus.Failed && !payrails_init_data) {
    yield put(actions.setPaymentError({ error: { message: '' } }))
    yield call(cleanupAfterPaymentFailure)

    return
  }

  if (payrails_init_data) {
    yield put(actions.setPayrailsInitData({ payrailsInitData: payrails_init_data }))
    yield put(actions.setIsCvvHandlingFlowEnabled({ isCvvHandlingFlowEnabled: true }))
    yield call(cleanupAfterPaymentFailure)

    return
  }

  if (authentication_action) {
    yield put(
      actions.setPaymentAuthAction({
        paymentAuthAction: transformPaymentAuthAction(authentication_action),
      }),
    )

    return
  }

  yield put(actions.clearPaymentAuthAction())
  yield put(
    actions.setPayment({
      status: debit_status,
      conversationId: user_msg_thread_id,
      payInRedirectUrl: pay_in_redirect_url,
      authenticationAction:
        authentication_action && transformPaymentAuthAction(authentication_action),
    }),
  )
}

export function* initiatePayment() {
  yield put(actions.removeFieldsErrors())
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: true }))

  const blikCode = yield* select(checkoutSelectors.getBlikPaymentCode)
  const transactionId = yield* select(checkoutSelectors.getTransactionId)
  const transactionChecksum = yield* select(checkoutSelectors.getTransactionChecksum)
  const isCardSingleUseOverriden = yield* select(paymentSelectors.getIsCardSingleUseOverriden)
  const encryptedCvv = yield* select(checkoutSelectors.getEncryptedCvv)

  if (!transactionId) return

  const response = yield* call(api.initiateEscrowPayment, transactionId, {
    transactionChecksum,
    browserAttributes: getBrowserAttributes(),
    ...(!!blikCode && { blikCode }),
    ...(isCardSingleUseOverriden && { isSingleUse: false }),
    ...(typeof encryptedCvv === 'string' && { encryptedCvv }),
  })

  yield put(statelessActions.handlePaymentInitiationResponse({ response }))
}

export function* initiateRetryPaymentAfterSubmitCvv({
  payload,
}: ReturnType<typeof statelessActions.initiateRetryPaymentAfterSubmitCvv>) {
  if (payload.cvv.length === 0) {
    yield put(actions.clearIsCvvHandlingFlowEnabled())
    yield put(actions.setEncryptedCvv({ encryptedCvv: '' }))
    yield put(statelessActions.initiateEscrowPaymentRequest())
  }
  const payrailsInitData = yield* select(checkoutSelectors.getPayrailsInitData)

  if (!payrailsInitData) {
    yield put(actions.clearIsCvvHandlingFlowEnabled())
    yield put(actions.setCvvHandlingError({ error: { message: '' } }))

    return
  }

  const { encryptedCvv } = yield* call(encryptCvv, payload.cvv, payrailsInitData)

  if (!encryptedCvv) {
    yield put(actions.clearIsCvvHandlingFlowEnabled())
    yield put(actions.setCvvHandlingError({ error: { message: '' } }))

    return
  }

  yield put(actions.clearIsCvvHandlingFlowEnabled())
  yield put(actions.setEncryptedCvv({ encryptedCvv }))
  yield put(statelessActions.initiateEscrowPaymentRequest())
}

export function* getPaymentData({
  payload,
}: ReturnType<typeof statelessActions.getEscrowPaymentDataRequest>) {
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: true }))

  const response = yield* call(api.getEscrowPayment, payload.transactionId)

  yield put(statelessActions.handlePaymentInitiationResponse({ response }))
}

export function* updatePaymentData({
  payload,
}: ReturnType<typeof statelessActions.updateEscrowPaymentDataRequest>) {
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: true }))

  const transactionId = yield* select(checkoutSelectors.getTransactionId)

  if (!transactionId) return

  const response = yield* call(api.updateEscrowPaymentData, transactionId, {
    paymentData: {
      data: payload.paymentData,
      details: payload.paymentDetails,
    },
  })

  yield put(statelessActions.handlePaymentInitiationResponse({ response }))
}

export function* handlePendingPayment({
  payload: { transactionId, pollAttemptCount },
}: ReturnType<typeof statelessActions.handlePendingPayment>) {
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: true }))
  yield delay(PAYMENT_POLL_DELAY)

  const response = yield* call(api.getEscrowPayment, transactionId)

  if ('errors' in response) {
    yield put(actions.setPaymentError({ error: { message: '' } }))
    yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: false }))

    return
  }

  const { debit_status, user_msg_thread_id } = response
  const isPending = debit_status === DebitStatus.Pending
  const shouldPoll = isPending && pollAttemptCount < MAX_PAYMENT_POLL_ATTEMPT_COUNT

  if (shouldPoll) {
    yield put(
      statelessActions.handlePendingPayment({
        transactionId,
        pollAttemptCount: pollAttemptCount + 1,
      }),
    )

    return
  }

  if (isPending) {
    yield put(actions.setIsPaymentProcessingTakesTooLong({ isPaymentProcessingTakesTooLong: true }))
  }

  yield put(
    actions.setPayment({
      status: debit_status,
      conversationId: user_msg_thread_id,
      payInRedirectUrl: null,
    }),
  )
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: false }))
}

export function* handlePaymentRedirectResult({
  payload: { transactionId },
}: ReturnType<typeof statelessActions.handlePaymentRedirectResult>) {
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: true }))
  yield put(actions.getCheckoutDataRequest({ transactionId }))

  const response = yield* call(api.getEscrowPayment, transactionId)

  if ('errors' in response) {
    yield put(actions.setPaymentError({ error: { message: '' } }))
    yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: false }))

    return
  }

  const { debit_status, user_msg_thread_id } = response

  if (debit_status === DebitStatus.Pending) {
    yield put(
      statelessActions.handlePendingPayment({
        transactionId,
        pollAttemptCount: 0,
      }),
    )

    return
  }

  if (debit_status === DebitStatus.Failed) {
    yield put(
      actions.setIsPaymentFailedAfterRedirect({
        isPaymentFailedAfterRedirect: true,
      }),
    )
  }

  yield put(
    actions.setPayment({
      status: debit_status,
      conversationId: user_msg_thread_id,
      payInRedirectUrl: null,
    }),
  )
  yield put(actions.setIsPaymentProcessing({ isPaymentProcessing: false }))
}

export default function* saga() {
  yield takeLatest(statelessActions.fetchConfigurationRequest, fetchConfiguration)
  yield takeLatest(
    [statelessActions.initiateEscrowPaymentRequest, actions.confirmBlikAuth],
    initiatePayment,
  )
  yield takeLatest(statelessActions.getEscrowPaymentDataRequest, getPaymentData)
  yield takeLatest(statelessActions.updateEscrowPaymentDataRequest, updatePaymentData)
  yield takeEvery(actions.getCheckoutDataRequest, getCheckoutData)
  yield takeEvery(actions.updateCheckoutDataRequest, updateCheckoutData)
  yield takeLatest(
    statelessActions.handlePaymentInitiationResponse,
    handlePaymentInitiationResponse,
  )
  yield takeLatest(statelessActions.handlePendingPayment, handlePendingPayment)
  yield takeLatest(statelessActions.handlePaymentRedirectResult, handlePaymentRedirectResult)
  yield takeLatest(
    statelessActions.initiateRetryPaymentAfterSubmitCvv,
    initiateRetryPaymentAfterSubmitCvv,
  )
}
