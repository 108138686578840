'use client'

import { useState, MouseEvent } from 'react'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { getAppLinks } from 'data/api'
import { clickEvent } from '_libs/common/event-tracker/events'
import { openBrowsingContext } from '_libs/utils/window'
import { useTracking } from '@marketplace-web/shared/event-tracker'

const useLeavingVintedModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [url, setUrl] = useState<string | null>(null)

  const { track } = useTracking()

  const closeModal = () => setIsModalVisible(false)

  const ensureProtocol = (originalUrl: string): string => {
    const hasProtocol = /^[a-zA-Z]+:\/\//.test(originalUrl)

    return hasProtocol ? originalUrl : `https://${url}`
  }

  const navigateToExternalLink = (externalUrl: string) => {
    const browsingContext = openBrowsingContext(ensureProtocol(externalUrl), '_blank')

    if (browsingContext) browsingContext.opener = null
  }

  const handleOnContinue = () => {
    if (!url) return

    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.ProceedFromLeavingModal,
        targetDetails: url,
      }),
    )

    closeModal()
    navigateToExternalLink(url)
  }

  const handleCancel = () => {
    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.CancelLeavingModal,
        targetDetails: url,
      }),
    )

    closeModal()
  }

  const handleLinkClick = async (event: MouseEvent, link: string) => {
    event.preventDefault()

    const browsingContext = openBrowsingContext()
    const plainUrl = link.replace(/^\/\//, 'http://')

    const response = await getAppLinks(plainUrl)

    if ('errors' in response) {
      if (browsingContext) browsingContext.location.href = link

      return
    }

    const isInternal = response.app_links[plainUrl]?.web

    if (!isInternal) {
      browsingContext?.close()
      track(
        clickEvent({
          screen: Screen.MessageReply,
          target: ClickableElement.ShowLeavingModal,
          targetDetails: link,
        }),
      )

      setIsModalVisible(true)
      setUrl(link)

      return
    }

    if (browsingContext) browsingContext.location.href = link
  }

  return {
    isLeavingVintedModalVisible: isModalVisible,
    url,
    handleLinkClick,
    handleOnContinue,
    handleCancel,
  }
}

export default useLeavingVintedModal
