import { MultiVariantsOnboardingModalDto } from 'types/dtos'
import { MultiVariantsOnboardingModalModel } from 'types/models'

export const transformMultiVariantsOnboardingModal = ({
  name,
  actions,
  steps,
}: MultiVariantsOnboardingModalDto): MultiVariantsOnboardingModalModel => ({
  name,
  actions,
  steps: steps.map(step => ({
    name: step.name,
    title: step.title,
    body: step.body,
    imageUrl: step.image_url,
    video: step.video && {
      vertical: {
        formats: step.video.vertical.formats,
        imageUrl: step.video.vertical.image_url,
      },
    },
    videoDark: step.video_dark && {
      vertical: {
        formats: step.video_dark.vertical.formats,
        imageUrl: step.video_dark.vertical.image_url,
      },
    },
  })),
})
