import { UserBundleItemsResp, UserBundleItemsRespDeprecated } from 'types/api'
import { UserBundleItemsModel } from 'types/models'

import {
  transformBundleItemDtosToProductItems,
  transformItemDtosToProductItems,
} from './product-item'
import { transformPaginationDto } from './pagination'

export const transformUserBundleItemsDeprecated = (
  response: UserBundleItemsRespDeprecated,
): UserBundleItemsModel => ({
  items: transformItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})

export const transformUserBundleItems = (response: UserBundleItemsResp): UserBundleItemsModel => ({
  items: transformBundleItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})
