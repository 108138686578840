import { PromotionalListingBannerInCatalogFeedDto } from 'types/dtos'
import { PromotionalListingBannerInCatalogFeedModel } from 'types/models'

export const transformPromotionalListingBannerInCatalogFeed = ({
  name,
  image_url,
  dark_image_url,
  title,
  body,
  ab_test_name,
  ab_test_group,
  actions,
}: PromotionalListingBannerInCatalogFeedDto): PromotionalListingBannerInCatalogFeedModel => ({
  name,
  imageUrl: image_url,
  darkImageUrl: dark_image_url,
  title,
  body,
  abTestName: ab_test_name,
  abTestGroup: ab_test_group,
  actions,
})
