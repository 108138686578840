import { isEmpty } from 'lodash'

import {
  CatalogItemDto,
  FeedItemDto,
  FavouriteItemDto,
  HomepageItemDto,
  ItemDto,
  UserDto,
  ClosetPromotionItemDto,
  ItemPhotoDto,
  BundleItemDto,
} from 'types/dtos'
import {
  HomepageBlockItemModel,
  ItemPhotoModel,
  ProductItemModel,
  ProductItemUserModel,
} from 'types/models'
import { getUserThumbnail } from 'data/utils/user'
import { ItemThumbnailSize } from 'constants/images'
import { ContentSource } from 'constants/tracking/content-sources'
import { getItemThumbnail, getItemPhotosThumbnails } from 'data/utils/item'

import { transformPhotoDto } from './photo'
import { transformItemPhotoDtos } from './item'
import { transformItemAlertDto } from './item-alert'
import { transformCurrencyAmountDto, transformCurrencyAmountDtoOrString } from './currency-amount'
import { transformIconBadges } from './icon-badges'
import { transformItemBoxDto } from './item-box'

export const getDominantColor = (photos: Array<ItemPhotoDto>) => {
  if (isEmpty(photos)) return null

  const photo = photos.find(({ is_main }) => is_main === true)

  if (!photo) return null

  return photo.dominant_color_opaque
}

export const transformPartialUserDtoToProductItemUser = (
  user: Partial<Pick<UserDto, 'id' | 'login' | 'photo' | 'profile_url' | 'business'>>,
): ProductItemUserModel => ({
  id: user.id,
  login: user.login,
  photo: user.photo ? transformPhotoDto(user.photo) : null,
  thumbnailUrl: getUserThumbnail(user.photo),
  profileUrl: user.profile_url,
  isBusiness: user.business,
})

export const transformItemDtoToProductItem = (item: ItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  photos: transformItemPhotoDtos(item.photos),
  viewCount: item.view_count,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_dto?.title || '',
  sizeTitle: item.size_title || '',
  user: transformPartialUserDtoToProductItemUser(item.user),
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  price: transformCurrencyAmountDto(item.price),
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  thumbnailUrl: getItemThumbnail(item.photos, ItemThumbnailSize.Large),
  dominantColor: getDominantColor(item.photos),
  badge: item.badge,
  itemAlertType: item.item_alert_type,
  itemAlert: item.item_alert && transformItemAlertDto(item.item_alert),
  itemClosingAction: item.item_closing_action,
  isPromoted: item.promoted,
  isDraft: item.is_draft,
  isReserved: item.is_reserved,
  isHidden: item.is_hidden,
  isClosed: item.is_closed,
  isFavourite: item.is_favourite,
  isProcessing: item.is_processing,
  canPushUp: item.can_push_up,
  canEdit: item.can_edit,
  areStatsVisible: item.stats_visible,
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformBundleItemDtoToProductItem = (item: BundleItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: '',
  photos: transformItemPhotoDtos(item.photos),
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title || '',
  sizeTitle: item.size_title || '',
  user: transformPartialUserDtoToProductItemUser({ business: item.is_owner_business }),
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  price: transformCurrencyAmountDto(item.price),
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  thumbnailUrl: getItemThumbnail(item.photos, ItemThumbnailSize.Large),
  dominantColor: getDominantColor(item.photos),
  badge: item.badge,
  isFavourite: item.is_favourite,
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformCatalogItemDtoToProductItem = (item: CatalogItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title,
  sizeTitle: item.size_title,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.discount.currency_code)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.price.currency_code),
  badge: item.badge,
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.service_fee.currency_code)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.total_item_price.currency_code)
    : undefined,
  isPromoted: item.promoted,
  isFavourite: item.is_favourite,
  thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
  dominantColor: item.photo && getDominantColor([item.photo]),
  user: transformPartialUserDtoToProductItemUser(item.user),
  photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
  iconBadges: transformIconBadges(item.icon_badges),
  viewCount: item.view_count,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformFeedItemDtoToProductItem = (item: FeedItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title,
  sizeTitle: item.size_title,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.currency),
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
    : undefined,
  badge: item.badge,
  isPromoted: item.promoted,
  isFavourite: item.is_favourite,
  thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
  dominantColor: item.photo && getDominantColor([item.photo]),
  user: transformPartialUserDtoToProductItemUser(item.user),
  photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformFavouriteItemDtoToProductItem = (
  item: FavouriteItemDto,
  contentSource: ContentSource,
): HomepageBlockItemModel => ({
  id: item.id,
  url: item.url,
  title: item.title,
  photos: item.photo ? transformItemPhotoDtos([item.photo]) : [],
  brandTitle: item.brand_title || '',
  sizeTitle: item.size_title || '',
  favouriteCount: item.favourite_count,
  user: transformPartialUserDtoToProductItemUser(item.user),
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: item.photo ? getItemThumbnail([item.photo], ItemThumbnailSize.Large) : null,
  dominantColor: item.photo ? getDominantColor([item.photo]) : null,
  badge: item.badge,
  isPromoted: item.promoted,
  isFavourite: item.is_favourite,
  iconBadges: transformIconBadges(item.icon_badges),
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
  isReserved: item.is_reserved,
  isHidden: item.is_hidden,
  isClosed: item.is_closed,
  itemClosingAction: item.item_closing_action,
  contentSource,
})

// TODO: convert to HomepageItemModel and move to homepage-blocks transformer file
export const transformHomepageItemDtoToProductItem = (item: HomepageItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  user: { id: item.user_id, isBusiness: item.business_user },
  url: item.url,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title,
  sizeTitle: item.size_title,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.currency),
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
    : undefined,
  badge: item.badge,
  itemClosingAction: item.item_closing_action,
  isPromoted: item.promoted,
  isFavourite: item.is_favourite,
  isHidden: item.is_hidden,
  isReserved: item.is_reserved,
  isClosed: item.is_closed,
  thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
  dominantColor: item.photo && getDominantColor([item.photo]),
  photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
  iconBadges: transformIconBadges(item.icon_badges),
  homepageSignals: item.homepage_signals,
  contentSource: item.content_source,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

const transformClosetPromotionPhotos = (item: ClosetPromotionItemDto): Array<ItemPhotoModel> => {
  if (item.photos && item.photos.length > 0) {
    return transformItemPhotoDtos(item.photos)
  }

  if (!item.photo) return []

  return transformItemPhotoDtos([item.photo])
}

const tranaformClosetPromotionItemsThumbnails = (
  item: ClosetPromotionItemDto,
): Array<string> | undefined => {
  if (item.photos && item.photos.length > 0) {
    return getItemPhotosThumbnails(item.photos, ItemThumbnailSize.Large)
  }

  if (!item.photo) return undefined

  return getItemPhotosThumbnails([item.photo], ItemThumbnailSize.Large)
}

const tranaformClosetPromotionItemThumbnail = (item: ClosetPromotionItemDto): string | null => {
  if (item.photos && item.photos.length > 0) {
    return getItemThumbnail(item.photos, ItemThumbnailSize.Large)
  }

  if (!item.photo) return null

  return getItemThumbnail([item.photo], ItemThumbnailSize.Large)
}

const transformClosetPromotionItemDominantColor = (item: ClosetPromotionItemDto): string | null => {
  if (item.photos && item.photos.length > 0) {
    return getDominantColor(item.photos)
  }

  if (!item.photo) return null

  return getDominantColor([item.photo])
}

export const transformClosetPromotionItemDtoToProductItem = (
  item: ClosetPromotionItemDto,
): ProductItemModel => ({
  id: item.id,
  title: item.title,
  user: { id: item.user_id, isBusiness: item.business_user },
  url: item.url,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title,
  sizeTitle: item.size_title,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.currency),
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
    : undefined,
  badge: item.badge,
  isFavourite: item.is_favourite,
  thumbnailUrl: tranaformClosetPromotionItemThumbnail(item),
  thumbnailUrls: tranaformClosetPromotionItemsThumbnails(item),
  dominantColor: transformClosetPromotionItemDominantColor(item),
  photos: transformClosetPromotionPhotos(item),
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformItemDtosToProductItems = (items: Array<ItemDto>): Array<ProductItemModel> =>
  items.map(item => transformItemDtoToProductItem(item))

export const transformBundleItemDtosToProductItems = (
  items: Array<BundleItemDto>,
): Array<ProductItemModel> => items.map(item => transformBundleItemDtoToProductItem(item))

export const transformCatalogItemDtosToProductItems = (
  items: Array<CatalogItemDto>,
): Array<ProductItemModel> => items.map(transformCatalogItemDtoToProductItem)

export const transformFeedItemDtosToProductItems = (
  items: Array<FeedItemDto>,
): Array<ProductItemModel> => items.map(transformFeedItemDtoToProductItem)

export const transformHomepageItemDtosToProductItems = (
  items: Array<HomepageItemDto>,
): Array<ProductItemModel> => items.map(transformHomepageItemDtoToProductItem)

export const transformClosetPromotionItemDtosToProductItems = (
  items: Array<ClosetPromotionItemDto>,
): Array<ProductItemModel> => items.map(transformClosetPromotionItemDtoToProductItem)
