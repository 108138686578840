import { BadgeTheme } from '@vinted/web-ui/dist/types/lib/Badge/Badge'

import {
  ColorDto,
  ItemPriceSuggestionsDto,
  ItemUploadDto,
  AssignedPhotoDto,
  ItemAttributeDto,
  ItemUploadConditionDto,
  UploadFormModelDto,
  CatalogBadgeDto,
} from 'types/dtos'
import { BookDetailsModel, ItemPriceSuggestionsModel } from 'types/models'
import { BookDetailsResp, GetSimilarSoldItemsResp, ItemSuggestionsResponse } from 'types/api'

import { transformBrandDtos } from 'data/transformers/brand'
import { transformColorDtos } from 'data/transformers/color'
import { transformSimilarSoldItemDtoToModel } from 'data/transformers/similar-sold-item'
import { transformSizeDtos } from 'data/transformers/size'
import { MODEL_OTHER_ID } from 'pages/ItemUpload/ModelSelect/constants'
import { Attributes } from 'pages/ItemUpload/types/context'

import { ConditionSelectType, ColorSelectType, DynamicAttribute } from './types'

export const transformConditions = ({
  conditions,
}: {
  conditions: Array<ItemUploadConditionDto>
}): Array<ConditionSelectType> =>
  conditions.map(({ id, title, explanation }) => ({
    id,
    title,
    body: explanation,
  }))

export const transformColors = (colorsDto: Array<ColorDto>): Array<ColorSelectType> =>
  colorsDto.map(({ id, title, code }) => ({
    id,
    title,
    code,
  }))

export const transformDynamicAttributes = (
  attributes: Array<ItemAttributeDto> | undefined,
): Array<DynamicAttribute> =>
  attributes?.map(({ code, ids }) => ({ field: code, value: ids })) || []

export const transformCatalogBadge = (
  badge: CatalogBadgeDto | null | undefined,
): CatalogBadgeDto | null => {
  if (!badge) return null

  const supportedThemes: Array<BadgeTheme> = [
    'primary',
    'muted',
    'success',
    'warning',
    'expose',
    'amplified',
  ]

  if (!supportedThemes.includes(badge.theme)) return null

  return badge
}

export const transformPriceSuggestionsDto = ({
  price_suggestion,
  similar_sold_items_present,
}: ItemPriceSuggestionsDto): ItemPriceSuggestionsModel => ({
  min: price_suggestion ? Number(price_suggestion.minimum.amount) : null,
  max: price_suggestion ? Number(price_suggestion.maximum.amount) : null,
  currency: price_suggestion?.currency || null,
  hasSimilarItems: similar_sold_items_present,
})

export const transformBookDetails = ({ book_details }: BookDetailsResp): BookDetailsModel => ({
  author: book_details.author,
  title: book_details.title,
})

const transformUploadFormModel = (modelData: Omit<UploadFormModelDto, 'id' | 'children'>) => ({
  model: {
    ...(modelData.metadata.collection_id === MODEL_OTHER_ID && { suggestion: modelData.name }),
    metadata: modelData.metadata,
  },
})

export const transformItemAttributesToItemUploadDto = (
  assignedPhotos: Array<AssignedPhotoDto>,
  dynamicAttributes: Array<DynamicAttribute>,
  currency: string,
  {
    id,
    manufacturer,
    manufacturerLabel,
    brandId,
    brandTitle,
    catalogId,
    sizeId,
    statusId,
    colorIds,
    title,
    description,
    isUnisex,
    measurementLength,
    measurementWidth,
    isbn,
    price,
    packageSizeId,
    domesticShipment,
    internationalShipment,
    videoGameRatingId,
    modelData,
  }: Attributes,
  tempUuid = '',
): ItemUploadDto => ({
  id,
  currency,
  temp_uuid: tempUuid,
  title,
  description,
  brand_id: brandId,
  brand: brandTitle,
  size_id: sizeId,
  catalog_id: catalogId,
  isbn,
  is_unisex: isUnisex,
  status_id: statusId,
  video_game_rating_id: videoGameRatingId,
  price,
  package_size_id: packageSizeId,
  shipment_prices: {
    domestic: domesticShipment,
    international: internationalShipment,
  },
  color_ids: colorIds,
  assigned_photos: assignedPhotos,
  measurement_length: measurementLength,
  measurement_width: measurementWidth,
  item_attributes: dynamicAttributes?.map(attr => ({
    code: attr.field,
    ids: attr.value,
  })),
  manufacturer,
  manufacturer_labelling: manufacturerLabel,
  ...(modelData && transformUploadFormModel(modelData)),
})

export const transformItemSuggestions = ({
  brands,
  colors,
  sizes,
  catalogs,
}: ItemSuggestionsResponse) => ({
  catalogIds: catalogs,
  brands: transformBrandDtos(brands),
  colors: transformColorDtos(colors),
  sizes: transformSizeDtos(sizes),
})

export const transformSimilarSoldItems = ({ items }: GetSimilarSoldItemsResp) =>
  items.map(transformSimilarSoldItemDtoToModel)
