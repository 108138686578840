'use client'

import { Cell, Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { ItemPageClosetTitlePluginModel } from './types'

type Props = {
  data: ItemPageClosetTitlePluginModel | undefined
}

const ItemPageClosetTitlePlugin = ({ data }: Props) => {
  const translate = useTranslate('item')

  if (!data) return null
  if (data.itemCount <= 1) return null

  return (
    <>
      <Spacer size={Spacer.Size.XLarge} />
      <Cell
        title={
          <Text type={Text.Type.Title} as="h2">
            {translate('owner_items_title', { count: data.itemCount })}
          </Text>
        }
        styling={Cell.Styling.Tight}
        testId="item-page-other_user_items_header-plugin"
      />
    </>
  )
}

export default ItemPageClosetTitlePlugin
