'use client'

import { ReactNode, useState } from 'react'
import { Dialog, Spacer } from '@vinted/web-ui'

import ScrollableArea from 'components/ScrollableArea'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent, itemAttributesClickEvent } from '_libs/common/event-tracker/events'

type Props = {
  title: string
  body: ReactNode
  trackingTarget?: ClickableElement
  code: string
  itemId: number
}

const MAX_SCROLLABLE_CONTENT_HEIGHT = '50vh'

const MoreInfoModal = ({ title, body, trackingTarget, code, itemId }: Props) => {
  const translate = useTranslate('item.details')
  const { track } = useTracking()

  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)

    track(
      itemAttributesClickEvent({
        itemId: itemId.toString(),
        attributeCode: code,
      }),
    )

    if (trackingTarget) {
      track(clickEvent({ target: trackingTarget, screen: Screen.Item }))
    }
  }

  const handleCloseModal = () => setOpenModal(false)

  if (!body) return null

  return (
    <>
      <button
        type="button"
        className="details-list__item-value--show-more-button"
        onClick={handleOpenModal}
        aria-label={translate('a11y.show_more_action')}
        data-testid={`show-more-${code}`}
      >
        {translate('actions.show_more')}
      </button>
      <Dialog
        testId={`more-info-modal-${code}`}
        title={title}
        body={
          <>
            <Spacer />
            <ScrollableArea className="u-text-wrap" maxHeight={MAX_SCROLLABLE_CONTENT_HEIGHT}>
              {body}
            </ScrollableArea>
          </>
        }
        show={openModal}
        defaultCallback={handleCloseModal}
        enableCloseButton
        closeOnOverlay
        actions={[
          {
            text: translate('actions.got_it'),
            style: Dialog.ActionStyling.Filled,
            callback: handleCloseModal,
            testId: `got-it-${code}`,
          },
        ]}
      />
    </>
  )
}

export default MoreInfoModal
