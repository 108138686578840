'use client'

import { useContext, useEffect } from 'react'
import Script from 'next/script'

import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

import { ConsentGroup } from 'constants/consent'

import AdsContext from '../../../../containers/AdsProvider/AdsContext'

import {
  initGoogletag,
  setupGoogletagServices,
  setAdsSegments,
  setNonPersonalizedAds,
} from './utils'

const Google = () => {
  const { segments: adsSegments } = useContext(AdsContext)
  const hasConsentedTargeting = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  useEffect(() => {
    initGoogletag()
    setupGoogletagServices()
  }, [])

  useEffect(() => {
    setNonPersonalizedAds(!hasConsentedTargeting)
  }, [hasConsentedTargeting])

  useEffect(() => {
    setAdsSegments(adsSegments)
  }, [adsSegments])

  return (
    <Script
      id="googletag-script"
      data-testid="googletag-script"
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      strategy="lazyOnload"
    />
  )
}

export default Google
