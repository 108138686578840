'use client'

import { useLayoutEffect, useRef, useState } from 'react'
import { Icon, Text, Spacer } from '@vinted/web-ui'
import { Globe12 } from '@vinted/monochrome-icons'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'
import { Translation } from 'constants/translation'
import { getFeedback } from 'data/api'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { clickEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import TranslationControl, { Status } from 'components/TranslationControl'

type Props = {
  originalFeedback: string
  localization: Translation
  feedbackId: string
  itemId: number
}

const FeedbackWithTranslation = ({ originalFeedback, localization, feedbackId, itemId }: Props) => {
  const [isTranslated, setIsTranslated] = useState(false)
  const [translatedFeedback, setTranslatedFeedback] = useState<string | null>(null)
  const [showMore, setShowMore] = useState(false)
  // Needed to fix the flickering issue on initial render
  const [isHidden, setIsHidden] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)

  const translate = useTranslate()
  const { track } = useTracking()
  const contentRef = useRef<HTMLDivElement>(null)

  const activeContent = (isTranslated && translatedFeedback) || originalFeedback

  useLayoutEffect(() => {
    const element = contentRef.current

    if (element && element.scrollHeight > element.offsetHeight) setShowMore(true)
    else setShowMore(false)

    setIsHidden(false)
  }, [contentRef, activeContent])

  const showOriginal = () => setIsTranslated(false)

  const translateFeedback = async () => {
    if (translatedFeedback) {
      setIsTranslated(true)

      return
    }

    const response = await getFeedback({ feedbackId: Number(feedbackId), localize: true })

    if ('errors' in response) return
    if (!response?.user_feedback.feedback) return

    setTranslatedFeedback(response.user_feedback.feedback)
    setIsTranslated(true)
  }

  const handleTranslate = (translated: boolean) => {
    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.TranslateFeedback,
        targetDetails: JSON.stringify({
          item_id: itemId,
          feedback_id: feedbackId,
        }),
      }),
    )

    if (translated) showOriginal()
    else translateFeedback()
  }

  const renderTranslateButton = (status: Status) => {
    const messageTheme = status === Status.Translating ? undefined : 'primary'

    return (
      <div className="u-flexbox u-align-items-center">
        <Icon name={Globe12} color={Icon.Color.Primary} aria={{ 'aria-hidden': 'true' }} />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Regular} />
        <div>
          {status === Status.Translated && (
            <>
              <Text
                as="h4"
                text={translate('translation.status.translated_prefix')}
                type={Text.Type.Caption}
              />
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
              <Text text="‧" type={Text.Type.Caption} as="div" />
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            </>
          )}
          <Text
            as="h4"
            text={translate(`translation.status.${status}`)}
            theme={messageTheme}
            type={Text.Type.Caption}
          />
        </div>
      </div>
    )
  }

  const handleShowMore = () => {
    setShowMore(false)
    setIsExpanded(true)

    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.ReadMoreFeedback,
        targetDetails: JSON.stringify({ item_id: itemId, feedback_id: feedbackId }),
      }),
    )
  }

  return (
    <>
      <div className="u-position-relative">
        <div
          className={classNames({
            'feedback--collapsed': !isExpanded,
            'feedback--truncated': showMore,
            'feedback--hidden': isHidden,
          })}
        >
          <Text
            type={Text.Type.Subtitle}
            text={
              <p
                className={classNames({
                  'feedback--collapsed': !isExpanded,
                })}
                ref={contentRef}
              >
                {activeContent}
              </p>
            }
            as="div"
          />
        </div>
        {showMore && (
          <div className="feedback-show-more">
            <button type="button" onClick={handleShowMore}>
              <Text
                clickable
                text={translate('item.seller_feedbacks.actions.more')}
                type={Text.Type.Subtitle}
                as="span"
              />
            </button>
          </div>
        )}
      </div>

      {localization !== Translation.None && (
        <>
          <Spacer size={Spacer.Size.Large} />
          <div data-testid="seller-feedback--translate-cta">
            <TranslationControl
              isTranslated={isTranslated}
              autotranslate={false}
              onClick={handleTranslate}
              isNavigating={false}
              clickable
              renderButton={renderTranslateButton}
            />
          </div>
        </>
      )}
    </>
  )
}

export default FeedbackWithTranslation
