'use client'

import { useState } from 'react'
import { Image } from '@vinted/web-ui'

import ImageCarousel from 'components/ImageCarousel'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

import { ItemPageGalleryPluginModel } from './types'
import FavouriteButton from './FavouriteButton'

const RENDER_PHOTOS_LIMIT = 5
const LAST_PHOTO_INDEX = RENDER_PHOTOS_LIMIT - 1

type Props = {
  data: ItemPageGalleryPluginModel
}

const ItemPageGalleryPlugin = ({ data }: Props) => {
  const { id, title, photos, ownerId, isFavourite, itemId, favouriteCount } = data

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(null)

  const breakpoint = useBreakpoint()

  const handleCarouselClose = () => setSelectedPhotoIndex(null)

  const handlePhotoClick = (index: number) => {
    setSelectedPhotoIndex(index)
  }

  const numberOfPhotos = photos.length
  const visiblePhotos = photos.slice(0, RENDER_PHOTOS_LIMIT)

  const hiddenPhotosCount = numberOfPhotos - RENDER_PHOTOS_LIMIT

  return (
    <>
      <section className="item-photos__container">
        <div className="item-photos">
          <FavouriteButton
            isFavourited={isFavourite}
            itemId={itemId}
            initialFavouriteCount={favouriteCount}
            itemUserId={ownerId}
          />
          {visiblePhotos.map((photo, index) => (
            <figure
              className={`item-description u-flexbox item-photo item-photo--${index + 1}`}
              key={photo.id}
            >
              <button
                type="button"
                className="item-thumbnail"
                data-photoid={photo.id}
                onClick={() => handlePhotoClick(index)}
              >
                <Image
                  src={photo.url}
                  scaling={Image.Scaling.Cover}
                  label={
                    !!hiddenPhotosCount && index === LAST_PHOTO_INDEX
                      ? `+ ${hiddenPhotosCount}`
                      : undefined
                  }
                  alt={numberOfPhotos === 1 ? `${title}` : `${title} ${index + 1}`}
                  color={photo.dominant_color_opaque}
                  testId={`item-photo-${index + 1}`}
                  styling={breakpoint.desktops ? Image.Styling.Rounded : undefined}
                />
              </button>
            </figure>
          ))}
        </div>
      </section>
      {selectedPhotoIndex !== null && (
        <ImageCarousel
          imageSources={photos.map(photo => photo.url)}
          initialImageIndex={selectedPhotoIndex}
          onClose={handleCarouselClose}
          itemId={id}
        />
      )}
    </>
  )
}

export default ItemPageGalleryPlugin
