import { ItemPageBreadcrumbsDto } from 'types/dtos'

import { ItemPageBreadcrumbsPluginModel } from './types'

export const getItemPageBreadcrumbsPluginModel = (
  data: ItemPageBreadcrumbsDto,
): ItemPageBreadcrumbsPluginModel => ({
  breadcrumbs: data.breadcrumbs.map(breadcrumb => ({
    title: breadcrumb.title,
    url: breadcrumb.url,
  })),
})
