'use client'

import { ReactNode } from 'react'

import { urlWithParams } from '_libs/utils/url'
import { AccessChannel } from 'constants/index'

import { FaqEntryType } from 'constants/faq-entry'

type Props = {
  accessChannel?: AccessChannel
  type: FaqEntryType
  render: (faqUrl: string, faqEntryId: number) => ReactNode
}

const FaqEntryUrl = ({ type, render, accessChannel }: Props) => {
  const hostUrl = `/help/${type}`

  const finalUrl = accessChannel
    ? urlWithParams(hostUrl, { access_channel: accessChannel })
    : hostUrl

  return <>{render(finalUrl, type)}</>
}

export default FaqEntryUrl
