'use client'

import { useState } from 'react'

import { navigateToPage } from '_libs/utils/window'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { AuthenticateProvider } from '@marketplace-web/shared/authentication'
import { GoogleTagManagerEvent } from 'constants/google'

import { authenticateFailEvent, authenticateSuccessEvent } from '_libs/common/event-tracker/events'

import { ResponseCode } from 'data/api/response-codes'
import { getCurrentUser } from 'data/api'
import { transformAuthenticateUserError } from 'data/transformers/authentication'
import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'

import { AppleButton as AuthAppleButton } from '@marketplace-web/domain/socials-authentication'

import { AuthExternalRegisterView } from '../../constants'
import useSuccessUrl from '../../hooks/useSuccessUrl/useSuccessUrl'
import useSocialLogin from '../../hooks/useSocialLogin/useSocialLogin'
import useAuthTracking from '../../hooks/useAuthTracking'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'

type Props = {
  setError: (error?: string) => void
}

const Apple = ({ setError }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate()

  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const { trackClickEvent } = useAuthTracking()

  const [isLoading, setIsLoading] = useState(false)
  const [appleResponse, setAppleResponse] = useState<AppleSignInAPI.SignInResponseI>()
  const successUrl = useSuccessUrl()
  const authenticateSocial = useSocialLogin()
  const { handleViewExternalRegister, handleViewTwoFactorLogin } = useAuthenticationContext()

  function handleClick() {
    trackClickEvent({ target: ClickableElement.LoginWithApple })
  }

  function trackError(message: string) {
    track(authenticateFailEvent({ type: 'apple', error: message }))
  }

  function setExternalRegisterData(idToken: string, user: AppleSignInAPI.SignInResponseI['user']) {
    handleViewExternalRegister({
      view: AuthExternalRegisterView.AppleRegister,
      data: {
        idToken,
        realName: user?.name && `${user.name.firstName} ${user.name.lastName}`,
        email: user?.email,
      },
    })
  }

  function handleLoginWithoutEmail(controlCode: string, email?: string) {
    handleViewExternalRegister({
      view: AuthExternalRegisterView.PasswordVerification,
      data: {
        idToken: controlCode,
        email,
      },
    })
  }

  async function handleSuccess(signInResponse: AppleSignInAPI.SignInResponseI) {
    setAppleResponse(signInResponse)
    setIsLoading(true)
    const {
      authorization: { id_token },
      user,
    } = signInResponse

    const response = await authenticateSocial(AuthenticateProvider.Apple, id_token)
    const is2FARequired =
      response.code === ResponseCode.Required2FA ||
      response.code === ResponseCode.VerifierSecondFactorRequired

    if ('errors' in response) {
      // TODO: handle ResponseCode.SessionFromTokenError
      if (
        response.code === ResponseCode.NotFound ||
        response.code === ResponseCode.ValidationError
      ) {
        setExternalRegisterData(id_token, user)
      } else if (
        response.code === ResponseCode.LoginWithoutEmail &&
        response.payload &&
        'control_code' in response.payload
      ) {
        handleLoginWithoutEmail(response.payload.control_code, user?.email)
      } else if (is2FARequired && response.payload) {
        handleViewTwoFactorLogin({
          ...transformAuthenticateUserError(response.payload),
          refUrl: successUrl,
        })

        return
      } else {
        setError(response.message)
        setIsLoading(false)
      }

      return
    }

    const getCurrentUserResp = await getCurrentUser()
    const userId = 'errors' in getCurrentUserResp ? undefined : getCurrentUserResp.user.id

    googleTagManagerTrack(GoogleTagManagerEvent.Login, {
      auth_type: 'apple',
      user_email: user?.email,
    })
    track(authenticateSuccessEvent({ type: 'apple', userId }))
    navigateToPage(successUrl)
  }

  useDataDomeCaptcha(() => {
    if (!appleResponse) return

    setError(undefined)
    handleSuccess(appleResponse)
  })

  function handleFailure() {
    trackError('User closed screen or did not provide permissions')
  }

  return (
    <AuthAppleButton
      text={translate('auth.select_type.actions.apple')}
      isLoading={isLoading}
      onClick={handleClick}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
    />
  )
}

export default Apple
