'use client'

import { createElement, JSXElementConstructor, ReactNode, useRef } from 'react'
import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'

import { AbTestsProvider } from '@marketplace-web/shared/ab-tests'
import { BreakpointProvider } from '@marketplace-web/shared/breakpoints'
import { CookieManagerProvider, createCookieManager } from '@marketplace-web/shared/cookies'
import { EnvsProvider } from '@marketplace-web/shared/environment'
import { TrackingProvider } from '@marketplace-web/shared/event-tracker'
import { FeatureSwitchesProvider } from '@marketplace-web/shared/feature-switches'
import { SessionProvider } from '@marketplace-web/shared/session'

import { UiState } from 'constants/ui'
import AppRouterRequestProvider from 'containers/RequestProvider/AppRouterRequestProvider'
import SystemConfigurationProvider from 'contexts/SystemConfigurationProvider'
import UserStatsProvider from 'contexts/UserStatsProvider'

import { AuthModalProvider } from '@marketplace-web/domain/identity'
import BrazeProvider from '_libs/common/braze/containers/BrazeProvider'
import { DataDomeProvider } from '@marketplace-web/domain/data-dome'

import TrackScreen from '../TrackScreen'
import { configureNextAppRouterStore } from '../../state/store'

type ProviderProps<T extends JSXElementConstructor<any>> = Omit<ComponentProps<T>, 'children'>

type Props = {
  children: ReactNode
  featureSwitchesProps: ProviderProps<typeof FeatureSwitchesProvider>
  envsProps: ProviderProps<typeof EnvsProvider>
  systemConfigurationProps: ProviderProps<typeof SystemConfigurationProvider>
  sessionProps: ProviderProps<typeof SessionProvider>
  abTestsProps: ProviderProps<typeof AbTestsProvider>
  intlProps: ProviderProps<typeof IntlProvider>
  cookies: Record<string, string | undefined>
  nextRequestProps: ProviderProps<typeof AppRouterRequestProvider>
  breakpointProps: ProviderProps<typeof BreakpointProvider>
}

const createProvider =
  <T extends JSXElementConstructor<any>>(provider: T, props?: ProviderProps<T>) =>
  (children: ReactNode) =>
    createElement(provider, props, children)

const ClientRootProviders = ({
  children,
  featureSwitchesProps,
  envsProps,
  systemConfigurationProps,
  sessionProps,
  abTestsProps,
  intlProps,
  cookies,
  nextRequestProps,
  breakpointProps,
}: Props) => {
  const cookieManagerRef = useRef<ReturnType<typeof createCookieManager> | null>(null)
  cookieManagerRef.current ??= createCookieManager(cookies)

  const debugPin = Number(sessionProps.initialSessionData.debugPin)
  const storeRef = useRef<ReturnType<typeof configureNextAppRouterStore> | null>(null)
  storeRef.current ??= configureNextAppRouterStore({
    abTests: Object.fromEntries(abTestsProps.initialAbTests.map(abTest => [abTest.name, abTest])),
    screen: { name: sessionProps.initialSessionData.screen },
    session: {
      anon_id: sessionProps.initialSessionData.anonId,
      countryCode: systemConfigurationProps.configuration.userCountry,
      isContentOnlyView: sessionProps.initialSessionData.isContentOnlyView,
      isWebView: sessionProps.initialSessionData.isWebview,
      languageCode: intlProps.locale,
      tracker_debug_pin: debugPin || undefined,
      tracking_platform: sessionProps.initialSessionData.trackingPlatform,
      user: sessionProps.initialSessionData.user,
    },
    systemConfiguration: {
      requestState: UiState.Success,
      systemConfiguration: systemConfigurationProps.configuration,
    },
  })

  const providers = [
    createProvider(ReduxProvider, { store: storeRef.current }),
    createProvider(FeatureSwitchesProvider, featureSwitchesProps),
    createProvider(EnvsProvider, envsProps),
    createProvider(SystemConfigurationProvider, systemConfigurationProps),
    createProvider(SessionProvider, sessionProps),
    createProvider(AbTestsProvider, abTestsProps),
    createProvider(IntlProvider, intlProps),
    createProvider(UserStatsProvider),
    createProvider(CookieManagerProvider, { cookieManager: cookieManagerRef.current }),
    createProvider(TrackingProvider),
    createProvider(AppRouterRequestProvider, nextRequestProps),
    createProvider(BrazeProvider),
    createProvider(TrackScreen),
    createProvider(BreakpointProvider, breakpointProps),
    createProvider(AuthModalProvider),
    createProvider(DataDomeProvider),
  ]

  return providers.reduceRight((providerChildren, provider) => provider(providerChildren), children)
}

export default ClientRootProviders
