'use client'

import { noop } from 'lodash'
import { createContext } from 'react'

import { AdsPlacementModel } from 'types/models'

export type AdsContextType = {
  placements: Array<AdsPlacementModel>
  segments: Record<string, string>
  hasAdBlockerBeenTracked: boolean
  shouldMockAds: boolean
  isAdBlockerUsed: boolean | null
  adBlockerVisitorId: string | null
  setIsAdBlockerUsed: (isAdBlockerUsed: boolean) => void
  setHasAdBlockerBeenTracked: (hasAdBlockerBeenTracked: boolean) => void
  setAdBlockerVisitorId: (adBlockerVisitorId: string) => void
}

const AdsContext = createContext<AdsContextType>({
  placements: [],
  segments: {},
  hasAdBlockerBeenTracked: false,
  shouldMockAds: false,
  isAdBlockerUsed: null,
  adBlockerVisitorId: null,
  setIsAdBlockerUsed: noop,
  setHasAdBlockerBeenTracked: noop,
  setAdBlockerVisitorId: noop,
})

export default AdsContext
