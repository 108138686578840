'use client'

import { MouseEvent, ReactNode } from 'react'
import { Cell, Icon } from '@vinted/web-ui'

type Props = {
  title: string
  icon: ComponentProps<typeof Icon>['name']
  url?: string
  suffix?: ReactNode
  onClick?: (event: MouseEvent) => void
  testId?: string
}

const AccountLinkWithIcon = ({ title, url, suffix, onClick, testId, icon }: Props) => (
  <Cell
    styling={Cell.Styling.Default}
    prefix={<Icon name={icon} color={Icon.Color.GreyscaleLevel3} />}
    title={title}
    theme="transparent"
    url={url}
    onClick={onClick}
    suffix={suffix}
    testId={testId}
  />
)

export default AccountLinkWithIcon
