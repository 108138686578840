import { kebabCase } from 'lodash'

import {
  Response,
  CountriesResp,
  ClosetPromoPerfrmanceResponse,
  BrandsResp,
  FeedbackRatingOptionsResp,
  GetItemUploadFeedbackRatingsResp,
  ClosetPromotionPricingResp,
  GetAdsPlacementsResp,
  PhotoTipsResp,
  GetShippingDetailsResp,
  UserSizesResp,
  GetSizeGroupsResp,
  NotificationSettingsResp,
  FaqEntryResp,
  UsersResp,
  GetIncomeSettingsResp,
  GetInvoicesAvailableDatesResp,
  UserResp,
  SendEmailVerificationCodeResp,
  GetInfoBoardPostsResp,
  GetTermsAndConditionsResp,
  GetInfoBoardPostResp,
  GetNotificationLandingsResp,
  MyOrdersResp,
  PhotoTipResp,
  FaqEntriesSearchResp,
  GetTermsAndConditionsContentResp,
  FaqEntryRecommendedFaqsResp,
  FaqEntryRelatedFaqsResp,
  BusinessAccountsTermsOfUseResp,
  ReferralShareResp,
  ReferralInviteInstructionsResp,
  GetUserItemsResp,
  ProfileUserInfoResp,
  InSiteSeoContentResp,
  GetOfflineVerificationFeedbackResp,
  CreateReferrerResp,
  GetSellerOfferOptionsResp,
  BusinessAccountAddressesResp,
  GetItemEscrowFeesResp,
  GetVasEntryPointsResp,
  PromotedClosetOrderResp,
  SellerBadgesResp,
  PromotedClosetsListResp,
  BumpMinimumPriceResp,
  GetPriceEstimateWithFeesResponse,
  PrepareBumpOrderResp,
  BumpOptionResp,
  UserBundleItemsResp,
  GetSellerInsightsResp,
  InfluencerResp,
  GetModelAttributeConfigurationResp,
  GetUploadFormModelsResp,
  GetHelpCenterLinkForUserMigrationResp,
  GetSellerFiltersResp,
  GetLabelOptionsResp,
  GetWardrobeItemsResp,
  GetRequestReturnDetailsResp,
  DefaultShippingAddressResp,
  GetReturnShippingOptionsResp,
  ShippingContactConfigurationResp,
  GetSellerInsightsPhotoRecommendationsResp,
  CustomShipmentConfigResp,
  DigitalLabelResp,
  GetShippingInstructionsResp,
  TrackingJourneySummaryResponse,
  ShipmentInstructionsResponse,
  GetCollectionDatesResp,
  GetVideoGameRatingsResp,
  GetCarrierSettingsFeedbackConfigurationResp,
  GetAvailableCarriersResp,
  CountryBoundsResponse,
  SystemConfigurationResp,
  NearbyPickupPointsResp,
  UserBundleItemsRespDeprecated,
  ItemPerformanceResponse,
  GetPublicPhotosResp,
  PackageSizesResp,
  GetBumpableItemsResp,
  ReserveItemDetailsResp,
  MarkAsSoldItemDetailsResp,
  GetBumpsRecommendedItemsResp,
  ElectronicsLandingPageResp,
} from 'types/api/response'
import {
  CountryModel,
  ClosetPromoPerformanceModel,
  FeedbackRatingModel,
  BrandModel,
  ClosetPromotionPricingModel,
  HelpCenterFaqEntryModel,
  WalletIncomeSettingsModel,
  InvoicesAvailableDatesModel,
  FeedEventsModel,
  HelpCenterQuestionModel,
  ReferralShareModel,
  ReferralInviteInstructionsModel,
  ProductItemModel,
  PaginationModel,
  InfluencerLandingPageModel,
  VideoGameRatingModel,
  CarrierSettingsFeedbackConfigurationModel,
  ElectronicsLandingPageModel,
} from 'types/models'
import { transformCountryDto } from 'data/transformers/country'
import { transformClosetPromoPerformanceDto } from 'data/transformers/closet-promo-performance'
import { transformBrandDto } from 'data/transformers/brand'
import { transformEscrowFees } from 'data/transformers/escrow-fees'
import {
  transformClosetPromotionOrder,
  transformClosetPromotionPricing,
} from 'data/transformers/closet-promotion'
import { transformItemDtosToProductItems } from 'data/transformers/product-item'
import { transformAdsPlacements } from 'data/transformers/ads'
import { transformPhotoTipDto, transformPhotoTipDtos } from 'data/transformers/photo-tip'
import {
  transformItemShippingDetailsDto,
  transformShippingOptionDtos,
} from 'data/transformers/shipping-option'
import { transformFeedbackFormDto } from 'data/transformers/feedback-form'
import { transformNotificationSettingsDto } from 'data/transformers/notification-settings'
import { transformUserDto, transformUserDtos } from 'data/transformers/user'
import { transformPaginationDto } from 'data/transformers/pagination'
import { HomepageBlocksModel } from 'types/models/homepage-blocks'
import { FeedEventsDto, HomepageBlocksDto } from 'types/dtos'
import { EmailVerificationCodeModel } from 'types/models/email-verification-code'
import { transformTermsAndConditionsDto } from 'data/transformers/terms-and-conditions'
import {
  transformInfoBoardPostDto,
  transformInfoBoardPostDtos,
  transformProfileUserInfoDto,
  transformSellerOfferOptionsDto,
  transformUserBundleItems,
  transformUserBundleItemsDeprecated,
  transformVideoGameRatingDtos,
} from 'data/transformers'
import { transformMyOrderDto } from 'data/transformers/my-order'
import { AccessChannel } from 'constants/index'
import { transformTermsAndConditionsContentDto } from 'data/transformers/terms-and-conditions-content'
import {
  transformBusinessAccountAddressesDto,
  transformBusinessAccountsTermsOfUseDto,
} from 'data/transformers/business-account'
import {
  transformReferralInviteInstructionsDto,
  transformReferralShareDto,
} from 'data/transformers/referral'
import { transformInSiteSeoContentDto } from 'data/transformers/seo'
import { SeoContentModel } from 'types/models/seo'
import { transformVasEntryPoints } from 'data/transformers/vas-entry-point'
import { transformSellerBadgeDto } from 'data/transformers/user-badge'
import { transformClosets } from 'data/transformers/closet'
import {
  transformBumpItems,
  transformBumpMinimumPrice,
  transformBumpUpOrder,
  transformBumpsOptions,
} from 'data/transformers/bumps'
import { transformPriceEstimateWithFees } from 'data/transformers/price-estimate-with-fees'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import {
  transformInfluencerClosetDto,
  transformInfluencerDisclaimerDto,
  transformInfluencerInspirationDto,
  transformInfluencerIntroductionDto,
  transformInfluencerLifestyleDto,
  transformInfluencerSeoDto,
  transformInfluencerTipsDto,
} from 'data/transformers/influencer'
import { transformHelpCenterLinkInMigration } from 'data/transformers/help-center'
import { transformPromoBoxDto } from 'data/transformers/generic-promo-box'
import { transformHomepageBlockEntities } from 'data/transformers/homepage-blocks'
import { transformLabelOptions } from 'data/transformers/label-options'
import { transformRequestReturnDetailsDto } from 'data/transformers/request-return-details'
import { transformAddress } from 'data/transformers/address'
import { transformWardrobeItemDtosToProductItems } from 'data/transformers/wardrobe-item'
import { transformReturnShippingOptionDtos } from 'data/transformers/return-shipping-option'
import { transformReturnCurrencyConversionDto } from 'data/transformers/return-currency-conversion'
import { transformShippingContactConfigurationDto } from 'data/transformers/shipping-contact'
import { transformCustomShipmentDto } from 'data/transformers/shipment-custom-config'
import { transformDigitalLabel } from 'data/transformers/digital-label'
import { transformShippingInstructionsDto } from 'data/transformers/shipping-instructions'
import { transformTrackingJourneySummary } from 'data/transformers/tracking-journey-summary'
import { transformShipmentInstructions } from 'data/transformers/shipment-instructions'
import { transformCollectionDates } from 'data/transformers/collection-dates'
import { transformCarrierSettingsFeedback as transformCarrierSettingsFeedbackConfiguration } from 'data/transformers/carrier-settings-feedback'
import { transformAvailableCarrierDtos } from 'data/transformers/available-carrier'
import { transformCountryBoundsDto } from 'data/transformers/country-bounds'
import { transformSystemConfigurationDto } from 'data/transformers/system-configuration'
import { transformPickupPointsDto } from 'data/transformers/pickup-point'
import { transformItemPerformance } from 'data/transformers/item-performance'
import { transformReserveItemDetailsDto } from 'data/transformers/reserve-item'
import { transformMarkAsSoldItemDetailsDto } from 'data/transformers/mark-as-sold-item'
import { transformPackageSizeDto } from 'data/transformers/package-sizes'
import {
  transformElectroncisIntroductionSectionDto,
  transformDiscoverSectionDto,
  transformElectroncisSeoDto,
  transformHowSectionDto,
  transformWhySectionDto,
  transformBottomSectionDto,
  transformDisclaimerSectionDto,
} from 'data/transformers/electronics-landing-page'

export const transformCountryResponse = (response: Response<CountriesResp>): Array<CountryModel> =>
  response.countries.map(transformCountryDto)

export const transformClosetPromoPerformanceResponse = (
  response: Response<ClosetPromoPerfrmanceResponse>,
): ClosetPromoPerformanceModel | null =>
  response.closet_promotion_performance &&
  transformClosetPromoPerformanceDto(response.closet_promotion_performance)

export const transformBrandsResponse = (response: Response<BrandsResp>): Array<BrandModel> =>
  response.brands.map(transformBrandDto)

export const transformFeedbackRatingsResponse = (
  response: Response<
    | FeedbackRatingOptionsResp
    | GetItemUploadFeedbackRatingsResp
    | GetOfflineVerificationFeedbackResp
  >,
): FeedbackRatingModel | undefined => {
  if (!response.feedback_ratings) return undefined

  return transformFeedbackFormDto(response.feedback_ratings)
}

export const transformCarrierSettingsFeedbackConfigurationResponse = (
  response: Response<GetCarrierSettingsFeedbackConfigurationResp>,
): CarrierSettingsFeedbackConfigurationModel | undefined => {
  return transformCarrierSettingsFeedbackConfiguration(response.feedback_ratings)
}

export const transformClosetPromotionPricingResponse = (
  response: Response<ClosetPromotionPricingResp>,
): ClosetPromotionPricingModel => transformClosetPromotionPricing(response.promoted_closet_pricing)

export const transformGetAdsPlacements = (response: Response<GetAdsPlacementsResp>) =>
  transformAdsPlacements(response.placements)

export const transformPhotoTipsResponse = (response: Response<PhotoTipsResp>) =>
  transformPhotoTipDtos(response.photo_tips)

export const transformPhotoTipResponse = (response: Response<PhotoTipResp>) =>
  transformPhotoTipDto(response.photo_tip)

export const transformGetShippingDetails = (response: Response<GetShippingDetailsResp>) =>
  response.shipping_details && transformItemShippingDetailsDto(response.shipping_details)

export const transformUserSizesResponse = (response: Response<UserSizesResp>) => ({
  sizeIds: response.size_ids,
})

export const transformSizeGroupsResponse = (response: Response<GetSizeGroupsResp>) => ({
  sizeGroups: response.size_groups,
})

export const transformNotificationSettingsResponse = (
  response: Response<NotificationSettingsResp>,
) => transformNotificationSettingsDto(response.notification_settings.email)

export const transformFaqEntryResponse = ({
  faq_entry,
}: FaqEntryResp): HelpCenterFaqEntryModel | undefined => {
  if (!faq_entry) return undefined

  return {
    id: faq_entry.id,
    title: faq_entry.title,
    body: faq_entry.body,
  }
}

export const transformFaqEntrySearchResponse = ({
  faq_entries,
}: FaqEntriesSearchResp): Array<HelpCenterQuestionModel> | null => {
  if (!faq_entries.length) return null

  return faq_entries.map(({ id, title }) => ({
    id,
    title,
    path: `/help/${kebabCase(`${id}-${title}`)}?access_channel=${AccessChannel.HcSearch}`,
  }))
}

export const transformUsersResponse = (response: Response<UsersResp>) => ({
  users: transformUserDtos(response.users),
  pagination: transformPaginationDto(response.pagination),
})

export const transformWalletIncomeSettingsResponse = ({
  is_monthly_report_enabled,
}: GetIncomeSettingsResp): WalletIncomeSettingsModel => ({
  isMonthlyReportEnabled: is_monthly_report_enabled,
})

export const transformInvoicesAvailableDatesResponse = ({
  available_dates,
}: GetInvoicesAvailableDatesResp): InvoicesAvailableDatesModel => ({
  availableDates: [...available_dates],
})

export const transformUserResponse = (response: Response<UserResp>) => ({
  user: transformUserDto(response.user),
})

export const transformHomepageBlockResponse = (
  response: HomepageBlocksDto,
): HomepageBlocksModel => ({
  promoBox: response.promo_box ? transformPromoBoxDto(response.promo_box.entity) : null,
  blocks: transformHomepageBlockEntities(response.blocks),
  nextPageToken: response.next_page_token,
  showLoadMoreButton: response.show_load_more_button,
})

export const transformFeedEventsResponse = (
  response: Response<FeedEventsDto>,
): FeedEventsModel => ({
  events: response.feed_events,
  initialMaxScore: response.max_score,
  showLoadMoreButton: response.show_load_more_button,
})

export const transformEmailVerificationCodeResponse = (
  response: Response<SendEmailVerificationCodeResp>,
): EmailVerificationCodeModel => ({
  email: response.email,
  canChangeEmail: response.can_change_email ?? true,
})

export const transformInfoBoardPostsResponse = (response: Response<GetInfoBoardPostsResp>) =>
  transformInfoBoardPostDtos(response.info_posts)

export const transformTermsAndConditionsResponse = (
  response: Response<GetTermsAndConditionsResp>,
) => transformTermsAndConditionsDto(response.terms_and_conditions)

export const transformInfoBoardPostResponse = (response: Response<GetInfoBoardPostResp>) =>
  transformInfoBoardPostDto(response.info_post)

export const transformNotificationLandingsResponse = ({
  notification_landing,
}: Response<GetNotificationLandingsResp>) => ({
  body: notification_landing.body,
  buttonTitle: notification_landing.button_title,
  buttonUrl: notification_landing.button_url,
  code: notification_landing.code,
  id: notification_landing.id,
  imageUrl: notification_landing.image_url,
  title: notification_landing.title,
})

export const transformMyOrdersResponse = (response: Response<MyOrdersResp>) => ({
  orders: response.my_orders.map(transformMyOrderDto),
  pagination: transformPaginationDto(response.pagination),
})

export const transformTermsAndConditionsContentResponse = (
  response: Response<GetTermsAndConditionsContentResp>,
) => transformTermsAndConditionsContentDto(response)

export const transformFaqEntryRecommendedFaqsResponse = ({
  faq_recommendations,
}: Response<FaqEntryRecommendedFaqsResp>): Array<HelpCenterQuestionModel> | null => {
  if (!faq_recommendations.length) return null

  return faq_recommendations
}

export const transformFaqEntryRelatedFaqsResponse = ({
  related_faqs,
}: Response<FaqEntryRelatedFaqsResp>): Array<HelpCenterQuestionModel> | null => {
  if (!related_faqs.length) return null

  return related_faqs
}

export const transformDefaultShippingAddress = ({
  user_address,
}: Response<DefaultShippingAddressResp>) => {
  if (!user_address) return null

  return transformAddress(user_address)
}

export const transformCountryBounds = ({ country_bounds }: Response<CountryBoundsResponse>) => {
  if (!country_bounds) return null

  return transformCountryBoundsDto(country_bounds)
}

export const transformBusinessAccountAddressesResponse = (
  response: Response<BusinessAccountAddressesResp>,
) => ({
  addresses: transformBusinessAccountAddressesDto(response.addresses),
})

export const transformBusinessAccountsTermsOfUseResponse = (
  response: Response<BusinessAccountsTermsOfUseResp>,
) => transformBusinessAccountsTermsOfUseDto(response)

export const transformReferralShareResponse = ({
  share,
}: Response<ReferralShareResp>): ReferralShareModel => transformReferralShareDto(share)

export const transformUserItemsResponse = (
  response: Response<GetUserItemsResp>,
): Array<ProductItemModel> => transformItemDtosToProductItems(response.items)

export const transformReferralInviteInstructionsResponse = ({
  invite_instructions,
}: Response<ReferralInviteInstructionsResp>): ReferralInviteInstructionsModel =>
  transformReferralInviteInstructionsDto(invite_instructions)

export const transformProfileUserInfoResponse = (response: Response<ProfileUserInfoResp>) =>
  transformProfileUserInfoDto(response)

export const transformInSiteSeoContentResponse = ({
  data,
}: Response<InSiteSeoContentResp>): SeoContentModel => transformInSiteSeoContentDto(data.attributes)

export const transformGetVideoGameRatingResponse = (
  response: Response<GetVideoGameRatingsResp>,
): Array<VideoGameRatingModel> => transformVideoGameRatingDtos(response.video_game_ratings)

export const transformWardrobeItemsResponse = (
  response: Response<GetWardrobeItemsResp>,
): {
  items: Array<ProductItemModel>
  pagination: PaginationModel
} => ({
  items: transformWardrobeItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})

export const transformReserveItemDetailsResponse = (response: Response<ReserveItemDetailsResp>) =>
  transformReserveItemDetailsDto(response.reserve_item_details)

export const transformMarkAsSoldItemDetailsResponse = (
  response: Response<MarkAsSoldItemDetailsResp>,
) => transformMarkAsSoldItemDetailsDto(response.mark_as_sold_item_details)

export const transformCreateReferrerResponse = (response: Response<CreateReferrerResp>) => ({
  referralInviteUrl: response.referral_invite_url,
  referrerId: response.referrer_id,
})

export const transformSellerOfferOptionsResponse = (
  response: Response<GetSellerOfferOptionsResp>,
) => transformSellerOfferOptionsDto(response.seller_offer_options)

export const transformItemEscrowFeesResponse = (response: Response<GetItemEscrowFeesResp>) =>
  transformEscrowFees(response.escrow_fees)

export const transformVasEntryPointsResponse = (response: Response<GetVasEntryPointsResp>) =>
  transformVasEntryPoints(response.entry_points)

export const transformPrepareClosetPromotionOrderResponse = (
  response: Response<PromotedClosetOrderResp>,
) => transformClosetPromotionOrder(response.closet_promotion.closet_promotion_order)

export const transformSellerBadgesResponse = (response: Response<SellerBadgesResp>) =>
  response.badges.map(transformSellerBadgeDto)

export const transformClosetPromotionsResponse = (response: Response<PromotedClosetsListResp>) =>
  transformClosets(response.promoted_closets)

export const transformBumpMinimumPriceResponse = (response: Response<BumpMinimumPriceResp>) =>
  transformBumpMinimumPrice(response.minimum_price)

export const transformGetPriceEstimateWithFeesResponse = (
  response: Response<GetPriceEstimateWithFeesResponse>,
) => transformPriceEstimateWithFees(response)

export const transformPrepareBumpOrderResponse = (response: Response<PrepareBumpOrderResp>) =>
  transformBumpUpOrder(response.push_up_order)

export const transformBumpOptionsResponse = (response: Response<BumpOptionResp>) =>
  transformBumpsOptions(response)

export const transformUserBundleItemsResponseDeprecated = (
  response: Response<UserBundleItemsRespDeprecated>,
) => transformUserBundleItemsDeprecated(response)

export const transformUserBundleItemsResponse = (response: Response<UserBundleItemsResp>) =>
  transformUserBundleItems(response)

export const transformGetSellerInsightsResponse = (response: Response<GetSellerInsightsResp>) => ({
  isVisible: response.is_visible,
  activeItemsStats: {
    ...response.active_items_stats,
    total: transformCurrencyAmountDto(response.active_items_stats.total),
  },
  soldItemsStats: {
    ...response.sold_items_stats,
    total: transformCurrencyAmountDto(response.sold_items_stats.total),
    period: {
      ...response.sold_items_stats.period,
      total: transformCurrencyAmountDto(response.sold_items_stats.period.total),
    },
  },
})

export const transformGetSellerInsightsPhotoRecommendationsResponse = (
  response: GetSellerInsightsPhotoRecommendationsResp,
) => ({
  status: response.status,
  itemsToImprove: response.items_to_improve.map(
    ({ item_id, photo_url, accessibility_description }) => ({
      itemId: item_id,
      photoUrl: photo_url,
      accessibilityDescription: accessibility_description,
    }),
  ),
})

export const transformInfluencerResponse = (
  response: InfluencerResp,
): InfluencerLandingPageModel => ({
  seo: transformInfluencerSeoDto(response.data[0].attributes.seo),
  introduction: transformInfluencerIntroductionDto(
    response.data[0].attributes.introduction_section,
  ),
  lifestyle: transformInfluencerLifestyleDto(response.data[0].attributes.lifestyle_section),
  closet: transformInfluencerClosetDto(response.data[0].attributes.closet_section),
  tips: transformInfluencerTipsDto(response.data[0].attributes.tips_section),
  inspiration: transformInfluencerInspirationDto(response.data[0].attributes.inspiration_section),
  disclaimer: transformInfluencerDisclaimerDto(response.data[0].attributes.disclaimer_section),
})

export const transformGetModelAttributeConfigurationResponse = (
  response: Response<GetModelAttributeConfigurationResp>,
) => ({ isVisible: response.visible, isRequired: response.required })

export const transformGetUploadFormModelsResponse = (
  response: Response<GetUploadFormModelsResp>,
) => ({
  sortingType: response.sorting_type,
  models: response.models,
})

export const transformHelpCenterLinksResponse = (
  response: Response<GetHelpCenterLinkForUserMigrationResp>,
) => transformHelpCenterLinkInMigration(response)

export const transformGetSellerFiltersResponse = (response: Response<GetSellerFiltersResp>) =>
  response.filters.reduce((obj, filter) => ({ ...obj, [filter.name]: filter.count }), {})

export const transformLabelOptionsResponse = (response: Response<GetLabelOptionsResp>) =>
  transformLabelOptions(response.label_options)

export const transformRequestReturnDetailsResponse = (
  response: Response<GetRequestReturnDetailsResp>,
) => transformRequestReturnDetailsDto(response.request_return_details)

export const transformGetReturnShippingOptionsResp = (
  response: Response<GetReturnShippingOptionsResp>,
) => ({
  returnShippingOptions: transformReturnShippingOptionDtos(response.return_shipping_options),
  currencyConversion: response.currency_conversion
    ? transformReturnCurrencyConversionDto(response.currency_conversion)
    : null,
})

export const transformShippingContactConfigurationResp = (
  response: Response<ShippingContactConfigurationResp>,
) => transformShippingContactConfigurationDto(response.shipping_contact_configuration)

export const transformSystemConfigurationResp = (response: Response<SystemConfigurationResp>) =>
  transformSystemConfigurationDto(response.system_configuration)

export const transformGetCustomShipmentConfig = (response: Response<CustomShipmentConfigResp>) =>
  transformCustomShipmentDto(response.custom_shipment_configuration)

export const transformDigitalLabelResponse = (response: Response<DigitalLabelResp>) =>
  transformDigitalLabel(response.digital_label)

export const transformShippingInstructionsResponse = (
  response: Response<GetShippingInstructionsResp>,
) => transformShippingInstructionsDto(response.shipping_instructions)

export const transformTrackingJourneySummaryResponse = (
  response: Response<TrackingJourneySummaryResponse>,
) => transformTrackingJourneySummary(response.journey_summary)

export const transformShipmentInstructionsResponse = (
  response: Response<ShipmentInstructionsResponse>,
) => transformShipmentInstructions(response.shipment_instructions)

export const transformCollectionDatesResponse = (response: Response<GetCollectionDatesResp>) =>
  transformCollectionDates(response.collection_dates)

export const transformAvailableCarriersResponse = (response: Response<GetAvailableCarriersResp>) =>
  transformAvailableCarrierDtos(response.available_rates)

export const tranformCountryBoundsResponse = (response: Response<CountryBoundsResponse>) =>
  transformCountryBounds(response)

export const transformNearbyPickupPoints = (response: Response<NearbyPickupPointsResp>) => {
  return {
    nearbyShippingPoints: transformPickupPointsDto(response.nearby_shipping_points),
    nearbyShippingOptions: transformShippingOptionDtos(response.nearby_shipping_options),
    suggestedShippingPointCode: response.suggested_shipping_point_code,
  }
}

export const transformItemPerformanceResponse = (response: Response<ItemPerformanceResponse>) => {
  return response.item_performance ? transformItemPerformance(response.item_performance) : null
}

export const transformPublicPhotosResponse = (response: Response<GetPublicPhotosResp>) => {
  return {
    photos: response.photos.map(({ item_id, url }) => ({
      itemId: item_id,
      url,
    })),
  }
}

export const tranformPackageSizesResponse = (response: Response<PackageSizesResp>) => {
  return response.package_sizes.map(transformPackageSizeDto)
}

export const transformBumpableItemsResponse = (response: Response<GetBumpableItemsResp>) => ({
  items: transformBumpItems(response.items),
  pageInfo: { hasMore: response.page_info.has_more },
})

export const transformBumpsRecommendedItemsResponse = (
  response: Response<GetBumpsRecommendedItemsResp>,
) => transformBumpItems(response.items)

export const transformElectroncisLandingPageResponse = (
  response: Response<ElectronicsLandingPageResp>,
): ElectronicsLandingPageModel => ({
  seo: transformElectroncisSeoDto(response.data[0].attributes.seo),
  introductionSection: transformElectroncisIntroductionSectionDto(
    response.data[0].attributes.introduction_section,
  ),
  whySection: transformWhySectionDto(response.data[0].attributes.why_section),
  howSection: transformHowSectionDto(response.data[0].attributes.how_section),
  discoverSection: transformDiscoverSectionDto(response.data[0].attributes.discover_section),
  bottomSection: transformBottomSectionDto(response.data[0].attributes.bottom_section),
  disclaimerSection: transformDisclaimerSectionDto(response.data[0].attributes.disclaimer_section),
})
