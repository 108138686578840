'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Cell, Icon, InputText, Spacer, Text } from '@vinted/web-ui'
import { Eye16, EyeDenied16 } from '@vinted/monochrome-icons'

import { navigateToPage } from '_libs/utils/window'

import { FormattedMessage } from 'components/@support'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { FaqEntryType } from 'constants/faq-entry'
import { AccessChannel } from 'constants/index'
import { FORGOT_PASSWORD_URL, ROOT_URL } from 'constants/routes'
import { authenticateUser } from 'data/api/authentication/requests'
import { transformAuthenticateUserError } from 'data/transformers/authentication'

import { getFingerprint } from '@marketplace-web/domain/audit'
import { ResponseCode } from 'data/api/response-codes'
import useRefUrl from 'hooks/useRefUrl'
import useTranslate from 'hooks/useTranslate'
import { renderValidation } from 'components/Input'

import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { AuthenticateGrantType } from '@marketplace-web/shared/authentication'

import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ExternalRegisterData } from '../../types'
import useSuccessUrl from '../../hooks/useSuccessUrl/useSuccessUrl'
import useAuthTracking from '../../hooks/useAuthTracking'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'

type Props = ExternalRegisterData

type Fields = {
  password: string
}

const PasswordVerificationLogin = ({ email, idToken }: Props) => {
  const translate = useTranslate('user.wrong_email_in_registration')
  const refUrl = useRefUrl(ROOT_URL)
  const successUrl = useSuccessUrl()
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<Fields>()
  const getErrorMessage = useFormValidationMessage(errors)
  const { trackInputEvent, trackClickEvent } = useAuthTracking()
  const { handleViewTwoFactorLogin } = useAuthenticationContext()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const doAuthenticate = async ({ password }: Fields) => {
    if (!email) return

    const fingerprint = await getFingerprint()

    const response = await authenticateUser({
      grantType: AuthenticateGrantType.Password,
      username: email,
      controlCode: idToken,
      password,
      fingerprint,
    })
    const is2FARequired =
      response.code === ResponseCode.Required2FA ||
      response.code === ResponseCode.VerifierSecondFactorRequired

    if (is2FARequired && 'payload' in response && response.payload) {
      handleViewTwoFactorLogin({
        ...transformAuthenticateUserError(response.payload),
        refUrl: successUrl,
      })

      return
    }

    if ('errors' in response) {
      setError('password', { type: 'manual', message: response.message })

      return
    }

    navigateToPage(refUrl)
  }

  useDataDomeCaptcha(() => {
    handleSubmit(doAuthenticate)()
  })

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(prevState => !prevState)
  }

  const handleInputFocus = (target: string) => () => trackInputEvent({ target, state: 'focus' })

  const handleInputBlur = (target: string) => () => trackInputEvent({ target, state: 'unfocus' })

  const getInputEvents = (field: string) => {
    return {
      onFocus: handleInputFocus(field),
      onBlur: handleInputBlur(field),
    }
  }

  const handleClickTracking = (target: ClickableElement) => () => {
    trackClickEvent({ target })
  }

  const renderLink = ({
    url,
    text,
    eventTarget,
  }: {
    url: string
    text: string
    eventTarget: ClickableElement
  }) => (
    <a href={url} onClick={handleClickTracking(eventTarget)}>
      <Text as="span" bold text={text} clickable />
    </a>
  )

  const renderContactUsLink = (url: string) =>
    renderLink({ url, text: translate('contact_us'), eventTarget: ClickableElement.HavingTrouble })

  const renderPasswordResetLink = (url: string) =>
    renderLink({
      url,
      text: translate('reset_password'),
      eventTarget: ClickableElement.ForgotPassword,
    })

  const passwordIconName = isPasswordVisible ? EyeDenied16 : Eye16

  return (
    <form onSubmit={handleSubmit(doAuthenticate)}>
      <Cell>
        <Text
          as="h1"
          text={translate('heading', { registration_email: email })}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          type={Text.Type.Heading}
        />
        <Spacer size={Spacer.Size.X2Large} />
        <Spacer />
        <FormattedMessage
          id="user.wrong_email_in_registration.error_explanation"
          values={{ registration_email: <Text as="span" text={email} bold /> }}
        />
      </Cell>
      <InputText
        {...register('password')}
        placeholder={translate('placeholder')}
        validation={renderValidation(getErrorMessage('password'))}
        type={isPasswordVisible ? InputText.Type.Text : InputText.Type.Password}
        suffix={
          <Button
            size={Button.Size.Medium}
            icon={<Icon name={passwordIconName} color={Icon.Color.GreyscaleLevel3} />}
            styling={Button.Styling.Flat}
            onClick={togglePasswordVisibility}
          />
        }
        {...getInputEvents('password')}
      />
      <Cell>
        <div className="u-text-center">
          <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
            <Button
              testId="login-button"
              type={Button.Type.Submit}
              text={translate('button', { registration_email: email })}
              styling={Button.Styling.Filled}
              onClick={handleClickTracking(ClickableElement.SocialLoginWithPassword)}
            />
            {renderPasswordResetLink(FORGOT_PASSWORD_URL)}
            <FaqEntryUrl
              type={FaqEntryType.CantLogin}
              accessChannel={AccessChannel.ProductLink}
              render={url => renderContactUsLink(url)}
            />
          </SeparatedList>
        </div>
      </Cell>
      <Spacer />
    </form>
  )
}

export default PasswordVerificationLogin
