'use client'

import { useRef, useEffect, useCallback } from 'react'

import { getCurrentTimeInSeconds } from '_libs/utils/date'
import { leaveFaqEntryEvent, noticesUserOpenFaq } from '_libs/common/event-tracker/events'
import useLocation from 'hooks/useLocation'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useHelpCenterSessionTracking from 'hooks/useHelpCenterSessionTracking'
import { useSession } from '@marketplace-web/shared/session'

type Props = {
  faqEntryId: number
  accessChannel?: string | undefined
}

const HelpCenterFaqEntryTrackingEvent = ({ faqEntryId, accessChannel }: Props) => {
  const { isWebview } = useSession()
  const { access_channel, thread_id } = useLocation().searchParams

  const initialTime = useRef(getCurrentTimeInSeconds())
  const initialPageScrollDepth = useRef(0)
  const maxPageScrollDepth = useRef(0)

  const { track } = useTracking()
  const { helpCenterSessionId } = useHelpCenterSessionTracking()

  const getScrollDepth = (scrollOffset = 0) => {
    const documentHeight = document.body.scrollHeight
    const clientHeight = document.documentElement.clientHeight + scrollOffset

    if (clientHeight <= 0) {
      return 0
    }

    return Math.floor((clientHeight / documentHeight) * 100)
  }

  const handleBeforeUnload = useCallback(() => {
    if (isWebview) return
    if (Array.isArray(access_channel)) return

    const totalViewTime = getCurrentTimeInSeconds() - initialTime.current

    if (!helpCenterSessionId) return

    track(
      leaveFaqEntryEvent({
        faqEntryId,
        faqAccessChannel: access_channel || accessChannel || null,
        initialPageScrollDepth: initialPageScrollDepth.current,
        maxPageScrollDepth: maxPageScrollDepth.current,
        totalViewTime,
        helpCenterSessionId,
      }),
    )
  }, [isWebview, access_channel, track, faqEntryId, accessChannel, helpCenterSessionId])

  const handleScrollDepthUpdate = useCallback(() => {
    const visibleScrollDepth = getScrollDepth()
    const currentScrollDepth = getScrollDepth(window.pageYOffset)

    initialPageScrollDepth.current = visibleScrollDepth

    if (currentScrollDepth > maxPageScrollDepth.current) {
      maxPageScrollDepth.current = currentScrollDepth
    }
  }, [])

  useEffect(() => {
    if (isWebview) return

    if (typeof thread_id !== 'string') return

    track(noticesUserOpenFaq({ threadId: thread_id }))
  }, [isWebview, thread_id, track])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleScrollDepthUpdate)
    const element = document.body

    resizeObserver.observe(element)

    return () => {
      resizeObserver.unobserve(element)
    }
  }, [handleScrollDepthUpdate])

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [handleBeforeUnload])

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDepthUpdate)

    return () => {
      window.removeEventListener('scroll', handleScrollDepthUpdate)
    }
  }, [handleScrollDepthUpdate])

  return null
}

export default HelpCenterFaqEntryTrackingEvent
