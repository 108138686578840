'use client'

import { useDispatch } from 'react-redux'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { makeOfferEvent } from '_libs/common/event-tracker/events'
import * as api from 'data/api'
import useOfferContext from 'pages/Inbox/OfferProvider/useOfferContext'

import { actions } from 'state/conversation/slice'

type Props = {
  offerPrice: string
  currentPrice: string
  currency: string
  transactionId: number
  offerSuggestionOptionIndex?: number
}

const useCreateOffer = () => {
  const dispatch = useDispatch()
  const { offerRequestSessionId } = useOfferContext()
  const { track } = useTracking()

  const createOfferRequest = async ({
    offerPrice,
    currentPrice,
    currency,
    transactionId,
    offerSuggestionOptionIndex,
  }: Props) => {
    dispatch(
      actions.createOfferRequest({
        offerPrice,
        currentPrice,
        currency,
        transactionId,
        offerSuggestionOptionIndex,
      }),
    )
    const response = await api.createOfferRequest({
      transactionId,
      price: offerPrice,
      currency,
    })

    if ('errors' in response) {
      dispatch(actions.createOfferFailure({ error: response?.errors?.[0]?.value || null }))

      return
    }

    const offerRequestId = 'offer_request' in response && response.offer_request.id
    const offerSuggestionOptionNumber = offerSuggestionOptionIndex
      ? offerSuggestionOptionIndex + 1
      : null

    const offerEvent = makeOfferEvent({
      isBuyer: true,
      transactionId,
      offerRequestSessionId,
      offerSuggestionOptionNumber,
      offerRequestId: offerRequestId || undefined,
      offeredPrice: Number(offerPrice),
      currentPrice: Number(currentPrice),
    })

    track(offerEvent)
    dispatch(actions.createOfferSuccess())
  }

  return { createOfferRequest }
}

export default useCreateOffer
