import { useEffect, useRef, useState } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { toggleBrandFollow } from 'data/api'
import { followBrandEvent } from '_libs/common/event-tracker/events'
import { useAuthModal } from '@marketplace-web/domain/identity'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

type Props = {
  brandId: number
  isFollowed: boolean
}

const useFollowBrand = ({ brandId, isFollowed: initialIsFollowed }: Props) => {
  const isLoggedIn = Boolean(useSession().user)
  const [isFollowed, setIsFollowed] = useState(initialIsFollowed)
  const { track } = useTracking()
  const isLoading = useRef(false)
  const { openAuthModal } = useAuthModal()

  const holdout = useAbTest('buyer_domain_holdout_2025q1')?.variant !== 'off'
  const isFollowBrandDisabled = useAbTest('follow_brands_removal')?.variant === 'on' && holdout

  useEffect(() => {
    setIsFollowed(initialIsFollowed)
  }, [initialIsFollowed])

  function signIn() {
    openAuthModal()
  }

  async function toggle() {
    if (isLoading.current) return

    isLoading.current = true
    const response = await toggleBrandFollow({ brandId })
    isLoading.current = false

    if ('errors' in response) return

    const isFollowEvent = response.is_followed

    track(followBrandEvent({ brandId, isFollowEvent }))
    setIsFollowed(isFollowEvent)
  }

  return {
    isFollowed,
    toggleFollow: isLoggedIn ? toggle : signIn,
    isFollowBrandDisabled,
  }
}

export default useFollowBrand
