'use client'

import { QuestionCircle24 } from '@vinted/monochrome-icons'
import { Button, Icon } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  testId?: string
  url: string
}

const HeaderHelpButton = ({ testId, url }: Props) => {
  const translate = useTranslate('header')

  return (
    <Button
      url={url}
      testId={testId}
      styling={Button.Styling.Flat}
      aria={{ 'aria-label': translate('a11y.help') }}
      icon={<Icon name={QuestionCircle24} color={Icon.Color.GreyscaleLevel2} />}
    />
  )
}

export default HeaderHelpButton
