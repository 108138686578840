'use client'

import { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import useLocation from 'hooks/useLocation'
import { getCardLoggersById } from '_libs/common/braze/utils/event-loggers'
import type { InitializeBrazeReturnType } from '_libs/common/braze/unsafe-initialize'
import { flushCustomEventsThrottled } from '_libs/common/braze/utils/custom-event'
import { requestContentCardsRefreshThrottled } from '_libs/common/braze/utils/async-utils'
import { useSession } from '@marketplace-web/shared/session'

import useInitializeBraze from './useInitializeBraze'
import BrazeContext, { initialBrazeContextValue } from './BrazeContext'
import useManageInAppMessage from './useManageInAppMessage'

const BrazeProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [providers, setProviders] = useState<Awaited<InitializeBrazeReturnType>['providers']>()
  const [contextValue, setContextValue] = useState(initialBrazeContextValue)
  const [isInitialized, setIsInitialized] = useState(false)

  const { relativeUrl } = useLocation()
  const { user } = useSession()
  const userExternalId = user?.external_id

  const initializeBraze = useInitializeBraze()

  useManageInAppMessage(providers?.inAppMessageProvider)

  useEffect(() => {
    initializeBraze().then(({ initialized, stores, providers: newProviders }) => {
      const cardStores = [
        stores.inboxMessageCardStore,
        stores.inboxNotificationCardStore,
        stores.promoBoxCardStore,
      ]
      const cardLoggers = getCardLoggersById(cardStores)

      if (newProviders) setProviders(newProviders)

      setIsInitialized(initialized)
      setContextValue({
        ...stores,
        ...cardLoggers,
      })
    })
  }, [initializeBraze])

  const handleRelativeUrlChange = useCallback(() => {
    if (!userExternalId) return
    if (!isInitialized) return

    flushCustomEventsThrottled(userExternalId)
    requestContentCardsRefreshThrottled()
  }, [isInitialized, userExternalId])

  useEffect(() => {
    handleRelativeUrlChange()
  }, [relativeUrl, handleRelativeUrlChange])

  return <BrazeContext.Provider value={contextValue}>{children}</BrazeContext.Provider>
}

export default BrazeProvider
