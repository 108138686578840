import {
  ItemPageHorizontalScrollBlockDto,
  ItemPageHorizontalScrollBlockHeaderDto,
} from 'types/dtos'
import { transformHorizontalScrollBlockItemDtoToProductItem } from 'data/transformers/horizontal-scroll-block'

import {
  ItemPageHorizontalScrollBlockHeaderPluginModel,
  ItemPageHorizontalScrollBlockPluginModel,
} from './types'

export const getItemPageHorizontalScrollBlockPluginModel = (
  pluginData: ItemPageHorizontalScrollBlockDto,
): ItemPageHorizontalScrollBlockPluginModel => ({
  itemId: pluginData.item_id,
  contentSource: pluginData.content_source,
  items: pluginData.items
    ? pluginData.items.map(transformHorizontalScrollBlockItemDtoToProductItem)
    : null,
})

export const getItemPageHorizontalScrollBlockHeaderPluginModel = (
  pluginData: ItemPageHorizontalScrollBlockHeaderDto,
): ItemPageHorizontalScrollBlockHeaderPluginModel => {
  const {
    header_cta: headerCta,
    item_id: itemId,
    title,
    content_source: contentSource,
  } = pluginData

  return {
    itemId,
    title,
    contentSource,
    headerCta: headerCta ? { title: headerCta.title, url: headerCta.url } : null,
  }
}
