'use client'

import { useIntl } from 'react-intl'
import { useWatch } from 'react-hook-form'
import { Button, Cell } from '@vinted/web-ui'

import { useTrackAbTestCallback, useAbTest } from '@marketplace-web/shared/ab-tests'
import { formatCurrency } from '_libs/utils/formatString'
import useTranslate from 'hooks/useTranslate'

import { OfferFormField } from '../types'

type Props = {
  isOfferPriceDisplayEnabled?: boolean
  currency: string
  isSubmitting: boolean
  isBuyer?: boolean
}

const OfferSubmitButton = ({
  currency,
  isSubmitting,
  isOfferPriceDisplayEnabled = false,
  isBuyer,
}: Props) => {
  const translate = useTranslate('conversation.hero_message.make_offer')
  const trackAbTest = useTrackAbTestCallback()
  const offerRequestsWithoutDiscountLimitABTest = useAbTest('offers_without_discount_limit')
  const { locale } = useIntl()

  const offerValue = useWatch({ name: OfferFormField.Offer, disabled: !isOfferPriceDisplayEnabled })

  function renderTitle() {
    if (isOfferPriceDisplayEnabled) {
      return translate('offer_modal.actions.submit_with_price', {
        price: offerValue ? formatCurrency(offerValue, { locale, currency }) : '',
      })
    }

    return translate('offer_modal.actions.submit')
  }

  const handleSubmit = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    trackAbTest(offerRequestsWithoutDiscountLimitABTest, !!isBuyer)
    if (isSubmitting) {
      event.preventDefault()
    }
  }

  return (
    <Cell>
      <Button
        disabled={isSubmitting}
        isLoading={isSubmitting}
        type={Button.Type.Submit}
        styling={Button.Styling.Filled}
        testId="offer-submit-button"
        onClick={handleSubmit}
      >
        {renderTitle()}
      </Button>
    </Cell>
  )
}

export default OfferSubmitButton
