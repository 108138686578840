'use client'

import { useContext } from 'react'

import { OfferContext } from './OfferContext'

const useOfferContext = () => {
  const context = useContext(OfferContext)

  if (!context) {
    throw new Error('useOfferContext must be used within a OfferProvider')
  }

  return context
}

export default useOfferContext
