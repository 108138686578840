import { Reducer } from '@reduxjs/toolkit'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import { END } from 'redux-saga'

import { tracker } from '_libs/common/tracker'
import { serverSide } from '@marketplace-web/shared/environment'
import { coreReducers, staticReducers } from 'state/reducers'
import { getCoreSagas } from 'state/sagas'
import { configureStore, createReducers } from 'state/store'

const withHydrate =
  <R extends Reducer>(reducer: R) =>
  (state: Parameters<R>[0], action: Parameters<R>[1]): R => {
    if (action.type === HYDRATE) return action.payload

    return reducer(state, action)
  }

type Reducers = typeof staticReducers & typeof coreReducers
type InitialState = {
  [K in keyof Reducers]: Reducers[K] extends Reducer<infer S> ? Partial<S> : never
}

export const configureNextAppRouterStore = (initialState: Partial<InitialState>) => {
  // Eventually `businessAccountsReducer` should be a part of `coreReducers` but
  // in non-Next.js version of core, `businessAccounts` has a separate bundle and
  // the reducer is separated from `coreReducers`. It is only included with that
  // bundle.
  // Therefore, we need to combine them together here for `businessAccountsReducer`
  // to be available in Next.js context.

  const nextReducers = { ...coreReducers }
  const reducer = createReducers(nextReducers)

  const nextSagas = [...getCoreSagas()]

  const { store, sagaRun } = configureStore(initialState, reducer, nextSagas)

  // TODO: remove after `session_cookie_in_response` and `next_auth_refresh_token_interceptor` FS are scaled
  if (!serverSide) tracker.initialize(store)

  store.endSagas = () => {
    store.dispatch(END)

    return sagaRun.toPromise()
  }

  return store
}

export const configureNextStore = () => {
  // Eventually `businessAccountsReducer` should be a part of `coreReducers` but
  // in non-Next.js version of core, `businessAccounts` has a separate bundle and
  // the reducer is separated from `coreReducers`. It is only included with that
  // bundle.
  // Therefore, we need to combine them together here for `businessAccountsReducer`
  // to be available in Next.js context.

  const nextReducers = { ...coreReducers }
  const reducer = createReducers(nextReducers)
  const reducerWithHydration = withHydrate(reducer)

  const nextSagas = [...getCoreSagas()]

  const { store, sagaRun } = configureStore(undefined, reducerWithHydration, nextSagas)

  if (!serverSide) tracker.initialize(store)

  store.endSagas = () => {
    store.dispatch(END)

    return sagaRun.toPromise()
  }

  return store
}

export const wrapper = createWrapper(configureNextStore, {
  // TODO: this is a workaround for next.js erroring on detection of any explicit `undefined` values.
  // They do this to avoid edge cases due to such values not being properly serializable. Instead, we
  // remove "undefined" values altogether and avoid introducing unnecessary practices.
  // Implications to performance are not clear at this moment.
  // More on the issue: https://github.com/vercel/next.js/discussions/11209
  serializeState: state => JSON.stringify(state),
  deserializeState: state => JSON.parse(state),
})
