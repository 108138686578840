'use client'

import { useContext, useLayoutEffect, useRef, useState } from 'react'
import { Cell, Divider, Spacer, Text } from '@vinted/web-ui'

import { clickEvent } from '_libs/common/event-tracker/events'
import { LinkifiedMessage } from 'components/LinkifiedMessage'
import TranslationControl from 'components/TranslationControl'

import { Translation } from 'constants/translation'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { PluginsContext } from 'pages/Item/containers/PluginsProvider'

import { ItemPageDescriptionPluginModel } from './types'

const ItemPageDescriptionPlugin = ({
  itemId,
  description,
  localization,
  isLocalized,
}: ItemPageDescriptionPluginModel) => {
  const translate = useTranslate()
  const { track } = useTracking()

  const { updatePlugins } = useContext(PluginsContext)

  const contentRef = useRef<HTMLDivElement>(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [showMore, setShowMore] = useState(false)

  useLayoutEffect(() => {
    const element = contentRef.current

    if (element && element.scrollHeight > element.offsetHeight) {
      setIsCollapsed(true)
      setShowMore(true)
    } else {
      setIsCollapsed(false)
    }
  }, [contentRef, description])

  const handleShowMore = () => {
    setIsCollapsed(false)
    setShowMore(false)

    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.ReadMore,
      }),
    )
  }

  const handleDynamicTranslate = async (isTranslated: boolean) => {
    if (isTranslated) {
      await updatePlugins?.('translatable', { localize: false })
    } else {
      await updatePlugins?.('translatable', { localize: true })
    }
  }

  const descriptionClassName = isCollapsed ? 'description-collapsed' : undefined

  return (
    <>
      <Divider />
      <Spacer />
      <Cell styling={Cell.Styling.Tight}>
        <div className="u-text-wrap" itemProp="description">
          <div className={descriptionClassName} ref={contentRef}>
            <Text as="span" format>
              <LinkifiedMessage text={description} />
            </Text>
          </div>
          {showMore && (
            <button type="button" onClick={handleShowMore}>
              <Text
                as="span"
                clickable
                text={translate('item.description.actions.show_more')}
                type={Text.Type.Body}
              />
            </button>
          )}
        </div>
        <Spacer />
        {localization !== Translation.None && (
          <>
            <TranslationControl
              isTranslated={isLocalized}
              autotranslate={localization === Translation.Auto}
              onClick={handleDynamicTranslate}
              tracking={{
                target: ClickableElement.TranslateDescription,
                targetDetails: JSON.stringify({
                  item_id: itemId,
                }),
              }}
            />
            <Spacer />
          </>
        )}
      </Cell>
    </>
  )
}

export default ItemPageDescriptionPlugin
