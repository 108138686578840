'use client'

import { Card, Spacer } from '@vinted/web-ui'

import { isBundlingAvailable } from '_libs/utils/bundle'
import { useSession } from '@marketplace-web/shared/session'

import BundleEntryBanner from '../BundleEntryBanner'
import { ItemPageShopBundlesBlockPluginModel } from '../../types'

type Props = {
  data: ItemPageShopBundlesBlockPluginModel
}

const ItemPageShopBundlesBlockPlugin = ({ data }: Props) => {
  const currentUserId = useSession().user?.id

  const {
    canBundle,
    closetCountdownEndDate,
    itemId,
    itemUserId,
    userItemCount,
    isUserHated,
    isUserHatesYou,
    bundleDiscount,
    canBuyItem,
  } = data

  const isViewingOwnProfile = itemUserId === currentUserId

  const showPlugin = isBundlingAvailable({
    canBundle,
    totalItemCount: userItemCount,
    isCurrentUserAuthenticated: !!currentUserId,
    closetCountdownEndDate,
    hasHateFromAnySide: isUserHated || isUserHatesYou,
    isViewingOwnProfile,
  })

  if (!showPlugin) return null

  const renderContent = () => (
    <BundleEntryBanner
      itemId={itemId}
      canBuyItem={canBuyItem}
      itemUserId={itemUserId}
      bundleDiscount={bundleDiscount}
      testId="item-buy-bundle-banner"
    />
  )

  return (
    <>
      <Spacer size={Spacer.Size.Large} />
      <Card>
        <div className="u-overflow-hidden">{renderContent()}</div>
      </Card>
    </>
  )
}

export default ItemPageShopBundlesBlockPlugin
