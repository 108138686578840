import { useCallback } from 'react'

import { authenticateUser } from 'data/api/authentication/requests'
import { getFingerprint } from '@marketplace-web/domain/audit'
import useTranslate from 'hooks/useTranslate'
import { isBlockedByDataDome } from '@marketplace-web/domain/data-dome'
import { AuthenticateGrantType, AuthenticateProvider } from '@marketplace-web/shared/authentication'

import { isNetworkError, isTimeoutError } from '../../utils/errors'

const useSocialLogin = () => {
  const translate = useTranslate('auth.select_type')
  const errorsTranslate = useTranslate('errors')

  const loginWithSocial = useCallback(
    async (provider: AuthenticateProvider, token: string) => {
      const fingerprint = await getFingerprint()

      const response = await authenticateUser({
        grantType: AuthenticateGrantType.Assertion,
        assertion: token,
        provider,
        fingerprint,
      })

      if (isNetworkError(response)) {
        response.message = translate('errors.offline')
      }

      if (isTimeoutError(response)) {
        response.message = errorsTranslate('generic')
      }

      if (isBlockedByDataDome(response)) {
        response.message = translate('errors.datadome')
      }

      return response
    },
    [translate, errorsTranslate],
  )

  return loginWithSocial
}

export default useSocialLogin
