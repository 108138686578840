import { withServerSideProxy } from '@marketplace-web/shared/ssr'

export const { openBrowsingContext, redirectToPageInNewTab } = withServerSideProxy(
  {
    openBrowsingContext: (...args: Parameters<(typeof window)['open']>) => window.open(...args),
    redirectToPageInNewTab: (page: string) => {
      window.open(page, '_blank')
    },
  },
  { openBrowsingContext: () => null },
)
