'use client'

import { Container } from '@vinted/web-ui'

import { useSession } from '@marketplace-web/shared/session'
import useTranslate from 'hooks/useTranslate'

import type { FallbackComponentProps } from '../../../types/error'
import Error from '../../Error'
import StackTrace from '../../StackTrace'

const AppError = ({ error, errorInfo, handleRetry }: FallbackComponentProps) => {
  const { user } = useSession()
  const translate = useTranslate('error_page')

  const isGod = user?.is_god

  return (
    <Error
      action={translate('actions.retry')}
      body={translate('client_error.body')}
      title={translate('client_error.title')}
      onActionClick={handleRetry}
    >
      {isGod && error && (
        <Container>
          <StackTrace error={error} errorInfo={errorInfo} />
        </Container>
      )}
    </Error>
  )
}

export default AppError
