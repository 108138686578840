'use client'

import { useContext, useEffect, useRef } from 'react'
import axios from 'axios'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import useUserAgent from 'hooks/useUserAgent'

import { adBlockCheckEvent } from '_libs/common/event-tracker/events'
import { setSessionStorageItem } from '@marketplace-web/shared/browser'

import { useSession } from '@marketplace-web/shared/session'

import AdsContext from '../../containers/AdsProvider/AdsContext'

import {
  AdPlatform,
  AD_REQUEST_CHECK_URL,
  IS_AD_BLOCKER_USED_SESSION_KEY,
  AD_BLOCKER_VISITOR_KEY,
} from '../../constants'
import AdManager from '../../utils/ad-manager'

const AdvertisementBlockCheck = () => {
  const divRef = useRef<HTMLDivElement>(null)
  const { user, anonId } = useSession()

  const {
    isAdBlockerUsed,
    hasAdBlockerBeenTracked,
    adBlockerVisitorId,
    setIsAdBlockerUsed,
    setHasAdBlockerBeenTracked,
    setAdBlockerVisitorId,
  } = useContext(AdsContext)
  const visitorId = user?.id ? String(user.id) : anonId

  const { track } = useTracking()
  const userAgent = useUserAgent()

  useEffect(() => {
    if (!divRef.current || isAdBlockerUsed !== null) return

    // First check that can be done by checking if AdBlocker
    // loaded its own styles and hidden the `.ads-banner` element

    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    if (divRef.current.children[0]!?.clientHeight > 0) {
      // Then we check if ad request is failing (is blocked)
      // and if it is, then we assume that ad blocker is enabled

      axios
        .head(AD_REQUEST_CHECK_URL, {
          withCredentials: false,
        })
        .then(() => {
          setIsAdBlockerUsed(false)
        })
        .catch(() => {
          setIsAdBlockerUsed(true)
        })
    } else {
      setIsAdBlockerUsed(true)
    }
  }, [setIsAdBlockerUsed, isAdBlockerUsed])

  useEffect(() => {
    // If it has not been tracked (in this session)
    // and if the value actually is set

    if (!visitorId || hasAdBlockerBeenTracked || isAdBlockerUsed === null) return

    // Track the tracking event for ad block check

    track(
      adBlockCheckEvent({
        isMobileWeb: AdManager.getAdsPlatform(userAgent) === AdPlatform.Mobile,
        isAdBlockerUsed,
      }),
    )

    // Save the value in Redux that event has been tracked

    setHasAdBlockerBeenTracked(true)

    // Save the value of visitor id in Redux

    setAdBlockerVisitorId(visitorId)

    // Save to session storage that ad blocker is used
    setSessionStorageItem(IS_AD_BLOCKER_USED_SESSION_KEY, String(isAdBlockerUsed))

    // Save visitor id (user id or anon id) to identify
    // when user has logged in or out
    setSessionStorageItem(AD_BLOCKER_VISITOR_KEY, visitorId)
  }, [
    isAdBlockerUsed,
    hasAdBlockerBeenTracked,
    visitorId,
    userAgent,
    track,
    setHasAdBlockerBeenTracked,
    setAdBlockerVisitorId,
  ])

  useEffect(() => {
    // Reset hasAdBlockerBeenTracked value to `false`
    // after user logged in or logged out, to track
    // if particular user is actually using the adblocker

    if (!visitorId || !hasAdBlockerBeenTracked || visitorId === adBlockerVisitorId) return

    setHasAdBlockerBeenTracked(false)
    setAdBlockerVisitorId(visitorId)
  }, [
    hasAdBlockerBeenTracked,
    adBlockerVisitorId,
    visitorId,
    setHasAdBlockerBeenTracked,
    setAdBlockerVisitorId,
  ])

  return (
    <div
      ref={divRef}
      tabIndex={-1}
      suppressHydrationWarning
      // work around for ad-blcokers removing html content
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html:
          // We need a dummy div, however
          // we cannot set `display: none`
          // or `visibility: hidden` to make
          // it a valid check, because that's
          // what ad blocker uses to hide these
          // elements
          '<div class="ads-banner" data-testid="ads-banner" style="width:1px;height:1px;position:absolute;pointer-events:none;background:transparent;left:0;top:0"/>',
      }}
    />
  )
}

export default AdvertisementBlockCheck
