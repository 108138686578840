import { find, filter, intersection } from 'lodash'
import { createSelector } from 'reselect'

import { ConversationMessageType } from 'constants/conversation'
import { ApplicableTransactionAction } from 'constants/transaction'

import { stateName } from './constants'
import { AllState, Message } from './types'

const localState = (state: AllState) => state[stateName]

export const getMessageById = (state: AllState, id: number) => {
  const messages = filter(
    localState(state).conversation?.messages,
    message => message.entityType === ConversationMessageType.Message,
  ).map(message => message.message as Message)

  return find(messages, { id })
}

export const getUiState = createSelector(localState, ({ ui }) => ui)

export const getIsTranslated = (state: AllState) => localState(state).conversation?.isTranslated

/** @deprecated Use useConversationContext to access this value */
export const getConversation = (state: AllState) => localState(state).conversation

/** @deprecated Use useConversationContext to access this value */
export const getConversationUi = (state: AllState) => localState(state).ui.getConversation

/** @deprecated Use useConversationContext to access this value */
export const getTransaction = (state: AllState) => localState(state).transaction

/** @deprecated Use useConversationContext to access this value */
export const getConversationTransaction = (state: AllState) =>
  localState(state).conversation?.transaction

/** @deprecated Use useConversationContext to access this value */
export const getTransactionUiState = (state: AllState) =>
  localState(state).ui.getTransaction.uiState

/** @deprecated Use useConversationContext to access this value */
export const getTransactionId = (state: AllState) => localState(state).conversation?.transactionId

/** @deprecated Use useConversationContext to access this value */
export const getConversationId = (state: AllState) => localState(state).conversation?.id

export const getMessages = (state: AllState) => localState(state).conversation?.messages

export const getConversationOrderItemIds = (state: AllState) =>
  localState(state).conversation?.transaction?.order.itemIds

export const getSendMessageUi = (state: AllState) => localState(state).ui.sendMessage

export const getSendMessageUiState = createSelector(getSendMessageUi, ui => ui.uiState)

export const getSendMessageUiError = createSelector(getSendMessageUi, ui => ui.error)

export const getTransactionPrice = createSelector(
  getConversationTransaction,
  transaction => transaction?.offer.price,
)

export const getApplicableTransactionActions = createSelector(
  getConversationTransaction,
  transaction => {
    if (!transaction) return undefined

    return intersection(
      transaction.availableActions,
      Object.values(ApplicableTransactionAction),
    ) as Array<ApplicableTransactionAction>
  },
)

export const getTransactionStatus = createSelector(
  getConversationTransaction,
  transaction => transaction?.status,
)

/** @deprecated Use useConversationContext to access this value */
export const getRecipientId = (state: AllState) => localState(state).recipientId

export const getCreateOfferUi = createSelector(getUiState, ui => ui.createOffer)

/** @deprecated Use useOfferContext to access this value */
export const getOfferRequestOptions = (state: AllState) => localState(state).offerRequestOptions

/** @deprecated Use useOfferContext to access this value */
export const getSelectedOfferPriceSuggestionOption = (state: AllState) =>
  localState(state).selectedOfferPriceSuggestionOption

/** @deprecated Use useOfferContext to access this value */
export const getOfferRequestSessionId = (state: AllState) => localState(state).offerRequestSessionId

export const getTransactionUserSide = createSelector(
  getConversationTransaction,
  transaction => transaction?.userSide,
)

export const getShipmentStatus = createSelector(
  getConversationTransaction,
  transaction => transaction?.shipment?.status,
)

export const getIsHarassmentWarningModalOpen = (state: AllState) =>
  localState(state).ui.harassmentWarning.isModalOpen

export const getWasHarassmentWarningOpen = (state: AllState) =>
  localState(state).ui.harassmentWarning.wasModalOpen

export const getScoredHarassmentMessage = (state: AllState) =>
  localState(state).ui.harassmentWarning.scoredMessage

export const getScoredHarassmentMessageCorrelationId = (state: AllState) =>
  localState(state).ui.harassmentWarning.scoredHarassmentMessageCorrelationId

export const getIsEmailSharingModalVisible = (state: AllState) =>
  localState(state).ui.isEmailSharingModalVisible

export const getIsFirstTimeListerEducationModalVisible = (state: AllState) =>
  localState(state).ui.isFirstTimeListerEducationModalVisible

export const getIsConversationDeletionRestricted = (state: AllState) =>
  localState(state).conversation?.isDeletionRestricted
