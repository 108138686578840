import {
  AmazonTagFetchBidsConfig,
  BackBidsResponses,
  Bid,
  YieldbirdUnfilledRecoveryEventDetail,
} from 'types/ads'
import { RtbPlacementConfigModel } from 'types/models'

import { ADS_BIDDER_TIMEOUT, Bidder, BidderState } from '../../constants'

import { GoogleCallbacks, RequestManager, SetupAdPlacementProps } from './types'

export function getPlacementById(id: string) {
  return window.adPlacements?.[id]
}

export function getAdUnitPath({ dfpAccountId, dfpCode }: RtbPlacementConfigModel) {
  return `/${dfpAccountId}/${dfpCode}`
}

export function getAdUnitName({ shape, page, mediation }: RtbPlacementConfigModel) {
  const adUnitNameParts = ['ad', shape, page]

  if (mediation) {
    adUnitNameParts.push(mediation)
  }

  return adUnitNameParts.join('-')
}

export function setupGoogleEvents(googleSlot: googletag.Slot, callbacks: GoogleCallbacks) {
  const { googletag } = window

  if (!googletag) return

  Object.keys(callbacks).forEach(callbackKey => {
    const key = callbackKey as keyof googletag.events.EventTypeMap

    const callback = callbacks[key]

    if (!callback) return

    googletag.pubads().addEventListener(key, (event: googletag.events.Event) => {
      if (event.slot === googleSlot) callback(event)
    })
  })
}

export function setupAdPlacement({
  id,
  placementConfig,
  node,
  isRefreshEnabled = false,
  isManuallyRendered = false,
  isManuallyRefreshed = false,
  isOutOfPageAd = false,
}: SetupAdPlacementProps) {
  if (!window.adPlacements) {
    window.adPlacements = {}
  }

  const { adPlacements } = window
  const adUnitPath = getAdUnitPath(placementConfig)
  const adUnitName = getAdUnitName(placementConfig)

  adPlacements[id] = {
    code: id,
    adUnitPath,
    adUnitName,
    shape: placementConfig.shape,
    sizes: placementConfig.sizes,
    bids: placementConfig.bids,
    dfpCode: placementConfig.dfpCode,
    dfpAccountId: placementConfig.dfpAccountId,
    activeDuration: 0,
    isRefreshEnabled,
    isManuallyRendered,
    isManuallyRefreshed,
    isOutOfPageAd,
    node,
  }
}

export function handleUnfilledRecovery(event: Event) {
  const { detail } = event as CustomEvent<YieldbirdUnfilledRecoveryEventDetail>

  return (googleSlot: googletag.Slot, callbacks: GoogleCallbacks) => {
    const { slot, unfilledRecoveryAdUnit } = detail

    const googleSlotAdUnitPath = googleSlot.getAdUnitPath()

    const slotPathId = `${googleSlotAdUnitPath}#${googleSlot.getSlotElementId()}`

    if (slotPathId !== slot) return

    const yieldbirdRecoverySlot = googletag
      .pubads()
      .getSlots()
      .find(publicSlot => {
        return (
          publicSlot.getAdUnitPath() ===
          unfilledRecoveryAdUnit.substring(0, unfilledRecoveryAdUnit.indexOf('#'))
        )
      })

    if (!yieldbirdRecoverySlot) return

    setupGoogleEvents(yieldbirdRecoverySlot, callbacks)
  }
}

export function setupGoogleSlot(
  placementId: string,
  callbacks: GoogleCallbacks,
  iframeTitle?: string,
) {
  const placement = getPlacementById(placementId)
  const { googletag } = window

  if (!googletag || !placement) return

  const { adUnitPath, sizes } = placement

  googletag.cmd.push(() => {
    if (iframeTitle) googletag.setAdIframeTitle(iframeTitle)

    const slot = googletag.defineSlot(adUnitPath, sizes, placementId)

    if (!slot) return

    slot.addService(googletag.pubads())

    slot.setCollapseEmptyDiv(true)

    setupGoogleEvents(slot, callbacks)

    placement.googleSlot = slot

    window.addEventListener('unfilledRecovery', (event: Event) =>
      handleUnfilledRecovery(event)(slot, callbacks),
    )
  })
}

export function isBidderStateValid(requestManager: RequestManager, bidder: Bidder) {
  return requestManager[bidder] === BidderState.Fetched
}

export function sendAdserverRequest(placementId: string, requestManager: RequestManager) {
  const { googletag, pbjs, apstag } = window
  const placement = getPlacementById(placementId)

  if (!placement) return

  const { isManuallyRendered } = placement

  googletag.cmd.push(() => {
    const placementSlot = googletag
      .pubads()
      .getSlots()
      .find(slot => slot.getSlotElementId() === placementId)

    if (isBidderStateValid(requestManager, Bidder.Prebid))
      pbjs.setTargetingForGPTAsync([placementId])

    if (isBidderStateValid(requestManager, Bidder.Amazon)) apstag.setDisplayBids()

    if (isManuallyRendered) googletag.display(placementId)

    if (placementSlot && !isManuallyRendered) googletag.pubads().refresh([placementSlot])
  })
}

export function isArrayOfNumbers(candidate: unknown): candidate is Array<number | Array<number>> {
  return (
    !!candidate &&
    Array.isArray(candidate) &&
    !candidate.find(candidateItem => {
      return Array.isArray(candidateItem)
        ? !!candidateItem.find(candidateItemItem =>
            Number.isNaN(Number.parseInt(candidateItemItem, 10)),
          )
        : Number.isNaN(Number.parseInt(candidateItem, 10))
    })
  )
}

export function fetchAmazonBids(
  placementId: string,
  onBidderBack: (bidderName: Bidder.Amazon, placementId: string, state: BidderState) => void,
  isRefresh = false,
) {
  const placement = getPlacementById(placementId)
  const { apstag } = window
  let hasTimedOut = false

  if (!apstag || !placement) return

  const { sizes, dfpCode } = placement

  if (sizes === 'fluid' || !isArrayOfNumbers(sizes)) {
    onBidderBack(Bidder.Amazon, placementId, BidderState.BadSizes)

    return
  }

  const config: AmazonTagFetchBidsConfig = {
    slots: [
      {
        slotID: placementId,
        slotName: dfpCode,
        sizes,
      },
    ],
    params: {
      adRefresh: isRefresh ? '1' : '0',
    },
  }

  const timeout = setTimeout(() => {
    hasTimedOut = true

    onBidderBack(Bidder.Amazon, placementId, BidderState.TimedOut)
  }, ADS_BIDDER_TIMEOUT)

  apstag.fetchBids(config, () => {
    if (hasTimedOut) return

    clearTimeout(timeout)
    onBidderBack(Bidder.Amazon, placementId, BidderState.Fetched)
  })
}

export function fetchPrebidBids(
  placementId: string,
  onBidderBack: (bidderName: Bidder.Prebid, placementId: string, state: BidderState) => void,
  isRefresh = false,
) {
  const placement = getPlacementById(placementId)
  const { pbjs } = window

  if (!pbjs || !placement) return

  if (!placement?.bids?.length) {
    onBidderBack(Bidder.Prebid, placementId, BidderState.NoBids)

    return
  }

  const { sizes, bids, adUnitName, adUnitPath, googleSlot } = placement
  const adagioBidParams = bids.find(bid => bid.bidder === 'adagio')?.params

  const prebidPlacement = {
    code: placementId,
    mediaTypes: {
      banner: {
        sizes,
      },
    },
    bids: bids.reduce<Array<Bid>>((acc, bid) => {
      const stringifiedBid = JSON.stringify(bid)
      const stringifiedBids = JSON.stringify(acc)

      if (stringifiedBids.includes(stringifiedBid)) return acc

      return [...acc, bid]
    }, []),
    ortb2Imp: adagioBidParams
      ? {
          ext: {
            data: {
              ...adagioBidParams,
            },
          },
        }
      : undefined,
    pubstack: {
      adUnitPath,
      adUnitName,
    },
  }

  pbjs.que.push(() => {
    if (!isRefresh) pbjs.addAdUnits(prebidPlacement)

    pbjs.requestBids({
      adUnitCodes: [placementId],
      bidsBackHandler(_bids: BackBidsResponses, timedOut: boolean) {
        if (isRefresh) googleSlot?.setTargeting('ad_refresh', 'true')

        return onBidderBack(
          Bidder.Prebid,
          placementId,
          timedOut ? BidderState.TimedOut : BidderState.Fetched,
        )
      },
      timeout: ADS_BIDDER_TIMEOUT,
    })
  })
}
