'use client'

import { Spacer } from '@vinted/web-ui'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useCompanyWeb from 'hooks/useCompanyWeb'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'
import {
  ACCESSIBILITY_URL,
  ADVERTISING_URL,
  APP_LANDING_URL,
  BUSINESS_ACCOUNTS_GUIDE_URL,
  BUSINESS_ACCOUNTS_HOMEPAGE,
  HELP_CENTER_URL,
  HOW_IT_WORKS_URL,
  INFOBOARD_URL,
  ITEM_VERIFICATION_URL,
  JOBS_URL,
  JOBS_URL_NEW,
  MAIN_NAVIGATION_ABOUT_TAB_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'

import { Screen } from 'constants/tracking/screens'

import LinkCell from '../LinkCell'
import LinksGroup from '../LinksGroup'

type Props = {
  isBusinessAccountLinksVisible: boolean
}

const FirstColumn = ({ isBusinessAccountLinksVisible }: Props) => {
  const translate = useTranslate('header.main_navigation.about')
  const { track } = useTracking()
  const {
    isPressMenuEnabled,
    isSustainabilityMenuEnabled,
    newsroomUrl,
    sustainabilityLandingPageUrl,
  } = useCompanyWeb()

  const isItemVerificationPageEnabled = useFeatureSwitch('web_item_verification_page_link')
  const isJobsPageEnabled = useFeatureSwitch('jobs_page')
  const isNewJobsUrlEnabled = useFeatureSwitch('new_jobs_career_portal')
  const isAccesibilityLandingPageEnabled = useFeatureSwitch('web_portal_accessibility_landing_page')

  const handleSustainabilityClick = () => {
    track(
      clickEvent({
        target: ClickableElement.SustainabilityPageLink,
        targetDetails: 'navigation_web',
      }),
    )
  }

  const handleHelpCenterClick = () => {
    track(
      clickEvent({
        target: ClickableElement.HelpCenter,
        screen: Screen.AboutMenu,
      }),
    )
  }

  const renderDiscoverBlock = () => (
    <LinksGroup title={translate('discover.title')}>
      <LinkCell title={translate('discover.items.how_it_works')} url={HOW_IT_WORKS_URL} />
      {isItemVerificationPageEnabled && (
        <LinkCell
          title={translate('discover.items.item_verification')}
          url={ITEM_VERIFICATION_URL}
        />
      )}
      <LinkCell title={translate('discover.items.app')} url={APP_LANDING_URL} />
      <LinkCell
        title={translate('discover.items.help')}
        url={HELP_CENTER_URL}
        onClick={handleHelpCenterClick}
      />
      <LinkCell title={translate('discover.items.infoboard')} url={INFOBOARD_URL} />
      {isBusinessAccountLinksVisible && (
        <>
          <LinkCell title={translate('discover.items.pro')} url={BUSINESS_ACCOUNTS_HOMEPAGE} />
          <LinkCell
            title={translate('discover.items.pro_guide')}
            url={BUSINESS_ACCOUNTS_GUIDE_URL}
          />
        </>
      )}
    </LinksGroup>
  )

  const renderCompanyBlock = () => {
    const jobsUrl = isNewJobsUrlEnabled ? JOBS_URL_NEW : JOBS_URL

    return (
      <LinksGroup title={translate('company.title')}>
        <LinkCell title={translate('company.items.about')} url={MAIN_NAVIGATION_ABOUT_TAB_URL} />
        {isJobsPageEnabled && (
          <LinkCell
            testId="jobs-link-header"
            title={translate('company.items.jobs')}
            url={jobsUrl}
          />
        )}
        {isSustainabilityMenuEnabled && (
          <LinkCell
            title={translate('company.items.sustainability')}
            url={sustainabilityLandingPageUrl}
            onClick={handleSustainabilityClick}
          />
        )}
        {isPressMenuEnabled && (
          <LinkCell
            title={translate('company.items.press')}
            url={newsroomUrl}
            testId="press-link"
          />
        )}
        <LinkCell title={translate('company.items.advertising')} url={ADVERTISING_URL} />
        {isAccesibilityLandingPageEnabled && (
          <LinkCell
            title={translate('company.items.accessibility')}
            url={ACCESSIBILITY_URL}
            testId="accessibility-link-header"
          />
        )}
      </LinksGroup>
    )
  }

  return (
    <div>
      {renderDiscoverBlock()}
      <Spacer size={Spacer.Size.X2Large} />
      {renderCompanyBlock()}
    </div>
  )
}

export default FirstColumn
