'use client'

import { useFormContext } from 'react-hook-form'
import { noop } from 'lodash'

import { Text } from '@vinted/web-ui'

import { SelectableInputDropdown } from 'components/InputDropdown'
import useTranslate from 'hooks/useTranslate'

import { Field } from '../../../constants'
import { FormModel } from '../../../types'

type Props = {
  name: Field
  testId?: string
  onSave?: () => void
} & Pick<
  ComponentProps<typeof SelectableInputDropdown>,
  'inputTitle' | 'placeholder' | 'required'
> & {
    data: Array<{
      id: string
      title: string
    }>
  }

const AdvertisingSelect = ({
  name,
  onSave = noop,
  data,
  required,
  inputTitle,
  placeholder,
  testId,
}: Props) => {
  const translate = useTranslate('advertising.form.validation')
  const { register, watch, setValue, formState } = useFormContext<FormModel>()

  const selectedId = watch(name)

  const { name: registeredName } = register(name, { required })

  return (
    <SelectableInputDropdown
      name={registeredName}
      data={data}
      inputTitle={inputTitle}
      placeholder={placeholder}
      selectedValue={data.find(({ id }) => id === selectedId)}
      closeOnDropdownBlur
      testId={testId}
      onSave={value => {
        setValue(name, value ? String(value.id) : undefined, { shouldValidate: true })
        onSave()
      }}
      aria={{
        'aria-invalid': !!formState.errors[name],
        'aria-errormessage': `${name}-error`,
        'aria-required': required,
      }}
      validation={
        formState.errors[name] && (
          <Text type={Text.Type.Caption} theme="warning" as="p" id={`${name}-error`}>
            {translate('required')}
          </Text>
        )
      }
    />
  )
}

export default AdvertisingSelect
