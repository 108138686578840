import { serverSide } from '@marketplace-web/shared/environment'

import { CookieHandler } from './types'

import ClientCookieManager from './cookie-manager-client'
import SSRCookieManager from './cookie-manager-ssr'

const createCookieManager = (cookies?: Record<string, string | undefined>): CookieHandler => {
  return serverSide ? new SSRCookieManager(cookies) : new ClientCookieManager()
}

export { createCookieManager }
