import { useCallback } from 'react'

import { abTestExposeEvent } from '_libs/common/event-tracker/events'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { AbTestDto } from 'types/dtos'

import { ShouldTrackExposeCallback } from '../types'

import useExposee from './useExposee'

const useTrackAbTestCallback = () => {
  const { track } = useTracking()
  const exposee = useExposee()

  return useCallback(
    (abTest: AbTestDto | undefined, shouldTrack: boolean | ShouldTrackExposeCallback = true) => {
      if (!abTest) return

      if (typeof shouldTrack === 'function') {
        if (!shouldTrack(abTest, exposee)) return
      } else if (!shouldTrack) return

      track(abTestExposeEvent({ ...exposee, ...abTest }))
    },
    [exposee, track],
  )
}

export default useTrackAbTestCallback
