'use client'

import { createContext } from 'react'

import { PageId } from 'types/page-id'

export type RequestContextType = {
  pageId: PageId | null
  userAgent: string
  location: {
    url: string
    serverUrl: string
    urlParams: Record<string, string | Array<string> | undefined>
    back: () => void
    push: (url: string) => void
    routerPush: (url: string) => void
    replace: (url: string) => void
    refreshUrl: () => void
  }
}

const RequestContext = createContext<RequestContextType>(null as any)

export default RequestContext
