'use client'

import { Cell, Spacer } from '@vinted/web-ui'

import SellerBadge from 'components/SellerBadge'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import useTranslate from 'hooks/useTranslate'

import { ItemPageSellerBadgesInfoPluginModel } from './types'

const ItemPageSellerBadgesInfoPlugin = ({ badges }: ItemPageSellerBadgesInfoPluginModel) => {
  const translate = useTranslate('user.seller_badges.a11y')

  if (!badges.length) return null

  const renderItems = () =>
    badges.map((badge, index) => (
      <div className="u-flex-1" key={index}>
        <SellerBadge type={badge.type} />
      </div>
    ))

  const renderPhonesAndDesktops = () => (
    <SeparatedList
      separator={<Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Large} />}
    >
      {renderItems()}
    </SeparatedList>
  )

  const renderContent = () => (
    <>
      <div className="u-phones-only">{renderPhonesAndDesktops()}</div>

      <div className="u-tablets-only u-flexbox">{renderItems()}</div>

      <div className="u-desktops-only">{renderPhonesAndDesktops()}</div>
    </>
  )

  return <Cell aria={{ 'aria-label': translate('group_title') }}>{renderContent()}</Cell>
}

export default ItemPageSellerBadgesInfoPlugin
