import { AxiosResponse } from 'axios'
import { get } from 'lodash'

import { AxiosErrorResponseData, DataDomeError, Response, ResponseError } from 'types/api'

export const isAxiosErrorBlockedByDataDome = <
  T extends AxiosResponse<AxiosErrorResponseData<unknown>>,
>(
  headers: T['headers'],
  data: T['data'],
): data is DataDomeError => !!headers['x-dd-b'] && 'url' in data

export const isBlockedByDataDome = (response: Response<unknown> | ResponseError<unknown>) => {
  if (!('errors' in response)) return false
  if (response.status !== 403) return false

  return !!get(response, 'exception.response.headers.x-dd-b')
}
