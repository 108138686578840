'use client'

import { Divider, Label } from '@vinted/web-ui'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import {
  BUSINESS_TERMS_AND_CONDITIONS_URL,
  BUSINESS_TERMS_OF_SALE_URL,
  BUSINESS_TERMS_URL,
  COOKIE_POLICY_URL,
  HELP_SAFETY_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from 'constants/routes'
import useTranslate from 'hooks/useTranslate'

import OtherLink from '../OtherLink'

type Props = {
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
}

const Policies = ({ impressumUrl, isBusinessAccountLinksVisible }: Props) => {
  const translate = useTranslate('header.main_navigation.about.policies')

  const isProTermsAndConditionsFSEnabled = useFeatureSwitch('pro_terms_and_conditions_enabled')

  const renderBusinessAccountLinks = () => {
    if (!isBusinessAccountLinksVisible) return null

    if (isProTermsAndConditionsFSEnabled) {
      return (
        <OtherLink
          title={translate('items.pro_terms_and_conditions')}
          url={BUSINESS_TERMS_AND_CONDITIONS_URL}
        />
      )
    }

    return (
      <>
        <OtherLink title={translate('items.pro_terms_of_sale')} url={BUSINESS_TERMS_OF_SALE_URL} />
        <OtherLink title={translate('items.pro_terms_of_use')} url={BUSINESS_TERMS_URL} />
      </>
    )
  }

  return (
    <>
      <Label styling={Label.Styling.Wide} text={translate('title')} />
      <SeparatedList separator={<Divider />}>
        <OtherLink title={translate('items.safety')} url={HELP_SAFETY_URL} />
        {impressumUrl && <OtherLink title={translate('items.impressum')} url={impressumUrl} />}
        <OtherLink title={translate('items.privacy_policy')} url={PRIVACY_POLICY_URL} />
        <OtherLink title={translate('items.terms_and_conditions')} url={TERMS_URL} />
        <OtherLink title={translate('items.cookie_policy')} url={COOKIE_POLICY_URL} />
        {renderBusinessAccountLinks()}
      </SeparatedList>
    </>
  )
}

export default Policies
