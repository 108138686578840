'use client'

import { Spacer, Cell } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'
import {
  CheckCircle16,
  Clock16,
  Envelope16,
  LocationPin16,
  Umbrella16,
} from '@vinted/monochrome-icons'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useDate from 'hooks/useDate'

import { useSession } from '@marketplace-web/shared/session'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import UserInfoLine from 'components/ItemUserInfo/UserInfoLine'
import { getUserLocationDescription } from 'components/ItemUserInfo/utils'

import { viewEvent } from '_libs/common/event-tracker/events'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import { ItemPageUserInfoDetailsPluginDto } from './types'

type Props = {
  data: ItemPageUserInfoDetailsPluginDto
}

const ItemPageUserInfoDetailsPlugin = ({ data }: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()
  const { formatRelativeTime } = useDate()
  const { screen } = useSession()
  const isUKCompanyNumberHidden = useFeatureSwitch('hide_uk_sole_traders_company_number')

  const { user } = data
  if (!user) return null

  const renderBusinessEmail = () => {
    if (!user) return null

    const isBusinessAccount = user.business
    const businessEmail = isBusinessAccount && user.business_account?.email

    const hidePersonalInfo =
      isBusinessAccount && user.business_account?.entity_type === 'sole_trader'

    if (!businessEmail) return null

    if (businessEmail && hidePersonalInfo) {
      return (
        <UserInfoLine iconName={Envelope16} truncate>
          {businessEmail}
        </UserInfoLine>
      )
    }

    return (
      <UserInfoLine iconName={Envelope16} truncate>
        <a href={`mailto:${businessEmail}`} title={businessEmail} data-testid="seller-email-link">
          {businessEmail}
        </a>
      </UserInfoLine>
    )
  }

  if (!user) return null

  const isBusinessAccount = user.business
  const businessLegalCode = isBusinessAccount && user.business_account?.legal_code
  const hideBusinessLegalCode =
    isUKCompanyNumberHidden &&
    isBusinessAccount &&
    user.business_account?.entity_type === 'sole_trader' &&
    user.business_account?.country === 'UK'

  const handleLastSeenView = (inView: boolean) => {
    if (!inView) return

    track(
      viewEvent({
        screen,
        target: ViewableElement.UserLastSeen,
      }),
    )
  }

  return (
    <Cell>
      <SeparatedList separator={<Spacer size={Spacer.Size.Small} />}>
        <UserInfoLine iconName={LocationPin16}>{getUserLocationDescription(user)}</UserInfoLine>
        {renderBusinessEmail()}
        {businessLegalCode && !hideBusinessLegalCode && (
          <UserInfoLine iconName={CheckCircle16}>
            <span data-testid="seller-legal-code">
              {`${translate('user.verification.legal_code', {
                count: user.msg_template_count,
              })} ${businessLegalCode}`}
            </span>
          </UserInfoLine>
        )}
        {user.is_on_holiday && (
          <UserInfoLine iconName={Umbrella16}>
            <span>{translate('user.on_holiday')}</span>
          </UserInfoLine>
        )}
        {user.last_loged_on_ts && (
          <InView onChange={handleLastSeenView}>
            <UserInfoLine iconName={Clock16}>
              <span suppressHydrationWarning>
                {`${translate('user.last_seen')} ${formatRelativeTime(user.last_loged_on_ts)}`}
              </span>
            </UserInfoLine>
          </InView>
        )}
      </SeparatedList>
    </Cell>
  )
}

export default ItemPageUserInfoDetailsPlugin
