'use client'

import { getItemStatus } from 'data/utils/item'
import { useSession } from '@marketplace-web/shared/session'

import ItemStatus from './ItemStatus'
import { ItemPageItemStatusPluginModel } from './types'

type Props = {
  data: ItemPageItemStatusPluginModel | undefined
}

const ItemPageItemStatusPlugin = ({ data }: Props) => {
  const currentUserId = useSession().user?.id

  const isViewingSelf = currentUserId === data?.userId

  if (!data || !getItemStatus(data, isViewingSelf)) return null

  return <ItemStatus data={data} />
}

export default ItemPageItemStatusPlugin
