'use client'

import { useEffect } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { viewScreenEvent } from '_libs/common/event-tracker/events'
import { useSession } from '@marketplace-web/shared/session'

type Props = {
  children: JSX.Element
}

const TrackScreen = ({ children }: Props) => {
  const { track } = useTracking()
  const { screen } = useSession()

  useEffect(() => {
    track(viewScreenEvent({ screen }))
  }, [screen, track])

  return children
}

export default TrackScreen
