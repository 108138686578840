'use client'

import ItemCountdownBanner from 'components/ItemCountdownAction/ItemCountdownBanner'
import { useCanTransact } from 'pages/Item/hooks'
import useClosetCountdown from 'hooks/useClosetCountdown'
import { useSession } from '@marketplace-web/shared/session'

import { ItemPageClosetCountdownActionPluginDto } from './types'

type Props = {
  data: ItemPageClosetCountdownActionPluginDto
}

const ItemPageClosetCountdownActionPlugin = ({ data }: Props) => {
  const {
    userId,
    ownerLogin,
    isFollowed,
    endDate,
    isHatePresent,
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
  } = data

  const canTransact = useCanTransact({
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate: endDate,
  })
  const currentUserId = useSession().user?.id
  const { isCountdownActive } = useClosetCountdown(endDate)

  const isViewingOwnItem = userId === currentUserId
  const shouldShowCountdownBanner = isViewingOwnItem && isCountdownActive

  if (shouldShowCountdownBanner || !canTransact) {
    return (
      <ItemCountdownBanner
        userId={userId}
        ownerLogin={ownerLogin}
        isFollowed={isFollowed}
        isHatePresent={isHatePresent}
        endDate={endDate}
      />
    )
  }

  return null
}

export default ItemPageClosetCountdownActionPlugin
