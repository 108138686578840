'use client'

import { createContext } from 'react'
import { noop } from 'lodash'

export enum PromoAfterItemUpload {
  Empty = 'empty',
  ListAnotherItem = 'list_another_item',
  PushUpValueProposition = 'push_up_value_proposition',
  BumpCheckout = 'bump_checkout',
  ItemUploadFeedback = 'item_upload_feedback',
  SellerOfflineVerification = 'seller_offline_verification',
}

export type PromotionsType = {
  bumped: boolean
  feedback: boolean
  uploadAnotherItemTip: boolean
}

export type PromoAfterItemUploadContextType = {
  uploadedItemId: number | null
  currentPromotion: PromoAfterItemUpload | null
  setNextPromotion: () => void
  promotions: {
    bumped: boolean
    feedback: boolean
    uploadAnotherItemTip: boolean
  }
  setCurrentPromotion: (promo: PromoAfterItemUpload) => void
}

export const initialPromotionsValues = {
  bumped: false,
  feedback: false,
  uploadAnotherItemTip: false,
}

export const initialValues: PromoAfterItemUploadContextType = {
  uploadedItemId: null,
  currentPromotion: null,
  setNextPromotion: noop,
  promotions: initialPromotionsValues,
  setCurrentPromotion: noop,
}

const PromoAfterItemUploadContext = createContext<PromoAfterItemUploadContextType>(initialValues)

export default PromoAfterItemUploadContext
