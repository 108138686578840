import clientSideMetrics from '_libs/common/client-side-metrics'
import { logError } from '@marketplace-web/shared/logging'

import { ComponentLocation, ErrorReason } from './constants'
import { AuthExternalRegisterView, AuthView } from '../constants'

export const handlePageLoadFailure = ({
  authView,
  componentLocation,
  reason,
  error,
}: {
  authView: AuthView | AuthExternalRegisterView
  componentLocation: ComponentLocation
  reason: ErrorReason
  error?: Error | null
}) => {
  const feature = `${componentLocation}_load_failure`

  clientSideMetrics.counter(feature, { auth_view: authView, reason }).increment()

  if (!(error instanceof Error)) return

  logError(error, { feature, extra: JSON.stringify({ auth_view: authView, reason }) })
}

export const incrementPageLoadInitiatedCounter = ({
  authView,
  componentLocation,
}: {
  componentLocation: ComponentLocation
  authView: AuthView | AuthExternalRegisterView
}) => {
  clientSideMetrics
    .counter(`${componentLocation}_load_initiated`, { auth_view: authView })
    .increment()
}
