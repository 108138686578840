'use client'

import { Cell, Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

const FormHeader = () => {
  const translate = useTranslate('advertising.form')

  return (
    <Cell styling={Cell.Styling.Wide}>
      <Text text={translate('title')} type={Text.Type.Heading} as="h2" />
      <Spacer size={Spacer.Size.Large} />
      <Text as="span" text={translate('subtitle')} theme="muted" />
    </Cell>
  )
}

export default FormHeader
