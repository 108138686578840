'use client'

import { ReactNode, useContext } from 'react'

import { ToggleableContext } from '../ToggleableContext'

type ChildrenProps = {
  isVisible: boolean
  close: () => void
}

type Props = {
  children: ({ isVisible, close }: ChildrenProps) => ReactNode
}

const Menu = ({ children }: Props) => {
  const { isVisible, close } = useContext(ToggleableContext)

  return children({ isVisible, close })
}

export default Menu
