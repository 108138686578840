import { takeEvery } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import { MEMBER_PROFILE_URL, ROOT_URL, SELLER_INSIGHTS_URL } from 'constants/routes'
import { ItemAfterUploadActions, PromotionStorageKeys } from 'constants/item-upload'
import * as api from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { navigateToPage, scrollToElementById } from '_libs/utils/window'
import { tracker } from '_libs/common/tracker'
import { itemUploadSubmitFail, itemUploadSubmitSuccess } from '_libs/common/event-tracker/events'

import {
  CompleteItemArgs,
  CreateItemArgs,
  CreateItemDraftArgs,
  ResponseError,
  SubmitDraftResp,
  SubmitItemResp,
  UpdateItemArgs,
  UpdateItemDraftArgs,
} from 'types/api'
import { GtmTrackableItemDto } from 'types/dtos'
import { getFieldErrors } from 'pages/ItemUpload/utils/errors'
import { SELLER_INSIGHTS_TAB_NAME } from 'pages/Profile/SellerInsights/constants'

import * as statelessActions from './actions'
import * as transformers from './transformers'
import { ItemUploadFailReason, ItemStatus } from './constants'
import { setItemIdToStorage, setPromotionsAfterItemUploadToStorage } from './helpers'

export function* submitItem({ meta, payload }: ReturnType<typeof statelessActions.submitItem>) {
  const {
    user,
    tempUuid,
    itemStatus,
    screenName,
    trackListingToGoogleTagManager,
    trackListingToBraze,
    setFieldErrors,
    showEVSModal,
    showIVSModal,
    assignedPhotos,
    enableForm,
    disableForm,
    dynamicAttributes,
    attributes,
    origin,
    currency,
    feedbackId,
  } = payload

  yield call(disableForm)

  let response: SubmitItemResp | SubmitDraftResp | ResponseError

  const itemDto = yield* call(
    transformers.transformItemAttributesToItemUploadDto,
    assignedPhotos,
    dynamicAttributes,
    currency,
    attributes,
    tempUuid,
  )

  const { isItemBumped, saveAsDraft } = meta

  if (saveAsDraft) {
    if (itemDto.id) {
      const updateItemDraftArgs: UpdateItemDraftArgs = {
        draft: itemDto,
        feedbackId,
      }

      response = yield* call(api.updateItemDraft, updateItemDraftArgs)
    } else {
      const createItemDraftArgs: CreateItemDraftArgs = {
        draft: itemDto,
        feedbackId,
      }

      response = yield* call(api.createItemDraft, createItemDraftArgs)
    }
  } else if (itemStatus === ItemStatus.DraftEdit && itemDto.id) {
    const completeItemArgs: CompleteItemArgs = {
      draft: itemDto,
      feedbackId,
      isItemBumped,
    }

    response = yield* call(api.completeItem, completeItemArgs)

    if (
      'item' in response &&
      response.after_upload_actions?.includes(ItemAfterUploadActions.ShowBumpsCheckout)
    ) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowBumped)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  } else if (itemDto.id) {
    const updateItemArgs: UpdateItemArgs = {
      item: itemDto,
      feedbackId,
      isItemBumped,
    }

    response = yield* call(api.updateItem, updateItemArgs)

    if (
      'item' in response &&
      response.after_upload_actions?.includes(ItemAfterUploadActions.ShowBumpsCheckout)
    ) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowBumped)
    }
  } else {
    const createItemArgs: CreateItemArgs = {
      item: itemDto,
      feedbackId,
      isItemBumped,
    }

    response = yield* call(api.createItem, createItemArgs)

    if (
      'item' in response &&
      response.after_upload_actions?.includes(ItemAfterUploadActions.ShowBumpsCheckout)
    ) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowBumped)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  }

  if (!feedbackId) {
    setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowFeedback)
  }

  if (!response) {
    yield call(enableForm)

    return
  }

  if ('errors' in response) {
    yield call(setFieldErrors, getFieldErrors(response.errors))
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    yield* call(scrollToElementById, response.errors[0]!?.field)
    yield call(enableForm)

    tracker.track(
      itemUploadSubmitFail({
        reason: ItemUploadFailReason.ValidationError,
        validationErrors: response.errors.map(error => error.value),
        screen: screenName,
        tempUuid,
      }),
    )

    switch (response.code) {
      case ResponseCode.IncompleteTaxAddress:
        yield call(payload.openMissingPostalCodeModal)
        break
      case ResponseCode.PhotoMinimumCountRequired:
        yield call(payload.openLuxuryItemModal)
        break
      default:
        break
    }

    return
  }

  let redirectUrl = ROOT_URL
  let itemId = itemDto.id

  if ('item' in response) {
    const { item } = response
    const isItemEdit = itemStatus === ItemStatus.Edit
    const isNewListing = !saveAsDraft && !isItemEdit

    if (isNewListing) {
      const gtmTrackableItem: GtmTrackableItemDto = {
        id: item.id,
        catalog_id: attributes.catalogId,
        currency,
        price: { amount: attributes.price?.toString() || '', currency_code: currency },
        title: attributes.title,
        user: { email: user.email, external_id: user.externalId, id: user.id },
      }

      yield call(trackListingToGoogleTagManager, gtmTrackableItem)
      yield call(trackListingToBraze, { itemId: item.id, userExternalId: user.externalId })
    }

    redirectUrl = MEMBER_PROFILE_URL(user.id)
    itemId = item.id
  } else if ('draft' in response) {
    redirectUrl = MEMBER_PROFILE_URL(user.id)
    itemId = response.draft.id
  }

  redirectUrl = origin === SELLER_INSIGHTS_TAB_NAME ? SELLER_INSIGHTS_URL(user.id) : redirectUrl

  tracker.track(
    itemUploadSubmitSuccess({
      itemId: itemId || undefined,
      tempUuid,
      screen: screenName,
    }),
  )

  if (response.after_upload_actions?.includes(ItemAfterUploadActions.ShowIVSModal)) {
    if (itemId) setItemIdToStorage(itemId)
    yield* call(showIVSModal)

    return
  }

  if (response.after_upload_actions?.includes(ItemAfterUploadActions.ShowEVSModal)) {
    if (itemId) setItemIdToStorage(itemId)
    yield* call(showEVSModal)

    return
  }

  if (itemId) setItemIdToStorage(itemId)

  yield* call(navigateToPage, redirectUrl)
}

export default function* saga() {
  yield takeEvery(statelessActions.submitItem, submitItem)
}
