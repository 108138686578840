'use client'

import { Umbrella24 } from '@vinted/monochrome-icons'
import { Button, Card, Cell, Icon } from '@vinted/web-ui'
import { useState } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { disableUserHoliday } from 'data/api'
import { useSession } from '@marketplace-web/shared/session'
import useTranslate from 'hooks/useTranslate'
import { reloadPage } from '_libs/utils/window'

// TODO: Icon should be confirmed or replaced before scaling
const VacationNotification = () => {
  const { user } = useSession()
  const isPortalClosed = useFeatureSwitch('portal_closure_stage_3')

  const [isLoading, setIsLoading] = useState(false)
  const translate = useTranslate('vacation_notification')

  async function handleClick(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (isLoading) {
      event.preventDefault()

      return
    }

    if (!user) return

    setIsLoading(true)
    await disableUserHoliday(user.id)

    reloadPage()
  }

  if (isPortalClosed) return null
  if (!user?.is_on_holiday) return null

  return (
    <div className="u-ui-margin-vertical-x-large">
      <Card>
        <div className="u-overflow-hidden">
          <Cell
            styling={Cell.Styling.Wide}
            prefix={<Icon name={Umbrella24} color={Icon.Color.GreyscaleLevel2} />}
            body={translate('title')}
            suffix={
              <Button
                text={translate('actions.end')}
                onClick={handleClick}
                styling={Button.Styling.Filled}
                size={Button.Size.Medium}
                isLoading={isLoading}
                disabled={isLoading}
              />
            }
            testId="vacation-notification"
          />
        </div>
      </Card>
    </div>
  )
}

export default VacationNotification
