import { useContext } from 'react'

import useClosetCountdown from 'hooks/useClosetCountdown'
import { useSession } from '@marketplace-web/shared/session'

import { ShippingDetailsContext } from '../containers/ShippingDetailsProvider'

export const useCanTransact = ({
  canBuy,
  canInstantBuy,
  isReserved,
  isHidden,
  reservedForUserId,
  closetCountdownEndDate,
}) => {
  const { shippingDetails } = useContext(ShippingDetailsContext)
  const { isCountdownActive } = useClosetCountdown(closetCountdownEndDate)
  const currentUserId = useSession().user?.id

  const isItemReservedForBuyer = reservedForUserId === currentUserId
  const canTransact =
    canBuy &&
    canInstantBuy &&
    (!isReserved || isItemReservedForBuyer) &&
    !isHidden &&
    !!shippingDetails &&
    !isCountdownActive

  return canTransact
}
