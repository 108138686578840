export enum VerificationType {
  BankAccount = 'bank_account',
  Checkout = 'checkout',
  Login = 'login',
  Phone = 'phone',
  EntityHash = 'EntityHash',
}

export enum EmailCodeView {
  EnterCode = 'enterCode',
  NotReceiveEmail = 'notReceiveEmail',
}
