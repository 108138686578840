'use client'

import Script from 'next/script'
import { useContext, useEffect, useMemo } from 'react'

import { CDN_ADS_URL } from 'constants/index'

import useConsent from 'hooks/useConsent'

import AdsContext from '../../../../containers/AdsProvider/AdsContext'
import { AdKind } from '../../../../constants'

import { initPrebidSetup, setPrebidConsentManagement } from './utils'

const Prebid = () => {
  const { isCookieConsentVersion } = useConsent()
  const { placements: adsPlacements } = useContext(AdsContext)

  const adagioParams = useMemo(() => {
    const adPlacement = adsPlacements.find(placement => placement.kind === AdKind.Rtb)

    if (!adPlacement || adPlacement.kind !== AdKind.Rtb) return undefined

    return adPlacement.bids?.find(bid => bid.bidder === 'adagio')?.params
  }, [adsPlacements])

  useEffect(() => {
    initPrebidSetup(adagioParams)
  }, [adagioParams])

  useEffect(() => {
    setPrebidConsentManagement(isCookieConsentVersion)
  }, [isCookieConsentVersion])

  return (
    <Script
      id="prebid-script"
      data-testid="prebid-script"
      src={`${CDN_ADS_URL}/prebid.js`}
      strategy="lazyOnload"
    />
  )
}

export default Prebid
