import { withServerSideProxy } from '@marketplace-web/shared/ssr'

// TODO: remove this reexport
export {
  getBrowserWidth,
  getBrowserHeight,
  navigateToPage,
  openBrowsingContext,
  preventLeave,
  redirectToPage,
  redirectToPageInNewTab,
  reloadPage,
  scrollToElementById,
  scrollToTop,
} from '@marketplace-web/shared/browser'

export const { toggleOneTrustInfoDisplay } = withServerSideProxy(
  {
    toggleOneTrustInfoDisplay: () => {
      window.OneTrust?.ToggleInfoDisplay()
    },
  },
  {},
)
