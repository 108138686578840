'use client'

import { ArrowTopLeft24 } from '@vinted/monochrome-icons'
import { MouseEvent, MutableRefObject, useEffect, useRef } from 'react'
import { Button, Cell, Icon, Text } from '@vinted/web-ui'
import { escape, escapeRegExp, isEqual, kebabCase, noop } from 'lodash'
import { InView } from 'react-intersection-observer'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import List from 'components/List'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { SearchSuggestionType } from 'constants/search'
import {
  clickSearchSuggestionAutofillEvent,
  viewSearchSuggestionEvent,
  ViewSearchSuggestionEventArgs,
  viewSearchSuggestionsEvent,
} from '_libs/common/event-tracker/events'
import { SearchSuggestionModel } from 'types/models'
import useLatestCallback from 'hooks/useLatestCallback'
import { getSearchSessionData } from '_libs/utils/search'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'

type Props = {
  query: string
  items: Array<SearchSuggestionModel>
  highlightedIndex: number | null
  suggestionUrl: (suggestion: SearchSuggestionModel) => string | undefined
  suggestionsListId: MutableRefObject<string | null>
  suggestionsSessionId: MutableRefObject<string>
  onSuggestionClick?: (
    suggestion: SearchSuggestionModel,
    index: number,
    filterSuggestionCount: number,
    event: MouseEvent,
  ) => void
  seenSuggestions: MutableRefObject<Set<number>>
  onClearSuggestions: () => void
  onMobileAutofill: (query: string) => void
}

const SearchSuggestions = ({
  query,
  items,
  highlightedIndex,
  suggestionUrl,
  suggestionsListId,
  suggestionsSessionId,
  seenSuggestions,
  onClearSuggestions,
  onSuggestionClick = noop,
  onMobileAutofill,
}: Props) => {
  const { track } = useTracking()
  const breakpoint = useBreakpoint()

  const prevItems = useRef<Array<SearchSuggestionModel>>()
  const trackedQuery = useRef(query)

  const shouldAddViewSearchSuggestionEvent = useFeatureSwitch(
    'web_add_view_search_suggestion_event',
  )

  const holdout = useAbTest('buyer_domain_holdout_2025q1')?.variant !== 'off'

  const suggestionAutofillAb = useAbTest('search_suggestions_autofill_v3')
  const trackAbTest = useTrackAbTestCallback()
  const isSuggestionAutofillEnabled = Boolean(
    suggestionAutofillAb && holdout && suggestionAutofillAb.variant !== 'off',
  )

  const prevHighlightedIndex = useRef<number | null>(null)
  const isAutofillExposed = useRef(false)

  const exposeAutofill = useLatestCallback(() => {
    if (isAutofillExposed.current) return

    if (holdout) {
      trackAbTest(suggestionAutofillAb)
      isAutofillExposed.current = true
    }
  })

  useEffect(() => {
    if (breakpoint.phones) exposeAutofill()
  }, [breakpoint.phones, exposeAutofill])

  useEffect(() => {
    if (highlightedIndex === null) return
    exposeAutofill()
  }, [highlightedIndex, exposeAutofill])

  const itemsRef = useRef(items)
  itemsRef.current = items

  const queryRef = useRef(query)
  queryRef.current = query

  useEffect(() => {
    if (!isSuggestionAutofillEnabled) return

    const maybeSuggestion = itemsRef.current[prevHighlightedIndex.current ?? -1]

    if (
      highlightedIndex === null &&
      maybeSuggestion &&
      maybeSuggestion.type !== SearchSuggestionType.Fallback
    ) {
      const { searchSessionId, globalSearchSessionId } = getSearchSessionData()

      track(
        clickSearchSuggestionAutofillEvent({
          query: queryRef.current,
          suggestionText: maybeSuggestion.title || '',
          searchSessionId,
          suggestionsListId: suggestionsListId.current,
          suggestionPosition: prevHighlightedIndex.current! + 1,
          suggestionsSessionId: suggestionsSessionId.current,
          globalSearchSessionId,
        }),
      )
    }

    prevHighlightedIndex.current = highlightedIndex
  }, [
    highlightedIndex,
    isSuggestionAutofillEnabled,
    suggestionsListId,
    suggestionsSessionId,
    track,
  ])

  useEffect(() => {
    trackedQuery.current = query
  }, [query])

  useEffect(() => {
    if (!items.length || isEqual(prevItems.current, items)) return

    track(
      viewSearchSuggestionsEvent({
        suggestions: items.map(item => item.query),
        query: trackedQuery.current,
        suggestionsSessionId: suggestionsSessionId.current,
      }),
    )

    prevItems.current = items
  }, [track, items, suggestionsSessionId])

  const onClearSuggestionsLatest = useLatestCallback(onClearSuggestions)

  useEffect(() => onClearSuggestionsLatest, [onClearSuggestionsLatest])

  const handleItemClick =
    (suggestion: SearchSuggestionModel, index: number) => (event: MouseEvent) => {
      const scopedSuggestions = items.filter(item => item.type === SearchSuggestionType.Scoped)

      onSuggestionClick(suggestion, index, scopedSuggestions.length, event)
    }

  const getSuggestionKey = (suggestion: SearchSuggestionModel) => {
    if (suggestion.type === SearchSuggestionType.Scoped) {
      return [suggestion.id, kebabCase(suggestion.subtitle)].join('-')
    }

    return suggestion.id
  }

  const getHighlightedTitle = (suggestionTitle: string) => {
    const escapedQuery = escape(query)
    const escapedSuggestionTitle = escape(suggestionTitle)

    return escapedSuggestionTitle.replace(
      new RegExp(`^${escapeRegExp(escapedQuery)}`, 'i'),
      '<b>$&</b>',
    )
  }

  const handleMobileAutofill = (
    event: MouseEvent,
    suggestion: SearchSuggestionModel,
    index: number,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    onMobileAutofill(suggestion.query)

    const { searchSessionId, globalSearchSessionId } = getSearchSessionData()

    track(
      clickSearchSuggestionAutofillEvent({
        query,
        suggestionText: suggestion.title,
        searchSessionId,
        suggestionsListId: suggestionsListId.current,
        suggestionPosition: index + 1,
        suggestionsSessionId: suggestionsSessionId.current,
        globalSearchSessionId,
      }),
    )
  }

  const renderSuggestion = (suggestion: SearchSuggestionModel, index: number) => {
    const showSuffix =
      breakpoint.phones &&
      suggestion.type !== SearchSuggestionType.Fallback &&
      isSuggestionAutofillEnabled

    const isVariantB = suggestionAutofillAb?.variant === 'b'

    const cellProps: Partial<ComponentProps<typeof Cell>> = {
      type: Cell.Type.Navigating,
      url: suggestionUrl(suggestion),
      highlighted: isVariantB ? undefined : highlightedIndex === index,
      theme: isVariantB ? 'transparent' : undefined,
      testId: `suggestion-${suggestion.id}`,
      suffix: showSuffix && (
        <Button
          testId="autofill-button"
          styling={Button.Styling.Flat}
          theme={isVariantB ? 'muted' : 'amplified'}
          icon={<Icon name={ArrowTopLeft24} />}
          onClick={event => handleMobileAutofill(event, suggestion, index)}
          size={Button.Size.Small}
        />
      ),
    }

    switch (suggestion.type) {
      case SearchSuggestionType.Scoped:
        return (
          <Cell
            {...cellProps}
            onClick={handleItemClick(suggestion, index)}
            key={getSuggestionKey(suggestion)}
          >
            <Text
              as="span"
              theme="amplified"
              text={getHighlightedTitle(suggestion.title)}
              html
              highlight
              highlightTheme="muted"
            />{' '}
            <Text text={suggestion.subtitle} type={Text.Type.Subtitle} theme="primary" as="span" />
          </Cell>
        )
      case SearchSuggestionType.Multiword:
      case SearchSuggestionType.Brand:
      case SearchSuggestionType.Catalog: {
        return (
          <Cell
            {...cellProps}
            onClick={handleItemClick(suggestion, index)}
            key={getSuggestionKey(suggestion)}
          >
            <Text
              as="span"
              theme="amplified"
              text={getHighlightedTitle(suggestion.title)}
              html
              highlight
              highlightTheme="muted"
            />
          </Cell>
        )
      }
      case SearchSuggestionType.Fallback:
        return (
          <Cell
            {...cellProps}
            onClick={handleItemClick(suggestion, index)}
            title={suggestion.title}
            key={getSuggestionKey(suggestion)}
          />
        )
      default:
        return null
    }
  }

  const getHandleItemView = (item: SearchSuggestionModel, index: number) => (inView: boolean) => {
    if (!inView) return

    if (seenSuggestions.current.has(item.id)) return
    seenSuggestions.current.add(item.id)

    const { searchSessionId, globalSearchSessionId } = getSearchSessionData()

    const isFrontendGeneratedTextSelected = item.type === SearchSuggestionType.Fallback
    const trackingArgs: ViewSearchSuggestionEventArgs = {
      frontendGeneratedTextSelected: isFrontendGeneratedTextSelected,
      globalSearchSessionId,
      query: item.query,
      searchSessionId: searchSessionId || null,
      selectedSuggestionText: isFrontendGeneratedTextSelected ? item.query : item.title,
      suggestionPosition: index + 1,
      suggestionsListId: suggestionsListId.current,
      suggestionsSessionId: suggestionsSessionId.current,
    }

    if ('params' in item) {
      trackingArgs.params = item.params
    }

    track(viewSearchSuggestionEvent(trackingArgs))
  }

  const renderSuggestionWithBackground = (suggestion: SearchSuggestionModel, index: number) => {
    const isSelected = index === highlightedIndex && suggestionAutofillAb?.variant === 'b'

    return (
      <div
        key={getSuggestionKey(suggestion)}
        className={isSelected ? 'search-suggestions--hovered' : undefined}
        data-testid="search-suggestion-bg"
      >
        {renderSuggestion(suggestion, index)}
      </div>
    )
  }

  const renderSuggestions = (suggestions: Array<SearchSuggestionModel>) => (
    <List>
      {suggestions.map((suggestion, index) => (
        <InView onChange={getHandleItemView(suggestion, index)} key={getSuggestionKey(suggestion)}>
          {renderSuggestionWithBackground(suggestion, index)}
        </InView>
      ))}
    </List>
  )

  if (!items.length) return null

  return (
    <Cell styling={Cell.Styling.Tight}>
      {shouldAddViewSearchSuggestionEvent ? (
        renderSuggestions(items)
      ) : (
        <List>{items.map((item, index) => renderSuggestionWithBackground(item, index))}</List>
      )}
    </Cell>
  )
}

export default SearchSuggestions
