import { first } from 'lodash'

import { FilterState } from 'pages/Catalog/CatalogProvider/types'
import { SavedSearchApiParams } from 'types/api'
import { SelectedDynamicFilterModel } from 'types/models/dynamic-filter'
import { filterEmptyValues } from '_libs/utils/object'
import { getSelectedDynamicFiltersParams } from 'pages/Catalog/utils/utils'

export const filtersToApiParams = (
  filters: FilterState,
  selectedDynamicFilters: Array<SelectedDynamicFilterModel>,
  searchText: string,
): SavedSearchApiParams => {
  const params: SavedSearchApiParams = {
    search_text: searchText,
    catalog_id: first(filters.catalogIds),
    price_from: filters.priceFrom || undefined,
    price_to: filters.priceTo || undefined,
    currency: filters.currency || undefined,
    filters: getSelectedDynamicFiltersParams(selectedDynamicFilters),
  }

  return filterEmptyValues(params)
}
