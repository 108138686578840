import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { getAbTestByName } from 'state/ab-tests/selectors'

import { AbTestsContext } from '../context'

const useAbTest = (name: string) => {
  const abTests = useContext(AbTestsContext)
  const abTestFromContext = abTests ? abTests[name] : undefined
  const abTestFromRedux = useSelector(getAbTestByName(name))
  const isAbTestRefactorEnabled = useFeatureSwitch('next_ab_test_slice_refactor')

  return isAbTestRefactorEnabled ? abTestFromContext : abTestFromRedux
}

export default useAbTest
