'use client'

import { useContext } from 'react'

import {
  ItemPageShopBundlesBlockPlugin,
  ItemPageShopBundlesBlockPluginModel,
} from '@marketplace-web/domain/items-bundle'

import ItemPageClosetPlugin from '../plugins/Closet'
import { ItemPageClosetPluginDto } from '../plugins/Closet/types'
import ItemPageSimilarItemsPlugin from '../plugins/SimilarItems'
import { ItemPageSimilarItemsPluginModel } from '../plugins/SimilarItems/types'
import ItemPageClosetTitlePlugin from '../plugins/ClosetTitle'
import { ItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/types'
import Plugin from '../plugins/Plugin'
import { PluginName } from '../types'
import { PluginsContext } from '../containers/PluginsProvider'

type Props = {
  closetData: ItemPageClosetPluginDto | undefined
  similarItemsData: ItemPageSimilarItemsPluginModel | undefined
  shopBundlesBlockData: ItemPageShopBundlesBlockPluginModel | undefined
  closetTitleData: ItemPageClosetTitlePluginModel | undefined
}

const Feed = ({ closetData, similarItemsData, shopBundlesBlockData, closetTitleData }: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)

  return (
    <div className="u-ui-margin-top-medium">
      <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Feedbacks)} />
      <ItemPageClosetTitlePlugin data={closetTitleData} />
      {shopBundlesBlockData && <ItemPageShopBundlesBlockPlugin data={shopBundlesBlockData} />}
      {closetData && <ItemPageClosetPlugin data={closetData} />}
      <ItemPageSimilarItemsPlugin data={similarItemsData} />
    </div>
  )
}

export default Feed
