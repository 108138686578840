'use client'

import { useEffect, useMemo, useState } from 'react'

import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'
import { ConsentGroup } from 'constants/consent'

import { AdShape } from '../../../constants'

function useShouldRenderAd(shape?: AdShape): boolean {
  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)
  const isLeaderboard = useMemo(() => shape === AdShape.Leaderboard, [shape])

  const [shouldRender, setShouldRender] = useState<boolean>(isConsentGroupEnabled)

  useEffect(() => {
    if (isLeaderboard && isConsentGroupEnabled) return

    setShouldRender(isConsentGroupEnabled)
  }, [isConsentGroupEnabled, isLeaderboard])

  return shouldRender
}

export default useShouldRenderAd
