'use client'

import { Text, Spacer } from '@vinted/web-ui'

import useToggleFavourite from 'hooks/useToggleFavourite'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'
import { useSession } from '@marketplace-web/shared/session'

import { brazeLogCustomEvent } from '_libs/common/braze/utils/custom-event'
import { BrazeCustomEvent } from '_libs/common/braze/constants'
import { clickEvent, favouriteItemEvent } from '_libs/common/event-tracker/events'
import { getSearchSessionData } from '_libs/utils/search'
import { getHomepageSessionIdFromUrl } from '_libs/utils/homepage'

import { ContentSource } from 'constants/tracking/content-sources'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { GoogleTagManagerEvent } from 'constants/google'
import { googleTagManagerTrack } from 'data/utils/google'

import HeartIcon from '../HeartIcon'

type Props = {
  initialFavouriteCount: number
  isFavourited: boolean
  itemId: number
  itemUserId: number
}

const FavouriteIcon = ({ initialFavouriteCount, isFavourited, itemId, itemUserId }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('item')
  const { user } = useSession()

  const userExternalId = user?.external_id
  const currentUserId = user?.id

  const { isFavourite, favouriteCount, toggleFavourite, hasFavouritedChanged } = useToggleFavourite(
    {
      entityId: itemId,
      isFavourite: isFavourited,
      count: initialFavouriteCount,
    },
  )

  const isViewingOwnItem = itemUserId === currentUserId

  const handleFavouriteToggleSuccess = (newIsFavourite: boolean) => {
    if (!newIsFavourite) return

    googleTagManagerTrack(GoogleTagManagerEvent.AddToFavourites, {
      item_id: itemId,
    })

    brazeLogCustomEvent({
      event: BrazeCustomEvent.AddedToFavourites,
      modelId: itemId,
      userExternalId,
    })
  }

  const handleClick = () => {
    const searchSession = getSearchSessionData()

    track(clickEvent({ target: ClickableElement.Favourite }))

    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent: !isFavourite,
        contentSource: ContentSource.UserItems,
        globalSearchSessionId: searchSession.globalSearchSessionId,
        globalCatalogBrowseSessionId: searchSession.globalCatalogBrowseSessionId,
        homepageSessionId: getHomepageSessionIdFromUrl(),
      }),
    )

    toggleFavourite({
      onSuccess: handleFavouriteToggleSuccess,
    })
  }

  if (isViewingOwnItem) return null

  const getAriaLabel = () => {
    const textWithoutCount = translate(isFavourite ? 'a11y.favorited' : 'a11y.not_favorited')
    const textWithCount = translate(
      isFavourite ? 'a11y.unfavourite' : 'a11y.favourite',
      { count: favouriteCount },
      { count: favouriteCount },
    )

    return favouriteCount > 0 ? textWithCount : textWithoutCount
  }

  const renderActionFeedback = () => (
    <span aria-live="polite" className="u-visually-hidden">
      {translate(isFavourite ? 'a11y.added_to_favorites' : 'a11y.removed_from_favorites')}
    </span>
  )

  return (
    <div className="u-position-absolute u-right u-bottom u-zindex-bump">
      <button
        aria-pressed={isFavourite}
        aria-label={getAriaLabel()}
        type="button"
        onClick={handleClick}
        data-testid="favourite-button"
        className="u-background-white u-flexbox u-align-items-center item-favourite-icon"
      >
        <HeartIcon isFavourite={isFavourite} isToggled={hasFavouritedChanged} isIconLarge />
        {favouriteCount > 0 && (
          <>
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            <Text as="span" text={favouriteCount} type={Text.Type.Body} />
          </>
        )}
      </button>
      {renderActionFeedback()}
    </div>
  )
}

export default FavouriteIcon
