import clientSideMetrics from '_libs/common/client-side-metrics'
import { MS_PER_SECOND } from 'constants/date'

type CheckoutPlugins =
  | 'additional_service'
  | 'multiple_items_presentation'
  | 'single_item_presentation'
  | 'order_content'
  | 'order_summary'
  | 'pay_button'
  | 'payment_options'
  | 'shipping'
  | 'shipping_address'
  | 'shipping_contact'

type IncrementCheckoutLoad = {
  type: 'success' | 'error'
  checkoutType: 'old' | 'new'
}

export const incrementCheckoutLoad = ({ type, checkoutType }: IncrementCheckoutLoad) => {
  clientSideMetrics.counter('checkout_load', { type, checkoutType }).increment()
}

export const incrementCheckoutPluginLoad = (plugin: CheckoutPlugins) => {
  clientSideMetrics.counter('checkout_plugin_load', { plugin }).increment()
}

type ObserveCheckoutLoad = {
  time: number
  checkoutType: 'old' | 'new'
}

export const observeCheckoutLoad = ({ time, checkoutType }: ObserveCheckoutLoad) => {
  clientSideMetrics
    .histogram('checkout_load_duration_seconds', {
      checkout_type: checkoutType,
    })
    .observe(time / MS_PER_SECOND)
}
