'use client'

import { useEffect, useState } from 'react'
import { Dialog, Navigation, Button, Icon } from '@vinted/web-ui'
import { InfoCircle12, X24 } from '@vinted/monochrome-icons'

import { getFaqEntryById } from 'data/api'
import { transformFaqEntryResponse } from 'data/api/transformers/response'
import useFetch from 'hooks/useFetch'
import ContentLoader from 'components/ContentLoader'
import ScrollableArea from 'components/ScrollableArea'
import FaqContent from 'pages/HelpCenter/FaqContent'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { itemAttributesClickEvent } from '_libs/common/event-tracker/events'

type Props = {
  faqEntryId: number
  code: string
  accessChannel?: string
  itemId: number
}

const ItemInfoLink = ({ faqEntryId, accessChannel, code, itemId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [faqEntryTitle, setFaqEntryTitle] = useState<string | null | undefined>(null)
  const [faqEntryContent, setFaqEntryContent] = useState<string | null | undefined>(null)

  const translate = useTranslate('item.details')
  const { track } = useTracking()

  const {
    fetch: fetchFaqEntryById,
    transformedData: faqEntry,
    isLoading: isFaqEntryLoading,
  } = useFetch(getFaqEntryById, transformFaqEntryResponse)

  useEffect(() => {
    if (!faqEntry) return

    setFaqEntryTitle(faqEntry.title)
    setFaqEntryContent(faqEntry.body)
  }, [faqEntry])

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)

    track(
      itemAttributesClickEvent({
        itemId: itemId.toString(),
        attributeCode: code,
      }),
    )

    fetchFaqEntryById(faqEntryId.toString())
  }

  const renderCloseButton = () => (
    <Button
      onClick={handleModalClose}
      styling={Button.Styling.Flat}
      theme="amplified"
      iconName={X24}
      testId="item-info-link-modal-close-button"
      inline
    />
  )

  const renderContent = () => {
    if (isFaqEntryLoading) {
      return (
        <ContentLoader
          styling={ContentLoader.Styling.Wide}
          size={ContentLoader.Size.Large}
          testId="help-center-faq-entry-loader"
        />
      )
    }

    return (
      <FaqContent
        faqEntryId={faqEntryId}
        accessChannel={accessChannel}
        title={faqEntryTitle}
        content={faqEntryContent}
      />
    )
  }

  const renderInfoLink = () => (
    <button
      data-testid={`item-attributes-${code}-faq-link`}
      className="overflow-menu overflow-menu--top-right u-cursor-pointer"
      onClick={handleModalOpen}
      type="button"
      aria-label={translate(`a11y.${code}`)}
      title={translate(`a11y.${code}`)}
    >
      <Icon name={InfoCircle12} color={Icon.Color.GreyscaleLevel2} aria={{ 'aria-hidden': true }} />
    </button>
  )

  return (
    <>
      <Dialog hasScrollableContent show={isModalOpen}>
        <div className="u-fill-width">
          <Navigation right={renderCloseButton()} />
          <ScrollableArea className="u-padding-large">{renderContent()}</ScrollableArea>
        </div>
      </Dialog>
      {renderInfoLink()}
    </>
  )
}

export default ItemInfoLink
